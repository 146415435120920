import React from "react";
import styled from "styled-components";
import { Box } from "@monster/shared";
import { SepaPaymentAppBar } from "@pages/SepaPayment/SepaPaymentAppBar";
import { SepaPaymentFooter } from "@pages/SepaPayment/SepaPaymentFooter";
import { SepaResultDialog } from "./SepaResultDialog";

export const SepaResultPage = () => {
    return (
        <PageWrapper>
            <SepaPaymentAppBar />

            <SepaResultDialog />
            <Box as="main" $style={{ width: "100%", maxWidth: 504, margin: "0 auto" }}>
                SEPA Result
            </Box>

            <SepaPaymentFooter />
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
`;
