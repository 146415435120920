import { GqlDeWebapp } from "@api/Api";
import { DateUtils } from "@utils/DateHelpers";
import { ApplicationState } from "@redux/Reducers";
import { LegalDocumentSubCategory, LegalDocumentUtils } from "@utils/LegalDocumentUtils";
import { ClaimFrontendStatus, Helpers } from "@utils/Helpers";
import { DeHomeInsuranceContractWithInsuranceType, GermanInsuranceContract } from "@api/CustomGqlTypes";

export type ContractDocument = {
    contractId: string;
    url: string;
    subCategory?: LegalDocumentSubCategory;
    insuranceType?: GqlDeWebapp.InsuranceType | null;
    activeSince: string;
    activeUntil?: string;
    serialNumber?: number;
    versionInfo?: GqlDeWebapp.InsuranceDoc_insuranceVersion | null;
};

export class SessionSelectors {
    public static isLoggedIn(state: ApplicationState): boolean {
        return !!state.session?.email && !!state.session?.verifiedEmail && !!state.session?.accountData;
    }

    public static legalDocuments(state: ApplicationState) {
        const order: GqlDeWebapp.legalDocumentCategory[] = [
            GqlDeWebapp.legalDocumentCategory.CHERRISK_PRIVACY_POLICY,
            GqlDeWebapp.legalDocumentCategory.HEALTH_DATA,
            GqlDeWebapp.legalDocumentCategory.REGISTRATION_POLICY,
            // GqlDeWebapp.legalDocumentCategory.MARKETING_POLICY,
            GqlDeWebapp.legalDocumentCategory.INSURANCE_INTERMEDIARY_INFO,
        ];
        const findByCategory = (category: GqlDeWebapp.legalDocumentCategory): GqlDeWebapp.Session_legalDocumentsWithRelations | null => {
            return (
                (state.session?.legalDocumentsWithRelations ?? []).find(ldr => {
                    if (!ldr.relation.accepted) {
                        return false;
                    }
                    return ldr.legalDocument.category === category;
                }) ?? null
            );
        };

        return order
            .map(findByCategory)
            .filter(Helpers.nullFilter)
            .map(ldr => ldr.legalDocument);
    }

    public static getAllContracts() {
        return (state: ApplicationState) => {
            return [...(state.session?.germanHomeInsuranceContracts ?? []), ...(state.session?.germanTravelInsuranceContracts ?? [])].filter(contract => {
                return LegalDocumentUtils.isActiveInsurance(contract.status) || LegalDocumentUtils.isArchivedInsurance(contract.status);
            });
        };
    }

    public static getContract(contractId?: string | null) {
        return (state: ApplicationState): GermanInsuranceContract | null => {
            const findContract = (contract: GermanInsuranceContract) =>
                contract.id === contractId && (LegalDocumentUtils.isActiveInsurance(contract.status) || LegalDocumentUtils.isArchivedInsurance(contract.status));
            const travelInsuranceContract = (state.session?.germanTravelInsuranceContracts ?? []).find(findContract);
            const homeInsuranceContract = (state.session?.germanHomeInsuranceContracts ?? []).find(findContract);
            return travelInsuranceContract ?? homeInsuranceContract ?? null;
        };
    }

    public static getContractById(contractId?: string | null) {
        return (state: ApplicationState): GermanInsuranceContract | null => {
            const findContract = (contract: GermanInsuranceContract) => contract.id === contractId;
            const travelInsuranceContract = (state.session?.germanTravelInsuranceContracts ?? []).find(findContract);
            const homeInsuranceContract = (state.session?.germanHomeInsuranceContracts ?? []).find(findContract);
            return travelInsuranceContract ?? homeInsuranceContract ?? null;
        };
    }

    public static contractDocuments(state: ApplicationState) {
        const activeTravelContracts = (state.session?.germanTravelInsuranceContracts ?? []).filter(gtic => LegalDocumentUtils.isActiveInsurance(gtic.status));
        const activeTravelContractsWithArchiveDocs = activeTravelContracts.filter(gtic => Helpers.hasArchivedContractDocs(gtic.documents));
        const archiveTravelContracts = (state.session?.germanTravelInsuranceContracts ?? []).filter(gtic => LegalDocumentUtils.isArchivedInsurance(gtic.status));
        const pendingTravelContracts = (state.session?.germanTravelInsuranceContracts ?? []).filter(gtic => LegalDocumentUtils.isPendingPaymentInsurance(gtic.status));
        const activeHomeContracts = (state.session?.germanHomeInsuranceContracts ?? []).filter(ghic => LegalDocumentUtils.isActiveInsurance(ghic.status));
        const activeHomeContractsWithArchiveDocs = activeHomeContracts.filter(ghic => Helpers.hasArchivedContractDocs(ghic.documents));
        const archiveHomeContracts = (state.session?.germanHomeInsuranceContracts ?? []).filter(ghic => LegalDocumentUtils.isArchivedInsurance(ghic.status));
        const pendingHomeContracts = (state.session?.germanHomeInsuranceContracts ?? []).filter(ghic => LegalDocumentUtils.isPendingPaymentInsurance(ghic.status));
        const TravelInsuranceTypes = [GqlDeWebapp.InsuranceType.TRAVEL];
        const TravelModInsuranceType = [GqlDeWebapp.InsuranceType.DE_TRAVEL_MOD_S, GqlDeWebapp.InsuranceType.DE_TRAVEL_MOD_A];
        const CancellationInsuranceTypes = [GqlDeWebapp.InsuranceType.TRIP_CANCELLATION];
        const HomeInsuranceTypes = [GqlDeWebapp.InsuranceType.DE_HOME_PACKAGES, GqlDeWebapp.InsuranceType.HOME];
        const getDocs = (
            contracts: GqlDeWebapp.ModularTravelDEInsuranceContract[] | DeHomeInsuranceContractWithInsuranceType[],
            filter: { insuranceTypes: GqlDeWebapp.InsuranceType[]; subCategory?: string },
            activeOnly = true
        ) => {
            const docs: ContractDocument[] = [];

            contracts.forEach(contract => {
                if (contract.insuranceType && filter.insuranceTypes.includes(contract.insuranceType)) {
                    if (!filter.subCategory) {
                        const legalDocs = (activeOnly ? Helpers.getSortedContractDocs(contract.documents) : Helpers.getSortedArchiveContractDocs(contract.documents)).map(
                            (doc): ContractDocument => ({
                                contractId: contract.id ?? "",
                                url: doc.fileData?.url || "",
                                subCategory: filter.subCategory as LegalDocumentSubCategory,
                                insuranceType: contract.insuranceType,
                                activeSince: contract.activeSince as string,
                                activeUntil: "activeUntil" in contract ? contract.activeUntil : undefined,
                                serialNumber: doc.serialNumber,
                                versionInfo: doc.insuranceVersion,
                            })
                        );
                        docs.push(...legalDocs);
                    } else if ("legalDocuments" in contract) {
                        if (HomeInsuranceTypes.includes(contract.insuranceType)) {
                            const legalDocs = ((contract as GqlDeWebapp.DeHomeInsuranceContract).wlLegalDocumentRelations ?? [])
                                .map(l => l.legalDocument)
                                .filter(c => c.category === filter.subCategory)
                                .map(
                                    (ld): ContractDocument => ({
                                        contractId: contract.id ?? "",
                                        url: ld.url || "",
                                        subCategory: LegalDocumentUtils.legalDocumentCategoryToLegalDocumentSubCategory(filter.subCategory as GqlDeWebapp.legalDocumentCategory),
                                        insuranceType: contract.insuranceType,
                                        activeSince: contract.activeSince as string,
                                        activeUntil: "activeUntil" in contract ? contract.activeUntil : undefined,
                                    })
                                );
                            docs.push(...legalDocs);
                        } else {
                            const legalDocs = (contract.wlLegalDocumentRelations ?? [])
                                .filter(l => l.legalDocument.category === filter.subCategory)
                                .map(
                                    (ld): ContractDocument => ({
                                        contractId: contract.id ?? "",
                                        url: ld.legalDocument.url || "",
                                        subCategory: filter.subCategory as LegalDocumentSubCategory,
                                        insuranceType: contract.insuranceType,
                                        activeSince: contract.activeSince as string,
                                        activeUntil: "activeUntil" in contract ? contract.activeUntil : undefined,
                                    })
                                );
                            docs.push(...legalDocs);
                        }
                    }
                }
            });

            return docs.sort((b: ContractDocument, a: ContractDocument) => DateUtils.parse(b.activeSince).getTime() - DateUtils.parse(a.activeSince).getTime());
        };

        const order = [
            { insuranceTypes: TravelInsuranceTypes },
            { insuranceTypes: TravelModInsuranceType },
            { insuranceTypes: CancellationInsuranceTypes },
            { insuranceTypes: TravelInsuranceTypes, subCategory: LegalDocumentSubCategory.TERMS },
            { insuranceTypes: CancellationInsuranceTypes, subCategory: LegalDocumentSubCategory.TERMS },
            { insuranceTypes: TravelModInsuranceType, subCategory: GqlDeWebapp.legalDocumentCategory.TERMS },
            { insuranceTypes: TravelInsuranceTypes, subCategory: LegalDocumentSubCategory.IPIDS },
            { insuranceTypes: CancellationInsuranceTypes, subCategory: LegalDocumentSubCategory.IPIDS },
            { insuranceTypes: TravelModInsuranceType, subCategory: GqlDeWebapp.legalDocumentCategory.IPID },
            { insuranceTypes: HomeInsuranceTypes },
            { insuranceTypes: HomeInsuranceTypes, subCategory: GqlDeWebapp.legalDocumentCategory.TERMS },
            { insuranceTypes: HomeInsuranceTypes, subCategory: GqlDeWebapp.legalDocumentCategory.IPID },
            { insuranceTypes: TravelInsuranceTypes, subCategory: LegalDocumentSubCategory.PROTOCOL_OF_ADVICE },
            { insuranceTypes: TravelInsuranceTypes, subCategory: LegalDocumentSubCategory.GENERAL_CONTRACT_INFO },
            { insuranceTypes: TravelModInsuranceType, subCategory: GqlDeWebapp.legalDocumentCategory.PROTOCOL_OF_ADVICE },
            { insuranceTypes: TravelModInsuranceType, subCategory: GqlDeWebapp.legalDocumentCategory.GENERAL_CONTRACT_INFORMATION },
            { insuranceTypes: CancellationInsuranceTypes, subCategory: LegalDocumentSubCategory.PROTOCOL_OF_ADVICE },
            { insuranceTypes: CancellationInsuranceTypes, subCategory: LegalDocumentSubCategory.GENERAL_CONTRACT_INFO },
            { insuranceTypes: HomeInsuranceTypes, subCategory: GqlDeWebapp.legalDocumentCategory.PROTOCOL_OF_ADVICE },
            { insuranceTypes: HomeInsuranceTypes, subCategory: GqlDeWebapp.legalDocumentCategory.GENERAL_CONTRACT_INFORMATION },
        ];

        const result = order.reduce(
            (prev, filter): { active: ContractDocument[]; archive: ContractDocument[]; hasPendingContractGeneration: boolean } => {
                prev.active.push(...getDocs(activeTravelContracts, filter));
                prev.archive.push(...getDocs(archiveTravelContracts, filter));
                prev.archive.push(...getDocs(activeTravelContractsWithArchiveDocs, filter, false));
                prev.active.push(...getDocs(activeHomeContracts, filter));
                prev.archive.push(...getDocs(archiveHomeContracts, filter));
                prev.archive.push(...getDocs(activeHomeContractsWithArchiveDocs, filter, false));
                return prev;
            },
            { active: [], archive: [], hasPendingContractGeneration: false } as { active: ContractDocument[]; archive: ContractDocument[]; hasPendingContractGeneration: boolean }
        );
        const travelPending = pendingTravelContracts.length > 0 || activeTravelContracts.some(atc => atc.documents.some(dt => dt.created === null));
        const homePending = pendingHomeContracts.length > 0 || activeHomeContracts.some(ahc => ahc.documents.some(dt => dt.created === null));
        result.hasPendingContractGeneration = travelPending || homePending;
        return result;
    }

    public static getClaimableContracts(state: ApplicationState): Array<GqlDeWebapp.ModularTravelDEInsuranceContract | GqlDeWebapp.DeHomeInsuranceContract> {
        return [...(state.session?.germanHomeInsuranceContracts ?? []), ...(state.session?.germanTravelInsuranceContracts ?? [])].filter(contract => {
            return (contract.ops ?? []).includes(GqlDeWebapp.InsuranceOp.ReportClaim);
        });
    }

    public static getClaims(statuses: ClaimFrontendStatus[]) {
        return (state: ApplicationState) => {
            const claimStatuses = statuses.flatMap(Helpers.getClaimStatuses);
            return (state.session?.accountData?.claims ?? []).filter(claim => {
                return claimStatuses.includes(claim.claimStatus!);
            });
        };
    }
}
