import styled from "styled-components";

export const DashboardLink = styled.a`
    color: inherit;
    text-decoration: none;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.color.greenN};
        }
    }
`;
