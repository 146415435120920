import React from "react";
import { useTheme } from "styled-components";
import { Box, Flex } from "@monster/shared";
import { Surface, Text } from "@monster/chr-ui";
import { LoctoolMessage, LoctoolNumber } from "@monster/loctool";
import { SVGSharedIcon68X72ProductsHome } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon68X72ProductsHomeShadow } from "@monster/shared/dist/svg_assets";

import { SVGSharedIcon68X72ProductsTravel } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon68X72ProductsTravelShadow } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon72X71ProductsAccident } from "@monster/shared/dist/svg_assets";
import { SVGSharedIcon72X71ProductsAccidentShadow } from "@monster/shared/dist/svg_assets";
import {
    cart_cart_insurances_contract_DeHomeInsuranceContract,
    cart_cart_insurances_contract_HomeInsuranceContract,
    cart_cart_insurances_contract_ModularTravelDEInsuranceContract,
    CoverageBase,
} from "@api/graphql/selfservice/types";

export const SepaPaymentCart_DEHomePackages = ({
    insuranceId,
    amountTotal,
    contract,
}: {
    insuranceId: string;
    amountTotal: number;
    contract: cart_cart_insurances_contract_DeHomeInsuranceContract;
}) => {
    const theme = useTheme();
    const details = [
        { label: <LoctoolMessage id="pages.sepa.paymentcard.insuranceid" />, value: insuranceId },
        { label: <LoctoolMessage id="pages.sepa.paymentcard.home.tarif" />, value: <LoctoolMessage id={`pages.sepa.paymentcard.home.tarif.${contract.baseOfCoverage ?? "basic"}`} /> },
        { label: <LoctoolMessage id="pages.sepa.paymentcard.home.period" />, value: <LoctoolMessage id="common.paymentPeriodMonths" values={{ paymentPeriodMonths: contract.paymentPeriodMonths }} /> },
    ];
    const cartItems: {
        label: string | JSX.Element;
        value: string | JSX.Element;
    }[] = [];
    /*
    if (contract.baseOfCoverage === ModHomeBase.comfort) {
        cartItems.push({ label: <LoctoolMessage id="pages.sepa.paymentcard.home.packages.basic" />, value: <LoctoolMessage id="pages.sepa.paymentcard.home.riderincluded" /> });
    }
    if (contract.baseOfCoverage === ModHomeBase.premium) {
        cartItems.push({ label: <LoctoolMessage id="pages.sepa.paymentcard.home.packages.basic" />, value: <LoctoolMessage id="pages.sepa.paymentcard.home.riderincluded" /> });
        cartItems.push({ label: <LoctoolMessage id="pages.sepa.paymentcard.home.packages.comfort" />, value: <LoctoolMessage id="pages.sepa.paymentcard.home.riderincluded" /> });
    }*/
    if (contract.riders) {
        if (contract.riders.elementary) {
            cartItems.push({ label: <LoctoolMessage id="pages.sepa.paymentcard.home.riders.elementary" />, value: <LoctoolMessage id="pages.sepa.paymentcard.home.riderincluded" /> });
        }
        if (contract.riders.bicycle) {
            cartItems.push({ label: <LoctoolMessage id="pages.sepa.paymentcard.home.riders.bike" />, value: <LoctoolMessage id="pages.sepa.paymentcard.home.riderincluded" /> });
        }
        if (contract.riders.glass) {
            cartItems.push({ label: <LoctoolMessage id="pages.sepa.paymentcard.home.riders.glass" />, value: <LoctoolMessage id="pages.sepa.paymentcard.home.riderincluded" /> });
        }
    }
    const total = { label: <LoctoolMessage id="pages.sepa.paymentcard.total" />, value: <LoctoolNumber value={amountTotal} format="currency" currency="EUR" /> };
    return (
        <Surface as="table" $variant="greyBorder" $style={{ backgroundColor: theme.color.white, borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
            <Text as="caption" $variant="h3Mobile" $style={{ captionSide: "top", padding: "40px 16px 16px", fontWeight: 700, textAlign: "left", whiteSpace: "nowrap" }}>
                <LoctoolMessage id="pages.cart.cart.title" />
            </Text>
            <thead>
                <tr>
                    <Box
                        as="th"
                        colSpan={2}
                        scope="colgroup"
                        $style={{
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16,
                            backgroundColor: theme.color.greyN,
                            boxShadow: `inset 0 -1px 0 0 ${theme.color.greyD}`,
                            textAlign: "left",
                            padding: "12px 16px",
                        }}
                    >
                        <Flex.Container $style={{ columnGap: 16, alignItems: "center" }}>
                            <Flex.Item $shrink="shrink" $style={{ alignSelf: "flex-start" }}>
                                <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                                    <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                                        <SVGSharedIcon68X72ProductsHomeShadow width={68 / 2} height={72 / 2} />
                                    </Box>

                                    <Box as="span" $style={{ display: "block", position: "absolute" }}>
                                        <SVGSharedIcon68X72ProductsHome color={theme.color.greenN} width={68 / 2} height={72 / 2} />
                                    </Box>
                                </Box>
                            </Flex.Item>
                            <Flex.Item $shrink="auto">
                                <LoctoolMessage id="enums.insuranceType.DE_HOME_PACKAGES" />
                            </Flex.Item>
                        </Flex.Container>
                    </Box>
                </tr>
            </thead>
            <tbody>
                {details.map((detail, index) => {
                    return (
                        <tr key={index}>
                            <Box
                                as="th"
                                scope="row"
                                $style={{
                                    position: index + 1 === details.length ? "relative" : undefined,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    backgroundColor: index + 1 === details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 0 0 12px" : index + 1 === details.length ? "0 0 12px 12px" : "0 0 0 12px",
                                    "&::before": index + 1 === details.length ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomLeftRadius: 16 } : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.label}</Box>
                            </Box>
                            <Box
                                as="td"
                                $style={{
                                    position: index + 1 === details.length ? "relative" : undefined,
                                    textAlign: "right",
                                    backgroundColor: index + 1 === details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 12px 0 0" : index + 1 === details.length ? "0 12px 12px 0" : "0 12px 0 0",
                                    verticalAlign: "top",
                                    "&::before": index + 1 === details.length ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomRightRadius: 16 } : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.value}</Box>
                            </Box>
                        </tr>
                    );
                })}
            </tbody>
            {cartItems.length > 0 && (
                <tbody>
                    {cartItems.map((detail, index) => {
                        return (
                            <tr key={index}>
                                <Box
                                    as="th"
                                    scope="row"
                                    $style={{
                                        fontWeight: 400,
                                        textAlign: "left",
                                        padding: index === 0 ? "8px 4px 4px 16px" : "4px 4px 4px 16px",
                                    }}
                                    $styleLarge={{ boxShadow: "none", padding: `${index > 0 ? 4 : 8}px 4px ${index + 1 === cartItems.length ? 8 : 4}px 16px` }}
                                >
                                    {detail.label}
                                </Box>
                                <Box
                                    as="td"
                                    $style={{
                                        fontWeight: 700,
                                        textAlign: "right",
                                        padding: index === 0 ? "8px 16px 4px 4px" : "4px 16px 4px 4px",
                                        verticalAlign: "top",
                                    }}
                                    $styleLarge={{ boxShadow: "none", padding: `${index > 0 ? 4 : 8}px 16px ${index + 1 === cartItems.length ? 8 : 4}px 4px` }}
                                >
                                    {detail.value}
                                </Box>
                            </tr>
                        );
                    })}
                </tbody>
            )}

            <tfoot>
                <tr>
                    <Box as="th" scope="row" $style={{ boxShadow: `0 -1px 0 0 ${theme.color.greyN}`, fontWeight: 400, textAlign: "left", verticalAlign: "top", padding: "12px 4px 8px 16px" }}>
                        <Text $variant="h3Desktop">{total.label}</Text>
                    </Box>
                    <Box as="td" $style={{ boxShadow: `0 -1px 0 0 ${theme.color.greyN}`, fontWeight: 700, textAlign: "right", padding: "12px 16px 8px 4px", whiteSpace: "nowrap" }}>
                        <Text $variant="h3Desktop">{total.value}</Text>
                    </Box>
                </tr>
            </tfoot>
        </Surface>
    );
};

export const SepaPaymentCart_DELegacyHomePackages = ({
    insuranceId,
    amountTotal,
    contract,
}: {
    insuranceId: string;
    amountTotal: number;
    contract: cart_cart_insurances_contract_HomeInsuranceContract;
}) => {
    const theme = useTheme();
    const details = [
        { label: <LoctoolMessage id="pages.sepa.paymentcard.insuranceid" />, value: insuranceId },
        { label: <LoctoolMessage id="pages.sepa.paymentcard.home.tarif" />, value: <LoctoolMessage id={"pages.sepa.paymentcard.home.tarif.premium"} /> },
        { label: <LoctoolMessage id="pages.sepa.paymentcard.home.period" />, value: <LoctoolMessage id="common.paymentPeriodMonths" values={{ paymentPeriodMonths: contract.paymentPeriodMonths }} /> },
    ];
    const cartItems: {
        label: string | JSX.Element;
        value: string | JSX.Element;
    }[] = [];
    const total = { label: <LoctoolMessage id="pages.sepa.paymentcard.total" />, value: <LoctoolNumber value={amountTotal} format="currency" currency="EUR" /> };
    return (
        <Surface as="table" $variant="greyBorder" $style={{ backgroundColor: theme.color.white, borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
            <Text as="caption" $variant="h3Mobile" $style={{ captionSide: "top", padding: "40px 16px 16px", fontWeight: 700, textAlign: "left", whiteSpace: "nowrap" }}>
                <LoctoolMessage id="pages.cart.cart.title" />
            </Text>
            <thead>
                <tr>
                    <Box
                        as="th"
                        colSpan={2}
                        scope="colgroup"
                        $style={{
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16,
                            backgroundColor: theme.color.greyN,
                            boxShadow: `inset 0 -1px 0 0 ${theme.color.greyD}`,
                            textAlign: "left",
                            padding: "12px 16px",
                        }}
                    >
                        <Flex.Container $style={{ columnGap: 16, alignItems: "center" }}>
                            <Flex.Item $shrink="shrink" $style={{ alignSelf: "flex-start" }}>
                                <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                                    <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                                        <SVGSharedIcon68X72ProductsHomeShadow width={68 / 2} height={72 / 2} />
                                    </Box>

                                    <Box as="span" $style={{ display: "block", position: "absolute" }}>
                                        <SVGSharedIcon68X72ProductsHome color={theme.color.greenN} width={68 / 2} height={72 / 2} />
                                    </Box>
                                </Box>
                            </Flex.Item>
                            <Flex.Item $shrink="auto">
                                <LoctoolMessage id="enums.insuranceType.DE_HOME_PACKAGES" />
                            </Flex.Item>
                        </Flex.Container>
                    </Box>
                </tr>
            </thead>
            <tbody>
                {details.map((detail, index) => {
                    return (
                        <tr key={index}>
                            <Box
                                as="th"
                                scope="row"
                                $style={{
                                    position: index + 1 === details.length ? "relative" : undefined,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    backgroundColor: index + 1 === details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 0 0 12px" : index + 1 === details.length ? "0 0 12px 12px" : "0 0 0 12px",
                                    "&::before": index + 1 === details.length ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomLeftRadius: 16 } : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.label}</Box>
                            </Box>
                            <Box
                                as="td"
                                $style={{
                                    position: index + 1 === details.length ? "relative" : undefined,
                                    textAlign: "right",
                                    backgroundColor: index + 1 === details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 12px 0 0" : index + 1 === details.length ? "0 12px 12px 0" : "0 12px 0 0",
                                    verticalAlign: "top",
                                    "&::before": index + 1 === details.length ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomRightRadius: 16 } : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.value}</Box>
                            </Box>
                        </tr>
                    );
                })}
            </tbody>
            {cartItems.length > 0 && (
                <tbody>
                    {cartItems.map((detail, index) => {
                        return (
                            <tr key={index}>
                                <Box
                                    as="th"
                                    scope="row"
                                    $style={{
                                        fontWeight: 400,
                                        textAlign: "left",
                                        padding: index === 0 ? "8px 4px 4px 16px" : "4px 4px 4px 16px",
                                    }}
                                    $styleLarge={{ boxShadow: "none", padding: `${index > 0 ? 4 : 8}px 4px ${index + 1 === cartItems.length ? 8 : 4}px 16px` }}
                                >
                                    {detail.label}
                                </Box>
                                <Box
                                    as="td"
                                    $style={{
                                        fontWeight: 700,
                                        textAlign: "right",
                                        padding: index === 0 ? "8px 16px 4px 4px" : "4px 16px 4px 4px",
                                        verticalAlign: "top",
                                    }}
                                    $styleLarge={{ boxShadow: "none", padding: `${index > 0 ? 4 : 8}px 16px ${index + 1 === cartItems.length ? 8 : 4}px 4px` }}
                                >
                                    {detail.value}
                                </Box>
                            </tr>
                        );
                    })}
                </tbody>
            )}

            <tfoot>
                <tr>
                    <Box as="th" scope="row" $style={{ fontWeight: 400, textAlign: "left", verticalAlign: "top", padding: "12px 4px 8px 16px" }}>
                        <Text $variant="h3Desktop">{total.label}</Text>
                    </Box>
                    <Box as="td" $style={{ fontWeight: 700, textAlign: "right", padding: "12px 16px 8px 4px", whiteSpace: "nowrap" }}>
                        <Text $variant="h3Desktop">{total.value}</Text>
                    </Box>
                </tr>
            </tfoot>
        </Surface>
    );
};

export const SepaPaymentCart_ModularTravel = ({
    insuranceId,
    amountTotal,
    isAnnual,
    contract,
}: {
    insuranceId: string;
    amountTotal: number;
    isAnnual: boolean;
    contract: cart_cart_insurances_contract_ModularTravelDEInsuranceContract;
}) => {
    const theme = useTheme();
    const members = contract.members.filter(m => m.deleted === null);
    let travelInsureeCategory = "single";
    if (members.length === 2) {
        travelInsureeCategory = "couple";
    } else if (members.length > 2) {
        travelInsureeCategory = "family";
    }
    const details = [
        { label: <LoctoolMessage id="pages.sepa.paymentcard.insuranceid" />, value: insuranceId },
        { label: <LoctoolMessage id="pages.sepa.paymentcard.modtravel.tarif" />, value: <LoctoolMessage id={`enums.travelInsureeCategory.${travelInsureeCategory}`} /> },
        { label: <LoctoolMessage id="pages.sepa.paymentcard.modtravel.duration" />, value: <LoctoolMessage id={isAnnual ? "enums.travelDuration.annual" : "enums.travelDuration.single"} /> },
        {
            label: <LoctoolMessage id="pages.sepa.paymentcard.modtravel.destination" />,
            value: <LoctoolMessage id={`enums.travelDestination.${contract.modTravelDeData?.destinationCategory ?? "europe"}`} />,
        },
    ];
    const coverageBaseItems: { [key in CoverageBase]: string[] } = {
        cancellationInterruption: ["cancellation", "interruption"],
        health: ["health"],
        healthAndCancellation: ["health", "cancellation"],
        healthAndLuggage: ["health", "luggage"],
        luggage: ["luggage"],
        luggageAndCancellation: ["luggage", "cancellation"],
        full: ["cancellation", "interruption", "health", "luggage"],
    };

    const cartItems: {
        label: string | JSX.Element;
        value: string | JSX.Element;
    }[] = [];
    if (contract.modTravelDeData?.moduleCategory) {
        coverageBaseItems[contract.modTravelDeData?.moduleCategory].forEach(item => {
            cartItems.push({ label: <LoctoolMessage id={`pages.sepa.paymentcard.modtravel.coverages.${item}`} />, value: <LoctoolMessage id="pages.sepa.paymentcard.modtravel.coverageincluded" /> });
        });
    }
    const total = { label: <LoctoolMessage id="pages.sepa.paymentcard.total" />, value: <LoctoolNumber value={amountTotal} format="currency" currency="EUR" /> };
    return (
        <Surface as="table" $variant="greyBorder" $style={{ backgroundColor: theme.color.white, borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
            <Text as="caption" $variant="h3Mobile" $style={{ captionSide: "top", padding: "40px 16px 16px", fontWeight: 700, textAlign: "left", whiteSpace: "nowrap" }}>
                <LoctoolMessage id="pages.cart.cart.title" />
            </Text>
            <thead>
                <tr>
                    <Box
                        as="th"
                        colSpan={2}
                        scope="colgroup"
                        $style={{
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16,
                            backgroundColor: theme.color.greyN,
                            boxShadow: `inset 0 -1px 0 0 ${theme.color.greyD}`,
                            textAlign: "left",
                            padding: "12px 16px",
                        }}
                    >
                        <Flex.Container $style={{ columnGap: 16, alignItems: "center" }}>
                            <Flex.Item $shrink="shrink" $style={{ alignSelf: "flex-start" }}>
                                <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                                    <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                                        <SVGSharedIcon68X72ProductsTravelShadow width={68 / 2} height={72 / 2} />
                                    </Box>

                                    <Box as="span" $style={{ display: "block", position: "absolute" }}>
                                        <SVGSharedIcon68X72ProductsTravel color={theme.color.greenN} width={68 / 2} height={72 / 2} />
                                    </Box>
                                </Box>
                            </Flex.Item>
                            <Flex.Item $shrink="auto">
                                <LoctoolMessage id={`enums.insuranceType.${isAnnual ? "DE_TRAVEL_MOD_A" : "DE_TRAVEL_MOD_S"}`} />
                            </Flex.Item>
                        </Flex.Container>
                    </Box>
                </tr>
            </thead>
            <tbody>
                {details.map((detail, index) => {
                    return (
                        <tr key={index}>
                            <Box
                                as="th"
                                scope="row"
                                $style={{
                                    position: index + 1 === details.length ? "relative" : undefined,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    backgroundColor: index + 1 === details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 0 0 12px" : index + 1 === details.length ? "0 0 12px 12px" : "0 0 0 12px",
                                    "&::before": index + 1 === details.length ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomLeftRadius: 16 } : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.label}</Box>
                            </Box>
                            <Box
                                as="td"
                                $style={{
                                    position: index + 1 === details.length ? "relative" : undefined,
                                    textAlign: "right",
                                    backgroundColor: index + 1 === details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 12px 0 0" : index + 1 === details.length ? "0 12px 12px 0" : "0 12px 0 0",
                                    verticalAlign: "top",
                                    "&::before": index + 1 === details.length ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomRightRadius: 16 } : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.value}</Box>
                            </Box>
                        </tr>
                    );
                })}
            </tbody>
            {cartItems.length > 0 && (
                <tbody>
                    {cartItems.map((detail, index) => {
                        return (
                            <tr key={index}>
                                <Box
                                    as="th"
                                    scope="row"
                                    $style={{
                                        fontWeight: 400,
                                        textAlign: "left",
                                        padding: index === 0 ? "8px 4px 4px 16px" : "4px 4px 4px 16px",
                                    }}
                                    $styleLarge={{ boxShadow: "none", padding: `${index > 0 ? 4 : 8}px 4px ${index + 1 === cartItems.length ? 8 : 4}px 16px` }}
                                >
                                    {detail.label}
                                </Box>
                                <Box
                                    as="td"
                                    $style={{
                                        fontWeight: 700,
                                        textAlign: "right",
                                        padding: index === 0 ? "8px 16px 4px 4px" : "4px 16px 4px 4px",
                                        verticalAlign: "top",
                                    }}
                                    $styleLarge={{ boxShadow: "none", padding: `${index > 0 ? 4 : 8}px 16px ${index + 1 === cartItems.length ? 8 : 4}px 4px` }}
                                >
                                    {detail.value}
                                </Box>
                            </tr>
                        );
                    })}
                </tbody>
            )}

            <tfoot>
                <tr>
                    <Box as="th" scope="row" $style={{ boxShadow: `0 -1px 0 0 ${theme.color.greyN}`, fontWeight: 400, textAlign: "left", verticalAlign: "top", padding: "12px 4px 8px 16px" }}>
                        <Text $variant="h3Desktop">{total.label}</Text>
                    </Box>
                    <Box as="td" $style={{ boxShadow: `0 -1px 0 0 ${theme.color.greyN}`, fontWeight: 700, textAlign: "right", padding: "12px 16px 8px 4px", whiteSpace: "nowrap" }}>
                        <Text $variant="h3Desktop">{total.value}</Text>
                    </Box>
                </tr>
            </tfoot>
        </Surface>
    );
};

export const SepaPaymentCart_LifeInsurance = ({
    insuranceId,
    insureeCount,
    insuranceAmountId,
    billingPeriod,
    amountTotal,
}: {
    insuranceId: string;
    insureeCount: number;
    insuranceAmountId: number;
    billingPeriod: number;
    amountTotal: number;
}) => {
    const theme = useTheme();
    const insuranceAmountIds: { [key: string]: number } = {
        "101": 20000,
        "102": 35000,
        "103": 50000,
        "104": 75000,
        "105": 100000,
    };
    const payoutLimit = insuranceAmountIds[insuranceAmountId] ?? 20000;
    const details = [
        { label: <LoctoolMessage id="pages.sepa.paymentcard.insuranceid" />, value: insuranceId },
        { label: <LoctoolMessage id="pages.sepa.paymentcard.lifeinsurance.insurees" />, value: insureeCount },
        { label: <LoctoolMessage id="pages.sepa.paymentcard.lifeinsurance.payoutlimit" />, value: <LoctoolNumber value={payoutLimit} format="currency" currency="EUR" /> },
        { label: <LoctoolMessage id="pages.sepa.paymentcard.lifeinsurance.period" />, value: <LoctoolMessage id="common.paymentPeriodMonths" values={{ paymentPeriodMonths: billingPeriod }} /> },
    ];

    const total = { label: <LoctoolMessage id="pages.sepa.paymentcard.total" />, value: <LoctoolNumber value={amountTotal} format="currency" currency="EUR" /> };
    return (
        <Surface as="table" $variant="greyBorder" $style={{ backgroundColor: theme.color.white, borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
            <Text as="caption" $variant="h3Mobile" $style={{ captionSide: "top", padding: "40px 16px 16px", fontWeight: 700, textAlign: "left", whiteSpace: "nowrap" }}>
                <LoctoolMessage id="pages.cart.cart.title" />
            </Text>
            <thead>
                <tr>
                    <Box
                        as="th"
                        colSpan={2}
                        scope="colgroup"
                        $style={{
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16,
                            backgroundColor: theme.color.greyN,
                            boxShadow: `inset 0 -1px 0 0 ${theme.color.greyD}`,
                            textAlign: "left",
                            padding: "12px 16px",
                        }}
                    >
                        <Flex.Container $style={{ columnGap: 16, alignItems: "center" }}>
                            <Flex.Item $shrink="shrink" $style={{ alignSelf: "flex-start" }}>
                                <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                                    <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                                        <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                                            <SVGSharedIcon72X71ProductsAccidentShadow width={72 / 2} height={72 / 2} />
                                        </Box>

                                        <Box as="span" $style={{ display: "block", position: "absolute" }}>
                                            <SVGSharedIcon72X71ProductsAccident color={theme.color.greenN} width={72 / 2} height={72 / 2} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Flex.Item>
                            <Flex.Item $shrink="auto">
                                <LoctoolMessage id={"enums.insuranceType.LIFE"} />
                            </Flex.Item>
                        </Flex.Container>
                    </Box>
                </tr>
            </thead>
            <tbody>
                {details.map((detail, index) => {
                    return (
                        <tr key={index}>
                            <Box
                                as="th"
                                scope="row"
                                $style={{
                                    position: index + 1 === details.length ? "relative" : undefined,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    backgroundColor: index + 1 === details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 0 0 12px" : index + 1 === details.length ? "0 0 12px 12px" : "0 0 0 12px",
                                    "&::before": index + 1 === details.length ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomLeftRadius: 16 } : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.label}</Box>
                            </Box>
                            <Box
                                as="td"
                                $style={{
                                    position: index + 1 === details.length ? "relative" : undefined,
                                    textAlign: "right",
                                    backgroundColor: index + 1 === details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 12px 0 0" : index + 1 === details.length ? "0 12px 12px 0" : "0 12px 0 0",
                                    verticalAlign: "top",
                                    "&::before": index + 1 === details.length ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomRightRadius: 16 } : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.value}</Box>
                            </Box>
                        </tr>
                    );
                })}
            </tbody>

            <tfoot>
                <tr>
                    <Box as="th" scope="row" $style={{ boxShadow: `0 -1px 0 0 ${theme.color.greyN}`, fontWeight: 400, textAlign: "left", verticalAlign: "top", padding: "12px 4px 8px 16px" }}>
                        <Text $variant="h3Desktop">{total.label}</Text>
                    </Box>
                    <Box as="td" $style={{ boxShadow: `0 -1px 0 0 ${theme.color.greyN}`, fontWeight: 700, textAlign: "right", padding: "12px 16px 8px 4px", whiteSpace: "nowrap" }}>
                        <Text $variant="h3Desktop">{total.value}</Text>
                    </Box>
                </tr>
            </tfoot>
        </Surface>
    );
};

export const SepaPaymentCart = () => {
    return null;
};

/*
export const SepaPaymentCart = ({ insuranceType, insurance }: { insuranceType: InsuranceType; insurance: cart_cart_insurances }) => {
    const theme = useTheme();

    let productIcon = null;
    switch (insuranceType) {
        case InsuranceType.DE_TRAVEL_MOD_A:
        case InsuranceType.DE_TRAVEL_MOD_S: {
            productIcon = (
                <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                    <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                        <SVGSharedIcon68X72ProductsTravelShadow width={68 / 2} height={72 / 2} />
                    </Box>

                    <Box as="span" $style={{ display: "block", position: "absolute" }}>
                        <SVGSharedIcon68X72ProductsTravel color={theme.color.greenN} width={68 / 2} height={72 / 2} />
                    </Box>
                </Box>
            );
            break;
        }
        case InsuranceType.DE_HOME_PACKAGES: {
            productIcon = (
                <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                    <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                        <SVGSharedIcon68X72ProductsHomeShadow width={68 / 2} height={72 / 2} />
                    </Box>

                    <Box as="span" $style={{ display: "block", position: "absolute" }}>
                        <SVGSharedIcon68X72ProductsHome color={theme.color.greenN} width={68 / 2} height={72 / 2} />
                    </Box>
                </Box>
            );
            break;
        }
        case InsuranceType.LIFE: {
            productIcon = (
                <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                    <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                        <SVGSharedIcon72X71ProductsAccidentShadow width={72 / 2} height={72 / 2} />
                    </Box>

                    <Box as="span" $style={{ display: "block", position: "absolute" }}>
                        <SVGSharedIcon72X71ProductsAccident color={theme.color.greenN} width={72 / 2} height={72 / 2} />
                    </Box>
                </Box>
            );
        }
    }

    const DATA = {
        accident: {
            productIcon: (
                <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                    <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                        <SVGSharedIcon72X71ProductsAccidentShadow width={72 / 2} height={72 / 2} />
                    </Box>

                    <Box as="span" $style={{ display: "block", position: "absolute" }}>
                        <SVGSharedIcon72X71ProductsAccident color={theme.color.greenN} width={72 / 2} height={72 / 2} />
                    </Box>
                </Box>
            ),
            details: [
                { label: "Insurance ID", value: "CDE2161684" },
                { label: "Insurees", value: "2" },
                { label: "Payout limit", value: "€ 30.000,00" },
                { label: "Billing period", value: "12 month" },
            ],
            cartItems: [],
            total: { label: "Total:", value: 155.96 },
        },
        home: {
            productIcon: (
                <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                    <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                        <SVGSharedIcon68X72ProductsHomeShadow width={68 / 2} height={72 / 2} />
                    </Box>

                    <Box as="span" $style={{ display: "block", position: "absolute" }}>
                        <SVGSharedIcon68X72ProductsHome color={theme.color.greenN} width={68 / 2} height={72 / 2} />
                    </Box>
                </Box>
            ),
            details: [
                { label: "Insurance ID", value: "CDE2161684" },
                { label: "Tarif / Insurance type", value: "Premium" },
                { label: "Billing period", value: "12 month" },
            ],
            cartItems: [
                { label: "Basic package", value: "€80.00" },
                { label: "Elementary damages", value: "€30.00" },
                { label: "Glass insurance", value: "€20.00" },
                { label: "Bike insurance", value: "€1.99" },
            ],
            total: { label: "Total:", value: 131.99 },
        },
        travel: {
            productIcon: (
                <Box $style={{ display: "flex", justifyContent: "center", width: 32, height: 32, position: "relative" }}>
                    <Box as="span" $style={{ display: "block", position: "absolute", mixBlendMode: "multiply" }}>
                        <SVGSharedIcon68X72ProductsTravelShadow width={68 / 2} height={72 / 2} />
                    </Box>

                    <Box as="span" $style={{ display: "block", position: "absolute" }}>
                        <SVGSharedIcon68X72ProductsTravel color={theme.color.greenN} width={68 / 2} height={72 / 2} />
                    </Box>
                </Box>
            ),
            details: [
                { label: "Insurance ID", value: "CDE2161684" },
                { label: "Tarif / Insurees", value: "Family" },
                { label: "Duration", value: "Single trip" },
                { label: "Destination", value: "Europe" },
            ],
            cartItems: [
                { label: "Health", value: "€12,10" },
                { label: "Luggage", value: "€31,56" },
                { label: "Cancellation", value: "€112,30" },
            ],
            total: { label: "Total:", value: 155.96 },
        },
    };
    const details = DATA[product].details;
    const cartItems = DATA[product].cartItems;
    const total = DATA[product].total;

    return (
        <Surface as="table" $variant="greyBorder" $style={{ backgroundColor: theme.color.white, borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
            <Text as="caption" $variant="h3Mobile" $style={{ captionSide: "top", padding: "40px 16px 16px", fontWeight: 700, textAlign: "left", whiteSpace: "nowrap" }}>
                <LoctoolMessage id="pages.cart.cart.title" />
            </Text>
            <thead>
                <tr>
                    <Box
                        as="th"
                        colSpan={2}
                        scope="colgroup"
                        $style={{
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16,
                            backgroundColor: theme.color.greyN,
                            boxShadow: `inset 0 -1px 0 0 ${theme.color.greyD}`,
                            textAlign: "left",
                            padding: "12px 16px",
                        }}
                    >
                        <Flex.Container $style={{ columnGap: 16, alignItems: "center" }}>
                            <Flex.Item $shrink="shrink" $style={{ alignSelf: "flex-start" }}>
                                {DATA[product].productIcon}
                            </Flex.Item>
                            <Flex.Item $shrink="auto">
                                <LoctoolMessage id="pages.cart.cart.caption1" />
                            </Flex.Item>
                        </Flex.Container>
                    </Box>
                </tr>
            </thead>
            <tbody>
                {details.map((detail, index) => {
                    return (
                        <tr key={index}>
                            <Box
                                as="th"
                                scope="row"
                                $style={{
                                    position: index + 1 === details.length ? "relative" : undefined,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    backgroundColor: index + 1 === details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 0 0 12px" : index + 1 === details.length ? "0 0 12px 12px" : "0 0 0 12px",
                                    "&::before": index + 1 === details.length ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomLeftRadius: 16 } : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.label}</Box>
                            </Box>
                            <Box
                                as="td"
                                $style={{
                                    position: index + 1 === details.length ? "relative" : undefined,
                                    textAlign: "right",
                                    backgroundColor: index + 1 === details.length ? undefined : theme.color.greyN,
                                    padding: index === 0 ? "12px 12px 0 0" : index + 1 === details.length ? "0 12px 12px 0" : "0 12px 0 0",
                                    verticalAlign: "top",
                                    "&::before": index + 1 === details.length ? { content: '""', position: "absolute", inset: 0, backgroundColor: theme.color.greyN, borderBottomRightRadius: 16 } : {},
                                }}
                            >
                                <Box $style={{ position: "relative", padding: 4 }}>{detail.value}</Box>
                            </Box>
                        </tr>
                    );
                })}
            </tbody>
            {cartItems.length > 0 && (
                <tbody>
                    {cartItems.map((detail, index) => {
                        return (
                            <tr key={index}>
                                <Box
                                    as="th"
                                    scope="row"
                                    $style={{
                                        fontWeight: 400,
                                        textAlign: "left",
                                        padding: index === 0 ? "8px 4px 4px 16px" : "4px 4px 4px 16px",
                                    }}
                                    $styleLarge={{ boxShadow: "none", padding: `${index > 0 ? 4 : 8}px 4px ${index + 1 === cartItems.length ? 8 : 4}px 16px` }}
                                >
                                    {detail.label}
                                </Box>
                                <Box
                                    as="td"
                                    $style={{
                                        fontWeight: 700,
                                        textAlign: "right",
                                        padding: index === 0 ? "8px 16px 4px 4px" : "4px 16px 4px 4px",
                                        verticalAlign: "top",
                                    }}
                                    $styleLarge={{ boxShadow: "none", padding: `${index > 0 ? 4 : 8}px 16px ${index + 1 === cartItems.length ? 8 : 4}px 4px` }}
                                >
                                    {detail.value}
                                </Box>
                            </tr>
                        );
                    })}
                </tbody>
            )}

            <tfoot>
                <tr>
                    <Box as="th" scope="row" $style={{ boxShadow: `0 -1px 0 0 ${theme.color.greyN}`, fontWeight: 400, textAlign: "left", verticalAlign: "top", padding: "12px 4px 8px 16px" }}>
                        <Text $variant="h3Desktop">{total.label}</Text>
                    </Box>
                    <Box as="td" $style={{ boxShadow: `0 -1px 0 0 ${theme.color.greyN}`, fontWeight: 700, textAlign: "right", padding: "12px 16px 8px 4px", whiteSpace: "nowrap" }}>
                        <Text $variant="h3Desktop">{total.value}</Text>
                    </Box>
                </tr>
            </tfoot>
        </Surface>
    );
};
*/
