import React, { useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router";
import { GTM, usePrevious } from "@monster/shared";
import { Loctool } from "@monster/loctool";
import { Helpers } from "@utils/Helpers";
import { store } from "@redux/store";

type Props = { children: React.ReactNode | undefined };

const RouteContainer = (props: Props) => {
    const location = useLocation();
    const prevPathname = usePrevious(location.pathname);
    useLayoutEffect(() => {
        if (prevPathname && location.pathname !== prevPathname) {
            Loctool.clearUsedIntlKeys();
        }
        if (location.pathname !== prevPathname) {
            GTM.pushVirtualPageView({
                flow: "dashboard",
                product: "dashboard",
                siteLanguage: "de",
                userCountry: "DE",
                virtualPageURL: location.pathname,
                userId: store.getState().session?.accountData?.id ?? "unsigned",
            });
        }
    }, [location, prevPathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
        Helpers.refreshLivePersonTagImmediate();
    }, [location.pathname]);

    return <>{props.children}</>;
};

export default RouteContainer;
