import { ReduxHelpers, ActionsUnion } from "@monster/shared";

export enum AppStateActionTypes {
    RESET = "AppStateAction/RESET",
}

export const AppStateActions = {
    reset: () => ReduxHelpers.createAction(AppStateActionTypes.RESET),
};

export type AppStateActions = ActionsUnion<typeof AppStateActions>;
