import React, { useMemo, useState } from "react";
import { Button } from "@monster/chr-ui";
import { SVGChrIcon32BasicsArrowOpen } from "@monster/chr-ui/dist/svg_assets";
import { LoctoolMessage } from "@monster/loctool";
import { Box, Flex, flexItemShrinkVariant } from "@monster/shared";
import { useTheme } from "styled-components";
import { GqlDeWebapp } from "@api/Api";
import { DeHomeInsuranceContractWithInsuranceType } from "@api/CustomGqlTypes";

type Props = {
    insurance: DeHomeInsuranceContractWithInsuranceType | GqlDeWebapp.Session_germanTravelInsuranceContracts;
};

export const ContractAccordion = ({ insurance, children }: React.PropsWithChildren<Props>) => {
    const theme = useTheme();
    const [isOpen, setOpen] = useState(false);
    const accordionTitle = useMemo(() => {
        if (insurance.__typename === "DeHomeInsuranceContract") {
            return <LoctoolMessage id="pages.dashboard.contractAccordion.HOME.title" values={{ zipCode: insurance.subjectZipCode }} />;
        }
        if (insurance.insuranceType === GqlDeWebapp.InsuranceType.DE_TRAVEL_MOD_S) {
            return <LoctoolMessage id="pages.dashboard.contractAccordion.DE_TRAVEL_MOD_S.title" />;
        }
        return <LoctoolMessage id="pages.dashboard.contractAccordion.DE_TRAVEL_MOD_A.title" />;
    }, [insurance]);

    return (
        <>
            <Flex.Container
                as="li"
                $style={{ columnGap: 16, flexWrap: "wrap", padding: "15px 64px 9px 16px", boxShadow: `0px -1px 0px 0px ${theme.color.greyD}`, position: "relative" }}
                $styleMedium={{ padding: "15px 64px 17px 16px" }}
            >
                <Flex.Item
                    $shrink={1}
                    $styleMedium={{
                        ...flexItemShrinkVariant({
                            $shrink: "auto",
                        }),
                        fontWeight: 600,
                    }}
                >
                    {accordionTitle}
                </Flex.Item>
                <Box $style={{ position: "absolute", top: 17, right: 16 }} $styleMedium={{ top: 11 }}>
                    <Button.IconOnly
                        size="xsmall"
                        style={{ boxShadow: "none" }}
                        btnLabel={<LoctoolMessage id="pages.dashboard.toggleArchives" />}
                        icon={<SVGChrIcon32BasicsArrowOpen style={{ transform: isOpen ? "rotate(180deg)" : "none" }} />}
                        onClick={() => setOpen(!isOpen)}
                    />
                </Box>
            </Flex.Container>
            <div aria-atomic="true" aria-live="polite">
                {isOpen && children}
            </div>
        </>
    );
};
