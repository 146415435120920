import { LegalDocumentActionTypes } from "@redux/actions/legalDocumentActions";
import { ReduxAction } from "@redux/actions/ReduxAction";
import { LegalDocumentWithCase } from "@api/CustomGqlTypes";

export type LegalDocumentReducerType = {
    effectiveFrom: string | null;
    data: LegalDocumentWithCase[];
};

const initialState: LegalDocumentReducerType = {
    effectiveFrom: null,
    data: [],
};

export default function legalDocumentReducer(state: LegalDocumentReducerType = initialState, action: ReduxAction): LegalDocumentReducerType {
    switch (action.type) {
        case LegalDocumentActionTypes.UPDATE_LEGAL_DOCUMENTS:
            return { effectiveFrom: action.payload.effectiveFrom, data: [...action.payload.legalDocuments] };
        default:
            return state;
    }
}
