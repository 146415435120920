import { gql } from "@apollo/client";

export const SelfserviceQueries = Object.freeze({
    cart: gql`
        query cart {
            cart {
                id
                amountTotal {
                    amount
                }
                cartState
                insurances {
                    id
                    insuranceType
                    totalPayableAmountForInsurance
                    billingPeriod
                    contract {
                        ... on DeHomeInsuranceContract {
                            id
                            paymentPeriodMonths
                            baseOfCoverage
                            riders {
                                elementary
                                glass
                                bicycle
                            }
                        }
                        ... on ModularTravelDEInsuranceContract {
                            id
                            members {
                                ageCategory
                                deleted
                            }
                            modTravelDeData {
                                destinationCategory
                                moduleCategory
                            }
                        }
                        ... on LifeInsuranceContract {
                            id
                            insuranceAmountId
                            payoutLimit {
                                amount
                                currency
                            }
                            country
                        }
                        ... on HomeInsuranceContract {
                            id
                            paymentPeriodMonths
                            country
                            residencyCode
                            currency
                        }
                    }
                }
                getAvailablePaymentOptions {
                    currency
                    paymentMethod
                    provider
                    canBeSaved
                    canBeRecurring
                    isSaved
                    savedPaymentMethodId
                    savedPaymentMethodHint
                }
            }
        }
    `,
});
