import GraphQLClient, { GQLClient } from "./graphql/GraphQLClient";
import * as GqlGlobal from "./graphql/global/types";
import * as GqlDeWebapp from "./graphql/deWebapp/types";
import * as GqlSelfservice from "./graphql/selfservice/types";
import { DeWebappQueries } from "./graphql/deWebapp/DeWebappQueries";
import { GlobalQueries } from "./graphql/global/GlobalQueries";
import { DeWebappMutations } from "./graphql/deWebapp/DeWebappMutations";
import { SelfserviceQueries } from "./graphql/selfservice/SelfserviceQueries";
import { SelfserviceMutations } from "./graphql/selfservice/SelfserviceMutations";
import { legalDocumentUseCase, LegalDocumentWithCase, ExtendedTransactionResult } from "./CustomGqlTypes";

export class Api {
    public static async getServerTime(): Promise<string> {
        const result = await GraphQLClient.query<GqlGlobal.time>({
            query: GlobalQueries.time,
            context: { client: "global" },
        });

        return result.time;
    }

    public static async getSession(): Promise<GqlDeWebapp.Session> {
        const result = await GraphQLClient.query<GqlDeWebapp.getSession>({ query: DeWebappQueries.getSession, context: { client: GQLClient.DEWebapp } });

        if (!result.session) {
            throw new Error("Session not found");
        }
        return result.session;
    }

    public static async startSession(): Promise<GqlDeWebapp.Session> {
        const result = await GraphQLClient.mutate<GqlDeWebapp.startSession>({ mutation: DeWebappMutations.startSession, context: { client: GQLClient.DEWebapp } });

        return result.startSession;
    }

    public static async sendValidationEmail(email: string): Promise<GqlDeWebapp.VoidType> {
        const result = await GraphQLClient.mutate<GqlDeWebapp.sendValidationEmail, GqlDeWebapp.sendValidationEmailVariables>({
            mutation: DeWebappMutations.sendValidationEmail,
            variables: { email },
            context: { client: GQLClient.DEWebapp },
        });

        return result.sendValidationEmail;
    }

    public static async validateEmail(email: string, code: string): Promise<GqlDeWebapp.Session> {
        const result = await GraphQLClient.mutate<GqlDeWebapp.validateEmail, GqlDeWebapp.validateEmailVariables>({
            mutation: DeWebappMutations.validateEmail,
            variables: { email, code },
            context: { client: GQLClient.DEWebapp },
        });

        return result.validateEmail;
    }

    public static async getTransactionResult(transactionId: string): Promise<ExtendedTransactionResult> {
        const result = await GraphQLClient.query<GqlDeWebapp.getTransactionResult, GqlDeWebapp.getTransactionResultVariables>({
            query: DeWebappQueries.getTransactionResult,
            variables: { transactionId },
            context: { client: GQLClient.DEWebapp },
        });

        return { ...result.getTransactionResult, cartHistory: result.session?.accountData?.cartHistory ?? [] };
    }

    public static async applications(email: string): Promise<GqlGlobal.applications_applications[]> {
        const result = await GraphQLClient.query<GqlGlobal.applications, GqlGlobal.applicationsVariables>({
            query: GlobalQueries.applications,
            variables: { email },
            context: { client: GQLClient.Global },
        });

        return result.applications;
    }

    public static async getLegalDocuments(at: string): Promise<LegalDocumentWithCase[]> {
        const result = await GraphQLClient.query<GqlDeWebapp.getEffectiveLegalDocuments, GqlDeWebapp.getEffectiveLegalDocumentsVariables>({
            query: DeWebappQueries.legalDocuments,
            variables: { at },
            context: { client: GQLClient.DEWebapp },
        });

        const accountLegalDocuments = result.accountLegalDocuments.map((doc): LegalDocumentWithCase => ({ ...doc, useCase: legalDocumentUseCase.accountCreation }));
        const travelSingleTrip = result.travelSingleTrip.map((doc): LegalDocumentWithCase => ({ ...doc, useCase: legalDocumentUseCase.travelSingleTrip }));
        const travelAnnual = result.travelAnnual.map((doc): LegalDocumentWithCase => ({ ...doc, useCase: legalDocumentUseCase.travelAnnual }));
        const homeInsurance = result.homeInsurance.map((doc): LegalDocumentWithCase => ({ ...doc, useCase: legalDocumentUseCase.homeInsurance }));
        return [...accountLegalDocuments, ...travelSingleTrip, ...travelAnnual, ...homeInsurance];
    }

    public static async sendDocumentEmail(insuranceId: string, fileName: string): Promise<GqlDeWebapp.VoidType> {
        const result = await GraphQLClient.mutate<GqlDeWebapp.sendContractDocumentEmail, GqlDeWebapp.sendContractDocumentEmailVariables>({
            mutation: DeWebappMutations.sendContractDocumentEmail,
            variables: { insuranceId, fileName },
            context: { client: GQLClient.DEWebapp },
        });

        return result.sendContractDocumentEmail;
    }

    public static async rePay(insuranceId: string): Promise<GqlDeWebapp.rePay_rePay> {
        const result = await GraphQLClient.mutate<GqlDeWebapp.rePay, GqlDeWebapp.rePayVariables>({
            mutation: DeWebappMutations.rePay,
            variables: { insuranceId },
            context: { client: GQLClient.DEWebapp },
        });

        return result.rePay;
    }

    public static async getSelfserviceCart(token: string): Promise<GqlSelfservice.cart_cart> {
        const result = await GraphQLClient.query<GqlSelfservice.cart>({
            query: SelfserviceQueries.cart,
            context: { client: GQLClient.Selfservice, headers: { authorization: token } },
        });

        return result.cart;
    }

    public static async selfServiceSepaPay(token: string): Promise<string> {
        const result = await GraphQLClient.mutate<GqlSelfservice.pay>({
            mutation: SelfserviceMutations.pay,
            context: { client: GQLClient.Selfservice, headers: { authorization: token } },
        });

        if (result.pay.isOneClickPayment && result.pay.oneClickPaymentResult) {
            return `${process.env.REACT_APP_URL}/separesult?TransactionId=${result.pay.oneClickPaymentResult.transactionId}`;
        } else if (result.pay.standardPaymentResult) {
            return result.pay.standardPaymentResult;
        } else {
            throw new Error("Invalid result");
        }
    }

    public static async selfServiceSetPaymentMethod(input: GqlSelfservice.SelectedPaymentOptionInput, token: string): Promise<void> {
        await GraphQLClient.mutate<GqlSelfservice.setPaymentOption, GqlSelfservice.setPaymentOptionVariables>({
            mutation: SelfserviceMutations.setPaymentOption,
            variables: { selectedPaymentOption: input },
            context: { client: GQLClient.Selfservice, headers: { authorization: token } },
        });
    }

    public static async selfServiceGetTransactionResult(transactionId: string, token: string): Promise<GqlSelfservice.getTransactionResult_handleClientCallback> {
        const result = await GraphQLClient.mutate<GqlSelfservice.getTransactionResult, GqlSelfservice.getTransactionResultVariables>({
            mutation: SelfserviceMutations.getTransactionResult,
            variables: {
                transactionId,
            },
            context: { client: GQLClient.Selfservice, headers: { authorization: token } },
        });

        return result.handleClientCallback;
    }

    /**
     * Create session for claim app & return the url (-_-)
     */
    public static async createSessionByExternalInsuranceId(input: GqlDeWebapp.createSessionByExternalInsuranceIdVariables): Promise<string> {
        const result = await GraphQLClient.mutate<GqlDeWebapp.createSessionByExternalInsuranceId, GqlDeWebapp.createSessionByExternalInsuranceIdVariables>({
            mutation: DeWebappMutations.createSessionByExternalInsuranceId,
            variables: input,
            context: { client: GQLClient.DEWebapp },
        });
        return result.createSessionByExternalInsuranceId;
    }
}

export { GqlGlobal, GqlDeWebapp };
