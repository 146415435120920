import React, { useState } from "react";
import { useTheme } from "styled-components";
import { Box, Flex, flexItemShrinkVariant } from "@monster/shared";
import { CodeForm } from "./CodeForm";
import { EmailAddressForm } from "./EmailAddressForm";

export const LoginPage = () => {
    const theme = useTheme();
    const [email, setEmail] = useState("");

    return (
        <Flex.Container $style={{ height: "100vh", backgroundColor: theme.color.white }}>
            <Flex.Item $shrink={1} $style={{ height: "100%" }}>
                <Flex.Container $style={{ height: "100%", flexDirection: "column", overflow: "auto" }} $styleMedium={{ alignItems: "center" }}>
                    {email ? <CodeForm email={email} onBack={() => setEmail("")} /> : <EmailAddressForm onSubmit={setEmail} />}
                </Flex.Container>
            </Flex.Item>
            <Flex.Item $shrink="shrink" $styleLarge={flexItemShrinkVariant({ $shrink: 1 })}>
                <Box
                    $style={{ display: "none" }}
                    $styleLarge={{
                        display: "block",
                        height: "100vh",
                        backgroundImage: 'image-set( url("/images/login-bg.png") 1x, url("/images/login-bg@2x.png") 2x, url("/images/login-bg@3x.png") 3x)',
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                ></Box>
            </Flex.Item>
        </Flex.Container>
    );
};
