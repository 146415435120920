import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { Box, Cookie, Flex, GTM, useLoading } from "@monster/shared";
import { Text, Button, Dialog, DialogContent, useNotification } from "@monster/chr-ui";
import { useHistory } from "react-router-dom";
import { Api, GqlDeWebapp } from "@api/Api";
import { ExtendedTransactionResult } from "@api/CustomGqlTypes";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "@redux/Reducers";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { Helpers } from "@utils/Helpers";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { LoctoolMessage } from "@monster/loctool";
import { Path } from "@utils/Path";
import { SessionActions } from "@redux/actions/sessionActions";

enum AdCellEventId {
    travel = 14039,
    home = 15468,
}

export const TransactionResultDialog = () => {
    const theme = useTheme();
    const shadowBottom = useMemo(() => `0px -1px 0px 0px ${theme.color.greyD} inset`, [theme]);

    const [modalOpen, setModalOpen] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();
    const showNotification = useNotification();
    const session = useSelector((state: ApplicationState) => state.session);
    const email = session?.email;
    const userId = session?.accountData?.id;
    const [data, setData] = useState<ExtendedTransactionResult | null>(null);
    const transactionId = useMemo(() => Helpers.getLocationParameterByName("TransactionId", history.location.search), [history.location.search]);
    const transactionCookie = Cookie.transaction.get();
    const status = Helpers.getTransactionResultStatus(data?.resultCode);
    const contract = useSelector(SessionSelectors.getContractById(data?.insuranceId));
    const gtmSent = useRef(false);
    const adcellSent = useRef(false);
    const loading = useLoading();
    const getAdCellEventIdByInsuranceId = useCallback(
        (insuranceId: string | null) => {
            if (!insuranceId) {
                return null;
            }
            const isSameId = (c: { id: string | null }) => c.id === insuranceId;
            if (session?.germanHomeInsuranceContracts.some(isSameId)) {
                return AdCellEventId.home;
            }
            if (session?.germanHomeInsuranceContracts.some(isSameId)) {
                return AdCellEventId.travel;
            }
            return null;
        },
        [session]
    );
    const onButtonClick = useCallback(async () => {
        if (!data?.insuranceId) {
            return;
        }
        const status = Helpers.getTransactionResultStatus(data.resultCode);
        if (status === "success") {
            history.push(Path.contractDocument(data.insuranceId));
            return;
        }

        try {
            const result = await Api.rePay(data.insuranceId);
            if (result.isOneClickPayment && result.oneClickPaymentResult) {
                window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}?TransactionId=${result.oneClickPaymentResult?.transactionId}`;
            } else if (result.standardPaymentResult) {
                window.location.href = result.standardPaymentResult;
            } else {
                console.log("result is a never", result);
            }
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                showNotification(error.intlMessage);
            }
        }
    }, [data, history, showNotification]);

    useEffect(() => {
        if (modalOpen && userId && transactionId && data?.resultCode && Helpers.getTransactionResultStatus(data.resultCode) === "success" && adcellSent.current === false) {
            const eventId = getAdCellEventIdByInsuranceId(data.insuranceId);
            if (!eventId) {
                return;
            }

            adcellSent.current = true;
            const script = document.createElement("script");
            script.src = `https://t.adcell.com/t/track.js?pid=11072&eventid=${eventId}&referenz=${userId}`;
            script.async = true;

            document.body.appendChild(script);
            return () => {
                try {
                    document.body.removeChild(script);
                } catch (_e) {
                    // Catch error if script is already removed or not added
                }
            };
        }
    }, [data, getAdCellEventIdByInsuranceId, modalOpen, transactionId, userId]);

    const updateTransactionResult = useCallback(
        async (transactionId: string) => {
            try {
                loading.addLoading();
                const result = await Api.getTransactionResult(transactionId);
                setData(result);
                const session = await Api.getSession();
                dispatch(SessionActions.update(session));
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    showNotification(error.intlMessage);
                }
            }
            loading.removeLoading();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showNotification]
    );

    useEffect(() => {
        if (transactionId) {
            updateTransactionResult(transactionId);
        }
    }, [showNotification, transactionId, updateTransactionResult]);

    useEffect(() => {
        if (transactionId && Helpers.getTransactionResultStatus(data?.resultCode) === "pending") {
            const timeout = setTimeout(() => {
                updateTransactionResult(transactionId);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [data, transactionId, updateTransactionResult]);

    useEffect(() => {
        if (!data || gtmSent.current) {
            return;
        }

        const status = Helpers.getTransactionResultStatus(data.resultCode);

        if (status === "success" && transactionCookie) {
            GTM.pushPaymentSuccess({
                orderId: data.orderId,
                paymentMode: transactionCookie.paymentMode,
                paymentRecurringEnabled: transactionCookie.paymentRecurringEnabled?.toString(),
                currency: transactionCookie.currency,
                product: {
                    name: transactionCookie.insuranceType,
                    coupon: transactionCookie.coupon,
                    variant: getProductVariantForGTM(transactionCookie.variant as GqlDeWebapp.ModHomeBuildingType | GqlDeWebapp.Destination),
                    price: transactionCookie.price,
                },
                siteLanguage: "de",
                userCountry: "DE",
                userId: userId ?? "unsigned",
            });
            gtmSent.current = true;
        } else if (status === "success" && contract) {
            const cartId = contract.cartInsurances[0]?.cartExtId;
            const cart = data.cartHistory.find(({ id }) => id === cartId);

            GTM.pushPaymentSuccess({
                orderId: data.orderId,
                paymentMode: cart?.selectedPaymentOption?.paymentMethod,
                paymentRecurringEnabled: cart?.selectedPaymentOption?.beRecurring ? "true" : "false",
                currency: cart?.currency,
                product: {
                    name: contract.insuranceType,
                    coupon: cart?.coupons[0]?.name,
                    variant: getProductVariantForGTM("modTravelDeData" in contract ? contract.modTravelDeData?.destinationCategory : contract.buildingType),
                    price: cart?.amount,
                },
                siteLanguage: "de",
                userCountry: "DE",
                userId: userId ?? "unsigned",
            });
            gtmSent.current = true;
        }

        if (status === "failed") {
            GTM.pushPaymentFailure({ orderId: data.orderId, paymentError: data.resultMessage, siteLanguage: "de", userCountry: "DE", userId: userId ?? "unsigned" });
            gtmSent.current = true;
        }
    }, [data, contract, userId, transactionCookie]);

    if (!transactionId) {
        return null;
    }

    if (data === null || status === "pending") {
        return null;
    }

    return (
        <Dialog open={modalOpen} onOpenChange={setModalOpen}>
            <DialogContent onEscapeKeyDown={e => e.preventDefault()} onPointerDownOutside={e => e.preventDefault()} onInteractOutside={e => e.preventDefault()}>
                <Box $style={{ height: 56, boxShadow: shadowBottom }}>
                    <Flex.Container $style={{ justifyContent: "center", position: "relative", bottom: -28 }}>
                        <ModalImage />
                    </Flex.Container>
                </Box>
                <Box $style={{ paddingTop: 24 + 16, textAlign: "center" }}>
                    <Text $variant="h3Mobile" $style={{ fontWeight: 700 }}>
                        <LoctoolMessage id={`components.transactionResultDialog.${status}.title`} />
                    </Text>
                    <Text as="p" $variant="bodyMobile">
                        <LoctoolMessage id={`components.transactionResultDialog.${status}.message`} values={{ email }} />
                    </Text>
                </Box>
                <Flex.Container $style={{ paddingTop: 24, marginBottom: -24, justifyContent: "center", gap: 16 }}>
                    <Button.Primary btnLabel={<LoctoolMessage id={`components.transactionResultDialog.${status}.button`} />} onClick={onButtonClick} />
                    {status === "failed" && <Button.Secondary btnLabel={<LoctoolMessage id={`components.transactionResultDialog.${status}.closeButton`} />} onClick={() => setModalOpen(false)} />}
                </Flex.Container>
                <Box $style={{ backgroundColor: theme.color.greyL, padding: "48px 0 36px" }} $styleMedium={{ padding: "48px 32px 40px" }}>
                    <Box $style={{ border: `1px solid ${theme.color.greyD}`, borderRadius: 16 }}>
                        <Text $variant="bodyMobile" $style={{ padding: 16, fontWeight: 700, boxShadow: shadowBottom }}>
                            <LoctoolMessage id="components.transactionResultDialog.technicalDetails.title" />
                        </Text>

                        <Flex.Container $style={{ flexDirection: "column" }} $styleMedium={{ flexDirection: "row" }}>
                            <Flex.Item $shrink={1} $style={{ padding: 16, boxShadow: shadowBottom }} $styleMedium={{ boxShadow: "none" }}>
                                <Text $variant="bodyMobile">
                                    <LoctoolMessage id="components.transactionResultDialog.technicalDetails.title" />
                                </Text>
                                <Text $variant="captionMobile" $style={{ color: theme.color.blueL }}>
                                    {data.resultMessage}
                                </Text>
                            </Flex.Item>
                            <Flex.Item $shrink={1}>
                                <Box $style={{ padding: 16, boxShadow: shadowBottom }} $styleMedium={{ boxShadow: "none" }}>
                                    <Text $variant="bodyMobile">
                                        <LoctoolMessage id="components.transactionResultDialog.technicalDetails.transactionName" />
                                    </Text>
                                    <Text $variant="captionMobile" $style={{ color: theme.color.blueL }}>
                                        {data.orderId}
                                    </Text>
                                </Box>
                                <Box $style={{ padding: 16, boxShadow: shadowBottom }} $styleMedium={{ boxShadow: "none" }}>
                                    <Text $variant="bodyMobile">
                                        <LoctoolMessage id="components.transactionResultDialog.technicalDetails.transactionId" />
                                    </Text>
                                    <Text $variant="captionMobile" $style={{ color: theme.color.blueL }}>
                                        {data.transactionId}
                                    </Text>
                                </Box>
                            </Flex.Item>
                            <Flex.Item $shrink={1} $style={{ padding: 16 }}>
                                <Text $variant="bodyMobile">
                                    <LoctoolMessage id="components.transactionResultDialog.technicalDetails.referenceNumber" values={{ providerName: data.providerName }} />
                                </Text>
                                <Text $variant="captionMobile" $style={{ color: theme.color.blueL }}>
                                    {data.providerTransactionId}
                                </Text>
                            </Flex.Item>
                        </Flex.Container>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

const ModalImage = styled(Box)`
    min-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: ${({ theme }) => theme.color.greyN};
    background-image: image-set(url("/images/emma-default.png") 1x, url("/images/emma-default@2x.png") 2x, url("/images/emma-default@3x.png") 3x);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% auto;
`;

const getProductVariantForGTM = (variant?: GqlDeWebapp.ModHomeBuildingType | GqlDeWebapp.Destination | string | null) => {
    const productVariants: { [key: string]: string } = {
        [GqlDeWebapp.ModHomeBuildingType.flat]: "lakas",
        [GqlDeWebapp.ModHomeBuildingType.house]: "haz",
        [GqlDeWebapp.Destination.europe]: "europa",
        [GqlDeWebapp.Destination.america]: "amerikai",
        [GqlDeWebapp.Destination.notEUAM]: "nem_europa",
        EU: "europa",
        Worldwide: "amerikai",
        WorldwideWithoutAmerica: "nem_europa",
    };

    return variant ? productVariants[variant] : undefined;
};
