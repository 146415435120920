import { LegalDocumentSubCategory } from "./LegalDocumentUtils";

export class Path {
    public static readonly login = "/login";
    public static readonly dashboard = "/";
    public static contractDocument(contractId: string, subCategory: string = LegalDocumentSubCategory.CONTRACT_POLICY, serial?: string | number, version?: number | string) {
        if (serial) {
            if (typeof version !== "undefined") {
                return `/document/${contractId}/${subCategory}/${serial}/${version}`;
            }
            return `/document/${contractId}/${subCategory}/${serial}`;
        }
        return `/document/${contractId}/${subCategory}`;
    }
    public static readonly sepa = "/sepa/:token";
    public static readonly separesult = "/separesult";
}
