import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Box, Flex, useLoading, useCountdown } from "@monster/shared";
import { Button, Text, CodeInput, useNotification } from "@monster/chr-ui";
import { SVGChrBrandCherriskByUniqa } from "@monster/chr-ui/dist/svg_assets";
import { Api } from "@api/Api";
import { useDispatch } from "react-redux";
import { SessionActions } from "@redux/actions/sessionActions";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { LoctoolHTMLMessage, LoctoolMessage } from "@monster/loctool";

type CodeFormProps = {
    email: string;
    onBack: () => void;
};

const RESEND_TIMER_START_VALUE = 30;

export const CodeForm: React.FC<CodeFormProps> = props => {
    const theme = useTheme();
    const [code, setCode] = useState(["", "", "", ""]);
    const dispatch = useDispatch();
    const showNotification = useNotification();
    const loading = useLoading();
    const isIncomplete = code.some(value => value.length === 0);
    const [errorMessageId, setErrorMessageId] = useState<string | null>(null);
    const { restart, secondsRemaining } = useCountdown();

    useEffect(() => {
        setErrorMessageId(null);
    }, [code]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        loading.addLoading();
        try {
            const newSession = await Api.validateEmail(props.email, code.join(""));
            dispatch(SessionActions.update(newSession));
        } catch (error) {
            setErrorMessageId("pages.login.codeForm.invalidCode");
        }
        loading.removeLoading();
    };

    const onResendClick = async () => {
        try {
            await Api.sendValidationEmail(props.email);
            restart(RESEND_TIMER_START_VALUE);
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                showNotification(error.intlMessage);
            }
        }
    };

    return (
        <Box $style={{ padding: "0 16px", height: "100%", display: "flex", flexDirection: "column" }} $styleMedium={{ width: 440 }}>
            <Box $style={{ paddingTop: 32 }} $styleMedium={{ paddingTop: 48 }}>
                <SVGChrBrandCherriskByUniqa title="Cherrisk by Uniqa" color={theme.color.body.textColor} />
            </Box>
            <Box $style={{ paddingTop: 40 }} $styleXXLarge={{ marginTop: "auto" }}>
                <Text as="p" $variant="bodyMobile" $style={{ margin: "40px 0 22px", fontWeight: 700 }} $styleLarge={{ margin: "56px 0 30px" }} $styleXXLarge={{ margin: "auto 0 30px" }}>
                    <LoctoolHTMLMessage id="pages.login.codeForm.description" />
                </Text>
            </Box>

            <Box as="form" $style={{ paddingBottom: 32 }} onSubmit={handleSubmit}>
                <CodeInput id="login-pin" values={code} setValues={setCode} errorMessage={errorMessageId ? <LoctoolMessage id={errorMessageId} /> : null} />
                <Flex.Container $style={{ flexDirection: "column", gap: 24, padding: "32px 0" }} $styleLarge={{ padding: "32px 0", width: 234, margin: "0 auto" }}>
                    <Button.Primary type="submit" btnLabel={<LoctoolMessage id="pages.login.codeForm.continue" />} isExpanded disabled={isIncomplete} />
                    <Button.Secondary btnLabel={<LoctoolMessage id="pages.login.codeForm.back" />} isExpanded onClick={props.onBack} />
                </Flex.Container>
            </Box>
            <SeparatorWrapper>
                <Text $variant="bodyMobile" $style={{ padding: "0 16px", backgroundColor: theme.color.white, display: "inline-block", position: "relative" }}>
                    <LoctoolMessage id="pages.login.codeForm.noEmailYet" />
                </Text>
            </SeparatorWrapper>
            <Box $style={{ paddingBottom: 32, textAlign: "center" }}>
                {secondsRemaining > 0 ? (
                    <Text $variant="bodyMobile" $style={{ padding: "12px 0", backgroundColor: theme.color.white, display: "inline-block", position: "relative", color: theme.color.blueN }}>
                        <LoctoolMessage id="pages.login.codeForm.resendIn" values={{ seconds: secondsRemaining }} />
                    </Text>
                ) : (
                    <Button.Text btnLabel={<LoctoolMessage id="pages.login.codeForm.resend" />} onClick={onResendClick} />
                )}
            </Box>
        </Box>
    );
};

const SeparatorWrapper = styled.div`
    padding-bottom: 12px;
    text-align: center;
    position: relative;
    margin-top: auto;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 0;
        border-top: 1px solid ${({ theme }) => theme.color.greyD};
    }
`;
