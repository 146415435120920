import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Path } from "@utils/Path";
import { Loctool } from "@monster/loctool";
import RouteContainer from "@components/RouteContainer";
import { connect, DispatchProp } from "react-redux";
import { DashboardPage } from "@pages/Dashboard/DashboardPage";
import { LoginPage } from "@pages/Login/LoginPage";
import { ContractDocumentPage } from "@pages/ContractDocument/ContractDocumentPage";
import { SepaPaymentPage } from "@pages/SepaPayment/SepaPaymentPage";
import { SepaResultPage } from "@pages/SepaPayment/SepaResultPage";
import { Cookie, LoadingProp, withLoading } from "@monster/shared";
import { Api, GqlDeWebapp } from "@api/Api";
import { DateUtils, ServerDate } from "@utils/DateHelpers";
import { SessionActions } from "@redux/actions/sessionActions";
import { ApplicationState } from "@redux/Reducers";
import PrivateRoute from "@components/routes/PrivateRoute";
import GuestRoute from "@components/routes/GuestRoute";
import { Page500, NotificationProp, withNotification } from "@monster/chr-ui";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { LegalDocumentActions } from "@redux/actions/legalDocumentActions";

type ReduxProps = {
    session: GqlDeWebapp.Session | null;
};

type Props = ReduxProps & DispatchProp & NotificationProp & LoadingProp;

type State = {
    currentKey: number;
    isLoading: boolean;
    isErrorOccurred: boolean;
};

class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { currentKey: 0, isLoading: true, isErrorOccurred: false };
        window.lpAuthCallback = this.handleLivePersonAuthCallback;
    }

    handleLivePersonAuthCallback = (callback: Function) => {
        try {
            const token = this.props.session?.liveChatToken || null;
            callback(token);
        } catch (e) {
            callback(null);
        }
    };

    public async componentDidMount(): Promise<void> {
        Loctool.mount(this.onIntlConfigChange);
        const cookieSessionId = Cookie.authToken.get();
        try {
            await ServerDate.syncDateFromServer();
            const at = DateUtils.format(DateUtils.now());
            const legalDocuments = await Api.getLegalDocuments(at);
            this.props.dispatch(LegalDocumentActions.update(at, legalDocuments));
            let session: GqlDeWebapp.Session | null = null;
            try {
                if (cookieSessionId) {
                    session = await Api.getSession();
                } else {
                    session = await Api.startSession();
                    Cookie.authToken.set(session.sessionId);
                }
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    this.props.showNotification(error.intlMessage);
                }
            }

            this.props.dispatch(SessionActions.update(session));
            this.setState({ isLoading: false });
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                this.props.showNotification(error.intlMessage);
            }
            this.setState({ isErrorOccurred: true, isLoading: false });
        }
        this.props.removeLoading();
    }

    public componentWillUnmount(): void {
        Loctool.unmount();
    }

    public async componentDidUpdate(prevProps: Props): Promise<void> {
        if (prevProps.session && !this.props.session) {
            this.props.addLoading();
            try {
                const session = await Api.startSession();
                Cookie.authToken.set(session.sessionId);
                this.props.dispatch(SessionActions.update(session));
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    this.props.showNotification(error.intlMessage);
                }
                this.setState({ isErrorOccurred: true });
            }
            this.props.removeLoading();
        }
    }

    private onIntlConfigChange = (): void => {
        this.setState({ currentKey: this.state.currentKey + 1 });
    };

    public render() {
        if (this.state.isLoading) {
            return null;
        }

        if (this.state.isErrorOccurred) {
            return <Page500 />;
        }

        return (
            <RouteContainer key={this.state.currentKey}>
                <Switch>
                    <PrivateRoute exact path={Path.dashboard} component={DashboardPage} />
                    <GuestRoute exact path={Path.login} component={LoginPage} />
                    <PrivateRoute exact path={Path.contractDocument(":contractId", ":subCategory", ":serialNumber")} component={ContractDocumentPage} />
                    <PrivateRoute exact path={Path.contractDocument(":contractId", ":subCategory", ":serialNumber", ":version")} component={ContractDocumentPage} />
                    <PrivateRoute exact path={Path.contractDocument(":contractId", ":subCategory")} component={ContractDocumentPage} />
                    <Route path={Path.separesult} exact component={SepaResultPage} />
                    <Route path={Path.sepa} component={SepaPaymentPage} />
                    <Redirect from="*" to={Path.dashboard} />
                </Switch>
            </RouteContainer>
        );
    }
}

const mapStateToProps = ({ session }: ApplicationState): ReduxProps => {
    return {
        session,
    };
};

export default withLoading(withNotification(connect(mapStateToProps)(App)));
