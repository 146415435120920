import React from "react";
import { /*styled,*/ useTheme } from "styled-components";
import { Box, Flex, flexItemShrinkVariant } from "@monster/shared";
import { Text, Surface, Button } from "@monster/chr-ui";
import { Helpers } from "@utils/Helpers";
import { /*Loctool,*/ LoctoolMessage } from "@monster/loctool";

export const ContactUs = () => {
    const theme = useTheme();

    return (
        <Flex.Item $shrink={1} $styleLarge={{ ...flexItemShrinkVariant({ $shrink: "auto" }) }}>
            <Text as="h2" $variant="h2Mobile" $style={{ margin: "44px 16px 16px", fontWeight: 400 }} $styleLarge={{ margin: "12px 16px 20px" }}>
                <LoctoolMessage id="components.contactUs.h2" />
            </Text>
            {/* Note: C24IMP2-13-14 */}
            {/* <Surface
                $variant="white"
                $style={{
                    border: `1px solid ${theme.color.greyD}`,
                    padding: 16,
                    marginBottom: 32,
                    textAlign: "center",
                }}
            >
                <Box
                    $style={{
                        width: 100,
                        height: 80,
                        margin: "0 auto",
                        backgroundImage: 'image-set( url("/images/file-claim.png") 1x, url("/images/file-claim@2x.png") 2x, url("/images/file-claim@3x.png") 3x)',
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "auto 100%",
                    }}
                />
                <Text $variant="bodyMobile" $style={{ fontWeight: 700, margin: "16px 0" }}>
                    <LoctoolMessage id="components.contactUs.claim" />
                </Text>
                <ContactLink href={`mailto:${Loctool.instance.formatMessage({ id: "components.contactUs.email" })}`}>
                    <Text $variant="bodyMobile">
                        <LoctoolMessage id="components.contactUs.emailUs" values={{ email: Loctool.instance.formatMessage({ id: "components.contactUs.email" }) }} />
                    </Text>
                </ContactLink>
                <br />
                <ContactLink href={`tel:${Loctool.instance.formatMessage({ id: "components.contactUs.phoneNumber" }).replace(/\s/g, "")}`}>
                    <Text $variant="bodyMobile">
                        <LoctoolMessage id="components.contactUs.callUs" values={{ phoneNumber: Loctool.instance.formatMessage({ id: "components.contactUs.phoneNumber" }) }} />
                    </Text>
                </ContactLink>
            </Surface> */}
            <Surface
                $variant="white"
                $style={{
                    border: `1px solid ${theme.color.greyD}`,
                    padding: 16,
                    textAlign: "center",
                }}
            >
                <Box
                    $style={{
                        width: 100,
                        height: 80,
                        margin: "0 auto",
                        backgroundImage: 'image-set( url("/images/start-chat.png") 1x, url("/images/start-chat@2x.png") 2x, url("/images/start-chat@3x.png") 3x)',
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "auto 100%",
                    }}
                />
                <Text $variant="bodyMobile" $style={{ fontWeight: 700, margin: "16px 0" }}>
                    <LoctoolMessage id="components.contactUs.help" />
                </Text>
                <Box $style={{ paddingBottom: 24 - 16 }}>
                    <Button.Secondary btnLabel={<LoctoolMessage id="components.contactUs.startChat" />} onClick={Helpers.openLivePersonChat} />
                </Box>
            </Surface>
        </Flex.Item>
    );
};

// const ContactLink = styled.a`
//     color: ${({ theme }) => theme.color.blueN};
//     text-decoration: none;
//     display: inline-block;
//     padding: 12px 0;

//     &:hover {
//         color: ${({ theme }) => theme.color.greenN};
//     }
// `;
