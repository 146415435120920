/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: pay
// ====================================================

export interface pay_pay_oneClickPaymentResult {
    __typename: "TransactionResult";
    transactionId: string | null;
    resultCode: TransactionStatus;
    resultMessage: string | null;
    orderId: string | null;
    providerTransactionId: string | null;
    providerName: string | null;
    userId: string | null;
    insuranceId: string | null;
}

export interface pay_pay {
    __typename: "PaymentResult";
    isOneClickPayment: boolean;
    oneClickPaymentResult: pay_pay_oneClickPaymentResult | null;
    standardPaymentResult: string | null;
    insuranceId: string | null;
}

export interface pay {
    /**
     * Start the payment process again
     */
    pay: pay_pay;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: getTransactionResult
// ====================================================

export interface getTransactionResult_handleClientCallback {
    __typename: "TransactionResult";
    transactionId: string | null;
    resultCode: TransactionStatus;
    resultMessage: string | null;
    orderId: string | null;
    providerTransactionId: string | null;
    providerName: string | null;
    userId: string | null;
    insuranceId: string | null;
}

export interface getTransactionResult {
    /**
     * Client callback after the payment process has been started
     */
    handleClientCallback: getTransactionResult_handleClientCallback;
}

export interface getTransactionResultVariables {
    transactionId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setPaymentOption
// ====================================================

export interface setPaymentOption_setPaymentOption {
    __typename: "VoidType";
    code: number;
}

export interface setPaymentOption {
    /**
     * Sets the payment option to be used at purchase
     */
    setPaymentOption: setPaymentOption_setPaymentOption;
}

export interface setPaymentOptionVariables {
    selectedPaymentOption: SelectedPaymentOptionInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cart
// ====================================================

export interface cart_cart_amountTotal {
    __typename: "AmountWithCurrency";
    amount: any;
}

export interface cart_cart_insurances_contract_CarInsuranceContract {
    __typename: "CarInsuranceContract" | "TravelInsuranceContract" | "PetTravelInsuranceContract" | "TripCancellationInsuranceContract";
}

export interface cart_cart_insurances_contract_DeHomeInsuranceContract_riders {
    __typename: "Riders";
    elementary: boolean;
    glass: boolean;
    bicycle: boolean;
}

export interface cart_cart_insurances_contract_DeHomeInsuranceContract {
    __typename: "DeHomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    /**
     * number of months for one payment
     */
    paymentPeriodMonths: any | null;
    baseOfCoverage: ModHomeBase | null;
    riders: cart_cart_insurances_contract_DeHomeInsuranceContract_riders | null;
}

export interface cart_cart_insurances_contract_ModularTravelDEInsuranceContract_members {
    __typename: "ModularTravelDEInsuranceContractMember";
    ageCategory: AgeCategory | null;
    deleted: any | null;
}

export interface cart_cart_insurances_contract_ModularTravelDEInsuranceContract_modTravelDeData {
    __typename: "ModularTravelDERelatedData";
    destinationCategory: Destination;
    moduleCategory: CoverageBase;
}

export interface cart_cart_insurances_contract_ModularTravelDEInsuranceContract {
    __typename: "ModularTravelDEInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    /**
     * List of insured persons
     */
    members: cart_cart_insurances_contract_ModularTravelDEInsuranceContract_members[];
    modTravelDeData: cart_cart_insurances_contract_ModularTravelDEInsuranceContract_modTravelDeData | null;
}

export interface cart_cart_insurances_contract_LifeInsuranceContract_payoutLimit {
    __typename: "PayableAmount";
    /**
     * The amount
     */
    amount: any | null;
    /**
     * The currency of the amount
     */
    currency: Currency | null;
}

export interface cart_cart_insurances_contract_LifeInsuranceContract {
    __typename: "LifeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    /**
     * Insurance amount id
     */
    insuranceAmountId: any | null;
    payoutLimit: cart_cart_insurances_contract_LifeInsuranceContract_payoutLimit | null;
    country: string | null;
}

export interface cart_cart_insurances_contract_HomeInsuranceContract {
    __typename: "HomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    /**
     * number of months for one payment
     */
    paymentPeriodMonths: any | null;
    country: string | null;
    /**
     * Insurance residency
     */
    residencyCode: ResidencyCode | null;
    /**
     * Price currency
     */
    currency: Currency | null;
}

export type cart_cart_insurances_contract =
    | cart_cart_insurances_contract_CarInsuranceContract
    | cart_cart_insurances_contract_DeHomeInsuranceContract
    | cart_cart_insurances_contract_ModularTravelDEInsuranceContract
    | cart_cart_insurances_contract_LifeInsuranceContract
    | cart_cart_insurances_contract_HomeInsuranceContract;

export interface cart_cart_insurances {
    __typename: "Insurance";
    id: string;
    insuranceType: InsuranceType;
    totalPayableAmountForInsurance: any;
    billingPeriod: number;
    contract: cart_cart_insurances_contract;
}

export interface cart_cart_getAvailablePaymentOptions {
    __typename: "AvailablePaymentOption";
    currency: Currency;
    paymentMethod: PaymentMethodEnum;
    provider: PaymentProviderEnum | null;
    canBeSaved: boolean;
    canBeRecurring: boolean;
    isSaved: boolean;
    savedPaymentMethodId: number | null;
    savedPaymentMethodHint: string | null;
}

export interface cart_cart {
    __typename: "Cart";
    id: string;
    amountTotal: cart_cart_amountTotal;
    cartState: CartStatus | null;
    /**
     * The insurance policies in this cart
     */
    insurances: cart_cart_insurances[];
    /**
     * Calculate the available payment options for the current cart
     */
    getAvailablePaymentOptions: cart_cart_getAvailablePaymentOptions[];
}

export interface cart {
    cart: cart_cart;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AgeCategory {
    adult = "adult",
    child = "child",
    senior = "senior",
}

export enum CartStatus {
    Cancelled = "Cancelled",
    Deleted = "Deleted",
    Failed = "Failed",
    Open = "Open",
    Paid = "Paid",
    SepaRetry = "SepaRetry",
    WaitingPayment = "WaitingPayment",
}

export enum CoverageBase {
    cancellationInterruption = "cancellationInterruption",
    full = "full",
    health = "health",
    healthAndCancellation = "healthAndCancellation",
    healthAndLuggage = "healthAndLuggage",
    luggage = "luggage",
    luggageAndCancellation = "luggageAndCancellation",
}

/**
 * Currencies
 */
export enum Currency {
    CHF = "CHF",
    CZK = "CZK",
    EUR = "EUR",
    HUF = "HUF",
    JPY = "JPY",
    RON = "RON",
    USD = "USD",
}

export enum Destination {
    europe = "europe",
    worldwide = "worldwide",
    worldwideWithoutUSAndCA = "worldwideWithoutUSAndCA",
}

/**
 * Insurance type
 */
export enum InsuranceType {
    CAR = "CAR",
    CZ_CANCELLATION_WL = "CZ_CANCELLATION_WL",
    CZ_TRAVEL_WL = "CZ_TRAVEL_WL",
    DE_HOME_PACKAGES = "DE_HOME_PACKAGES",
    DE_TRAVEL_MOD_A = "DE_TRAVEL_MOD_A",
    DE_TRAVEL_MOD_S = "DE_TRAVEL_MOD_S",
    HOME = "HOME",
    LIFE = "LIFE",
    RO_CANCELLATION_WL = "RO_CANCELLATION_WL",
    RO_TRAVEL_WL = "RO_TRAVEL_WL",
    SK_CANCELLATION_WL = "SK_CANCELLATION_WL",
    SK_TRAVEL_WL = "SK_TRAVEL_WL",
    TRAVEL = "TRAVEL",
    TRIP_CANCELLATION = "TRIP_CANCELLATION",
}

export enum ModHomeBase {
    basic = "basic",
    comfort = "comfort",
    premium = "premium",
}

/**
 * Payment method
 */
export enum PaymentMethodEnum {
    BankCard = "BankCard",
    External = "External",
    PayPal = "PayPal",
    SEPA = "SEPA",
}

/**
 * Payment provider
 */
export enum PaymentProviderEnum {
    Borgun = "Borgun",
    Borgun2 = "Borgun2",
    GlobalPayments = "GlobalPayments",
    OTPSimple = "OTPSimple",
    PayPal = "PayPal",
    Saferpay = "Saferpay",
    Stripe = "Stripe",
    Unknown = "Unknown",
    VccSimple = "VccSimple",
    Wirecard = "Wirecard",
}

/**
 * Residency codes
 */
export enum ResidencyCode {
    CZ = "CZ",
    DE = "DE",
    HU = "HU",
    RO = "RO",
    SK = "SK",
}

/**
 * Transaction status by BigFish paymentGateway
 */
export enum TransactionStatus {
    CANCELED = "CANCELED",
    ERROR = "ERROR",
    FunctionNotImplemented = "FunctionNotImplemented",
    InactiveProvider = "InactiveProvider",
    InactiveStore = "InactiveStore",
    MissingParameter = "MissingParameter",
    MissingRemoteAddress = "MissingRemoteAddress",
    OPEN = "OPEN",
    PENDING = "PENDING",
    PaymentLinkCancelDenied = "PaymentLinkCancelDenied",
    PaypalrestError = "PaypalrestError",
    SUCCESSFUL = "SUCCESSFUL",
    TIMEOUT = "TIMEOUT",
    UNSUCCESFUL = "UNSUCCESFUL",
    UnauthorizedAccess = "UnauthorizedAccess",
    UnauthorizedRemoteAddress = "UnauthorizedRemoteAddress",
    UnknownParameter = "UnknownParameter",
    UnknownPaymentLink = "UnknownPaymentLink",
    UnknownProvider = "UnknownProvider",
    UnknownProviderForStore = "UnknownProviderForStore",
    UnknownReferenceTransaction = "UnknownReferenceTransaction",
    UnknownStore = "UnknownStore",
    UnknownTransaction = "UnknownTransaction",
    WrongApikey = "WrongApikey",
    WrongParameter = "WrongParameter",
    WrongProviderSettings = "WrongProviderSettings",
    WrongReferenceTransactionStatus = "WrongReferenceTransactionStatus",
}

export interface SelectedPaymentOptionInput {
    paymentMethod: PaymentMethodEnum;
    savedPaymentMethodId?: number | null;
    save?: boolean | null;
    beRecurring?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
