import { Api } from "@api/Api";
import de from "date-fns/locale/de";
import { ServerDateClass, DateUtils as DateUtilsClass, DateFormat } from "@monster/shared";

export const ServerDate = new ServerDateClass(Api.getServerTime);

export const DateUtils = new DateUtilsClass(ServerDate, de);

export const DateInputParser = {
    format: (date: Date): string => {
        return DateUtils.format(date, DateFormat.de);
    },
    parse: (dateString: string): Date => {
        return DateUtils.parse(dateString, DateFormat.api);
    },
    offsetDateToLocalDate: (dateString: string): string => {
        return dateString.split("T")[0];
    },
};
