/* eslint-disable prettier/prettier */

enum BackendEnvironmentName {
    validatorEmail = "validator.email",
}

const defaultBackendEnvironments: { [key in BackendEnvironmentName]: string } = {
    [BackendEnvironmentName.validatorEmail]: "^[a-zA-Z0-9\\.!#$%&\\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
};

export class BackendEnvironments {
    private static get(envName: BackendEnvironmentName): string {
        return defaultBackendEnvironments[envName];
    }

    public static validatorEmail(): RegExp {
        const validatorEmail = BackendEnvironments.get(BackendEnvironmentName.validatorEmail);
        return new RegExp(validatorEmail);
    }
}
