import { GqlDeWebapp } from "@api/Api";
import { ReduxHelpers, ActionsUnion } from "@monster/shared";

export enum SessionActionTypes {
    UPDATE = "SessionAction/UPDATE",
}

export const SessionActions = {
    update: (session: GqlDeWebapp.Session | null) => ReduxHelpers.createAction(SessionActionTypes.UPDATE, { session }),
};

export type SessionActions = ActionsUnion<typeof SessionActions>;
