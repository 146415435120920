import React from "react";
import { useTheme } from "styled-components";
import { Loctool } from "@monster/loctool";
import { AppBar as ChrAppBar } from "@monster/chr-ui";
import { SVGChrBrandCherriskByUniqa } from "@monster/chr-ui/dist/svg_assets";

export const SepaPaymentAppBar = () => {
    const theme = useTheme();

    return (
        <ChrAppBar
            leftItemChild={
                <SVGChrBrandCherriskByUniqa
                    color={theme.color.blueD}
                    title={`${Loctool.instance.formatMessage({ id: "common.brandName" })} ${Loctool.instance.formatMessage({ id: "common.brandNameBy" })}`}
                />
            }
        />
    );
};
