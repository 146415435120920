import { gql } from "@apollo/client";
import { LegalDocumentWLFragment } from "./LegalDocumentWLFragment";
import { ModularTravelDEInsuranceContractFragment } from "./ModularTravelDEInsuranceContractFragment";
import { DeHomeInsuranceContractFragment } from "./DeHomeInsuranceContractFragment";

export const SessionFragment = gql`
    fragment Session on Session {
        sessionId
        email
        verifiedEmail
        liveChatToken
        accountData {
            id
            name {
                first
                last
            }
            dateOfBirth
            channel
            email
            countryCode
            claims {
                id
                claimCause {
                    id
                    translationKey
                }
                claimStatus
                contract {
                    ... on CarInsuranceContract {
                        id
                    }
                    ... on HomeInsuranceContract {
                        id
                    }
                    ... on DeHomeInsuranceContract {
                        id
                    }
                    ... on TravelInsuranceContract {
                        id
                    }
                    ... on PetTravelInsuranceContract {
                        id
                    }
                    ... on LifeInsuranceContract {
                        id
                    }
                    ... on TripCancellationInsuranceContract {
                        id
                    }
                    ... on ModularTravelDEInsuranceContract {
                        id
                    }
                }
            }
        }
        germanTravelInsuranceContracts {
            ...ModularTravelDEInsuranceContract
        }
        germanHomeInsuranceContracts {
            ...DeHomeInsuranceContract
        }
        legalDocumentsWithRelations {
            relation {
                accepted
                legalDocumentWlId
                wlOfferId
                accountId
            }
            legalDocument {
                ...LegalDocumentWL
            }
        }
    }
    ${ModularTravelDEInsuranceContractFragment}
    ${DeHomeInsuranceContractFragment}
    ${LegalDocumentWLFragment}
`;
