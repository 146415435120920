import { gql } from "@apollo/client";
import { InsuranceDocFragment } from "./InsuranceDocFragment";
import { LegalDocumentFragment } from "./LegalDocumentFragment";
import { LegalDocumentWLFragment } from "./LegalDocumentWLFragment";

export const ModularTravelDEInsuranceContractFragment = gql`
    fragment ModularTravelDEInsuranceContract on ModularTravelDEInsuranceContract {
        id
        insuranceType
        status
        activeSince
        activeUntil
        policyNumber
        price
        documents {
            ...InsuranceDoc
        }
        legalDocuments {
            current {
                ...LegalDocument
            }
        }
        cartInsurances {
            cartExtId
        }
        modTravelDeData {
            destinationCategory
            firstDayOfTravel
            lastDayOfTravel
            durationCategory
        }
        wlLegalDocumentRelations {
            relation {
                accepted
                legalDocumentWlId
                wlOfferId
                accountId
            }
            legalDocument {
                ...LegalDocumentWL
            }
        }
        ops
        financed
    }
    ${LegalDocumentFragment}
    ${LegalDocumentWLFragment}
    ${InsuranceDocFragment}
`;
