/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: time
// ====================================================

export interface time {
    /**
     * Server time
     *
     * @auth None
     */
    time: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: applications
// ====================================================

export interface applications_applications {
    __typename: "ApplicationDescriptor";
    name: ChannelName;
    apiEndpoint: string;
}

export interface applications {
    /**
     * application descriptors for the account associated to the given email
     */
    applications: applications_applications[];
}

export interface applicationsVariables {
    email: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Channel on which an account or insurance created
 */
export enum ChannelName {
    BROKER_DE = "BROKER_DE",
    CHERRISK_WAPP = "CHERRISK_WAPP",
    CHERRISK_WAPP_DE_2022 = "CHERRISK_WAPP_DE_2022",
    CHERRISK_WAPP_DE_BROKER = "CHERRISK_WAPP_DE_BROKER",
    COVOMO = "COVOMO",
    MR_MONEY = "MR_MONEY",
    NETRISK = "NETRISK",
    SK_TREND = "SK_TREND",
    SOFTFAIR = "SOFTFAIR",
    TELESALES = "TELESALES",
    WHITE_LABEL_CZ = "WHITE_LABEL_CZ",
    WHITE_LABEL_HU = "WHITE_LABEL_HU",
    WHITE_LABEL_RO = "WHITE_LABEL_RO",
    WHITE_LABEL_SK = "WHITE_LABEL_SK",
    WPR = "WPR",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
