/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: startSession
// ====================================================

export interface startSession_startSession_accountData_name {
    __typename: "PersonName";
    first: string | null;
    last: string | null;
}

export interface startSession_startSession_accountData_claims_claimCause {
    __typename: "ClaimCause";
    id: any | null;
    translationKey: string | null;
}

export interface startSession_startSession_accountData_claims_contract_CarInsuranceContract {
    __typename: "CarInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface startSession_startSession_accountData_claims_contract_HomeInsuranceContract {
    __typename: "HomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface startSession_startSession_accountData_claims_contract_DeHomeInsuranceContract {
    __typename: "DeHomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface startSession_startSession_accountData_claims_contract_TravelInsuranceContract {
    __typename: "TravelInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface startSession_startSession_accountData_claims_contract_PetTravelInsuranceContract {
    __typename: "PetTravelInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface startSession_startSession_accountData_claims_contract_LifeInsuranceContract {
    __typename: "LifeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface startSession_startSession_accountData_claims_contract_TripCancellationInsuranceContract {
    __typename: "TripCancellationInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface startSession_startSession_accountData_claims_contract_ModularTravelDEInsuranceContract {
    __typename: "ModularTravelDEInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export type startSession_startSession_accountData_claims_contract =
    | startSession_startSession_accountData_claims_contract_CarInsuranceContract
    | startSession_startSession_accountData_claims_contract_HomeInsuranceContract
    | startSession_startSession_accountData_claims_contract_DeHomeInsuranceContract
    | startSession_startSession_accountData_claims_contract_TravelInsuranceContract
    | startSession_startSession_accountData_claims_contract_PetTravelInsuranceContract
    | startSession_startSession_accountData_claims_contract_LifeInsuranceContract
    | startSession_startSession_accountData_claims_contract_TripCancellationInsuranceContract
    | startSession_startSession_accountData_claims_contract_ModularTravelDEInsuranceContract;

export interface startSession_startSession_accountData_claims {
    __typename: "Claim";
    id: string | null;
    claimCause: startSession_startSession_accountData_claims_claimCause | null;
    claimStatus: ClaimStatus | null;
    contract: startSession_startSession_accountData_claims_contract | null;
}

export interface startSession_startSession_accountData {
    __typename: "Account";
    id: string | null;
    name: startSession_startSession_accountData_name | null;
    dateOfBirth: any | null;
    channel: ChannelName | null;
    email: string | null;
    countryCode: CountryCode | null;
    claims: startSession_startSession_accountData_claims[] | null;
}

export interface startSession_startSession_germanTravelInsuranceContracts_documents_insuranceVersion {
    __typename: "InsuranceDocVersionInfo";
    version: number;
    financedFrom: any;
    financedUntil: any;
}

export interface startSession_startSession_germanTravelInsuranceContracts_documents_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface startSession_startSession_germanTravelInsuranceContracts_documents {
    __typename: "InsuranceDoc";
    serialNumber: any | null;
    insuranceDocType: string | null;
    name: string | null;
    started: any | null;
    created: any | null;
    deleted: any | null;
    /**
     * Information about the policy version, only for Contract documents
     */
    insuranceVersion: startSession_startSession_germanTravelInsuranceContracts_documents_insuranceVersion | null;
    fileURL: string | null;
    fileData: startSession_startSession_germanTravelInsuranceContracts_documents_fileData | null;
}

export interface startSession_startSession_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface startSession_startSession_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList {
    __typename: "FileDataWithLanguage";
    languageCode: LanguageCode;
    fileData: startSession_startSession_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList_fileData;
    legalDocumentFilenamePrefix: string | null;
}

export interface startSession_startSession_germanTravelInsuranceContracts_legalDocuments_current {
    __typename: "LegalDocument";
    categoryId: number | null;
    category: LegalDocumentCategory | null;
    interaction: LegalDocumentInteraction | null;
    version: any | null;
    effectiveFrom: any | null;
    effectiveUntil: any | null;
    created: any | null;
    subcategory: string | null;
    documentFileDataList: startSession_startSession_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList[] | null;
}

export interface startSession_startSession_germanTravelInsuranceContracts_legalDocuments {
    __typename: "InsuranceLegalDocLists";
    current: startSession_startSession_germanTravelInsuranceContracts_legalDocuments_current[];
}

export interface startSession_startSession_germanTravelInsuranceContracts_cartInsurances {
    __typename: "CartInsurance";
    cartExtId: string;
}

export interface startSession_startSession_germanTravelInsuranceContracts_modTravelDeData {
    __typename: "ModularTravelDERelatedData";
    destinationCategory: Destination;
    firstDayOfTravel: any;
    lastDayOfTravel: any | null;
    durationCategory: Duration;
}

export interface startSession_startSession_germanTravelInsuranceContracts_wlLegalDocumentRelations_relation {
    __typename: "AccountLegalDocumentRelationWl";
    accepted: any | null;
    legalDocumentWlId: any;
    wlOfferId: string | null;
    accountId: any | null;
}

export interface startSession_startSession_germanTravelInsuranceContracts_wlLegalDocumentRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface startSession_startSession_germanTravelInsuranceContracts_wlLegalDocumentRelations {
    __typename: "LegalDocumentWithRelation";
    relation: startSession_startSession_germanTravelInsuranceContracts_wlLegalDocumentRelations_relation;
    legalDocument: startSession_startSession_germanTravelInsuranceContracts_wlLegalDocumentRelations_legalDocument;
}

export interface startSession_startSession_germanTravelInsuranceContracts {
    __typename: "ModularTravelDEInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    insuranceType: InsuranceType | null;
    /**
     * Contract status
     */
    status: InsuranceStatus | null;
    /**
     * First insured day (inclusive)
     */
    activeSince: any | null;
    activeUntil: any | null;
    /**
     * Policy number (contract ID and serial number)
     */
    policyNumber: string | null;
    /**
     * price
     */
    price: any | null;
    documents: startSession_startSession_germanTravelInsuranceContracts_documents[];
    legalDocuments: startSession_startSession_germanTravelInsuranceContracts_legalDocuments;
    cartInsurances: startSession_startSession_germanTravelInsuranceContracts_cartInsurances[];
    modTravelDeData: startSession_startSession_germanTravelInsuranceContracts_modTravelDeData | null;
    wlLegalDocumentRelations: startSession_startSession_germanTravelInsuranceContracts_wlLegalDocumentRelations[] | null;
    ops: InsuranceOp[] | null;
    financed: any | null;
}

export interface startSession_startSession_germanHomeInsuranceContracts_documents_insuranceVersion {
    __typename: "InsuranceDocVersionInfo";
    version: number;
    financedFrom: any;
    financedUntil: any;
}

export interface startSession_startSession_germanHomeInsuranceContracts_documents_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface startSession_startSession_germanHomeInsuranceContracts_documents {
    __typename: "InsuranceDoc";
    serialNumber: any | null;
    insuranceDocType: string | null;
    name: string | null;
    started: any | null;
    created: any | null;
    deleted: any | null;
    /**
     * Information about the policy version, only for Contract documents
     */
    insuranceVersion: startSession_startSession_germanHomeInsuranceContracts_documents_insuranceVersion | null;
    fileURL: string | null;
    fileData: startSession_startSession_germanHomeInsuranceContracts_documents_fileData | null;
}

export interface startSession_startSession_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface startSession_startSession_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList {
    __typename: "FileDataWithLanguage";
    languageCode: LanguageCode;
    fileData: startSession_startSession_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList_fileData;
    legalDocumentFilenamePrefix: string | null;
}

export interface startSession_startSession_germanHomeInsuranceContracts_legalDocuments_current {
    __typename: "LegalDocument";
    categoryId: number | null;
    category: LegalDocumentCategory | null;
    interaction: LegalDocumentInteraction | null;
    version: any | null;
    effectiveFrom: any | null;
    effectiveUntil: any | null;
    created: any | null;
    subcategory: string | null;
    documentFileDataList: startSession_startSession_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList[] | null;
}

export interface startSession_startSession_germanHomeInsuranceContracts_legalDocuments {
    __typename: "InsuranceLegalDocLists";
    current: startSession_startSession_germanHomeInsuranceContracts_legalDocuments_current[];
}

export interface startSession_startSession_germanHomeInsuranceContracts_cartInsurances {
    __typename: "CartInsurance";
    cartExtId: string;
}

export interface startSession_startSession_germanHomeInsuranceContracts_wlLegalDocumentRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface startSession_startSession_germanHomeInsuranceContracts_wlLegalDocumentRelations {
    __typename: "LegalDocumentWithRelation";
    legalDocument: startSession_startSession_germanHomeInsuranceContracts_wlLegalDocumentRelations_legalDocument;
}

export interface startSession_startSession_germanHomeInsuranceContracts {
    __typename: "DeHomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    /**
     * Serial number
     */
    serialNumber: any | null;
    /**
     * Contract status
     */
    status: InsuranceStatus | null;
    /**
     * First date of insurance contract
     */
    activeSince: any | null;
    buildingType: ModHomeBuildingType | null;
    subjectZipCode: string | null;
    /**
     * Monthly price (last paid price)
     */
    feeMonthly: any | null;
    /**
     * number of months for one payment
     */
    paymentPeriodMonths: any | null;
    documents: startSession_startSession_germanHomeInsuranceContracts_documents[];
    legalDocuments: startSession_startSession_germanHomeInsuranceContracts_legalDocuments;
    cartInsurances: startSession_startSession_germanHomeInsuranceContracts_cartInsurances[];
    wlLegalDocumentRelations: startSession_startSession_germanHomeInsuranceContracts_wlLegalDocumentRelations[] | null;
    ops: InsuranceOp[] | null;
}

export interface startSession_startSession_legalDocumentsWithRelations_relation {
    __typename: "AccountLegalDocumentRelationWl";
    accepted: any | null;
    legalDocumentWlId: any;
    wlOfferId: string | null;
    accountId: any | null;
}

export interface startSession_startSession_legalDocumentsWithRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface startSession_startSession_legalDocumentsWithRelations {
    __typename: "LegalDocumentWithRelation";
    relation: startSession_startSession_legalDocumentsWithRelations_relation;
    legalDocument: startSession_startSession_legalDocumentsWithRelations_legalDocument;
}

export interface startSession_startSession {
    __typename: "Session";
    /**
     * Session ID. Send this in the Authorization header to your GraphQL requests
     */
    sessionId: string;
    /**
     * The email address this session belongs to. May not have been verified.
     */
    email: string | null;
    /**
     * The email address this session belongs to, but only if it has been verified.
     */
    verifiedEmail: string | null;
    liveChatToken: string | null;
    /**
     * The account this session belongs to. Set if a verified email matches an existing account.
     */
    accountData: startSession_startSession_accountData | null;
    /**
     * The travel insurance contracts that have been created for the account the session belongs to.
     */
    germanTravelInsuranceContracts: startSession_startSession_germanTravelInsuranceContracts[];
    /**
     * The home insurance contracts that have been created for the account the session belongs to.
     */
    germanHomeInsuranceContracts: startSession_startSession_germanHomeInsuranceContracts[];
    /**
     * The legal document status for the account the session belongs to
     */
    legalDocumentsWithRelations: startSession_startSession_legalDocumentsWithRelations[];
}

export interface startSession {
    /**
     * start a new session
     */
    startSession: startSession_startSession;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendValidationEmail
// ====================================================

export interface sendValidationEmail_sendValidationEmail {
    __typename: "VoidType";
    code: number;
    message: string | null;
}

export interface sendValidationEmail {
    /**
     * attach the given email to the session (replacing any that is already there) and send a code by email to validate ownership
     */
    sendValidationEmail: sendValidationEmail_sendValidationEmail;
}

export interface sendValidationEmailVariables {
    email: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: validateEmail
// ====================================================

export interface validateEmail_validateEmail_accountData_name {
    __typename: "PersonName";
    first: string | null;
    last: string | null;
}

export interface validateEmail_validateEmail_accountData_claims_claimCause {
    __typename: "ClaimCause";
    id: any | null;
    translationKey: string | null;
}

export interface validateEmail_validateEmail_accountData_claims_contract_CarInsuranceContract {
    __typename: "CarInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface validateEmail_validateEmail_accountData_claims_contract_HomeInsuranceContract {
    __typename: "HomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface validateEmail_validateEmail_accountData_claims_contract_DeHomeInsuranceContract {
    __typename: "DeHomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface validateEmail_validateEmail_accountData_claims_contract_TravelInsuranceContract {
    __typename: "TravelInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface validateEmail_validateEmail_accountData_claims_contract_PetTravelInsuranceContract {
    __typename: "PetTravelInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface validateEmail_validateEmail_accountData_claims_contract_LifeInsuranceContract {
    __typename: "LifeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface validateEmail_validateEmail_accountData_claims_contract_TripCancellationInsuranceContract {
    __typename: "TripCancellationInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface validateEmail_validateEmail_accountData_claims_contract_ModularTravelDEInsuranceContract {
    __typename: "ModularTravelDEInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export type validateEmail_validateEmail_accountData_claims_contract =
    | validateEmail_validateEmail_accountData_claims_contract_CarInsuranceContract
    | validateEmail_validateEmail_accountData_claims_contract_HomeInsuranceContract
    | validateEmail_validateEmail_accountData_claims_contract_DeHomeInsuranceContract
    | validateEmail_validateEmail_accountData_claims_contract_TravelInsuranceContract
    | validateEmail_validateEmail_accountData_claims_contract_PetTravelInsuranceContract
    | validateEmail_validateEmail_accountData_claims_contract_LifeInsuranceContract
    | validateEmail_validateEmail_accountData_claims_contract_TripCancellationInsuranceContract
    | validateEmail_validateEmail_accountData_claims_contract_ModularTravelDEInsuranceContract;

export interface validateEmail_validateEmail_accountData_claims {
    __typename: "Claim";
    id: string | null;
    claimCause: validateEmail_validateEmail_accountData_claims_claimCause | null;
    claimStatus: ClaimStatus | null;
    contract: validateEmail_validateEmail_accountData_claims_contract | null;
}

export interface validateEmail_validateEmail_accountData {
    __typename: "Account";
    id: string | null;
    name: validateEmail_validateEmail_accountData_name | null;
    dateOfBirth: any | null;
    channel: ChannelName | null;
    email: string | null;
    countryCode: CountryCode | null;
    claims: validateEmail_validateEmail_accountData_claims[] | null;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_documents_insuranceVersion {
    __typename: "InsuranceDocVersionInfo";
    version: number;
    financedFrom: any;
    financedUntil: any;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_documents_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_documents {
    __typename: "InsuranceDoc";
    serialNumber: any | null;
    insuranceDocType: string | null;
    name: string | null;
    started: any | null;
    created: any | null;
    deleted: any | null;
    /**
     * Information about the policy version, only for Contract documents
     */
    insuranceVersion: validateEmail_validateEmail_germanTravelInsuranceContracts_documents_insuranceVersion | null;
    fileURL: string | null;
    fileData: validateEmail_validateEmail_germanTravelInsuranceContracts_documents_fileData | null;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList {
    __typename: "FileDataWithLanguage";
    languageCode: LanguageCode;
    fileData: validateEmail_validateEmail_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList_fileData;
    legalDocumentFilenamePrefix: string | null;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_legalDocuments_current {
    __typename: "LegalDocument";
    categoryId: number | null;
    category: LegalDocumentCategory | null;
    interaction: LegalDocumentInteraction | null;
    version: any | null;
    effectiveFrom: any | null;
    effectiveUntil: any | null;
    created: any | null;
    subcategory: string | null;
    documentFileDataList: validateEmail_validateEmail_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList[] | null;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_legalDocuments {
    __typename: "InsuranceLegalDocLists";
    current: validateEmail_validateEmail_germanTravelInsuranceContracts_legalDocuments_current[];
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_cartInsurances {
    __typename: "CartInsurance";
    cartExtId: string;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_modTravelDeData {
    __typename: "ModularTravelDERelatedData";
    destinationCategory: Destination;
    firstDayOfTravel: any;
    lastDayOfTravel: any | null;
    durationCategory: Duration;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_wlLegalDocumentRelations_relation {
    __typename: "AccountLegalDocumentRelationWl";
    accepted: any | null;
    legalDocumentWlId: any;
    wlOfferId: string | null;
    accountId: any | null;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_wlLegalDocumentRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts_wlLegalDocumentRelations {
    __typename: "LegalDocumentWithRelation";
    relation: validateEmail_validateEmail_germanTravelInsuranceContracts_wlLegalDocumentRelations_relation;
    legalDocument: validateEmail_validateEmail_germanTravelInsuranceContracts_wlLegalDocumentRelations_legalDocument;
}

export interface validateEmail_validateEmail_germanTravelInsuranceContracts {
    __typename: "ModularTravelDEInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    insuranceType: InsuranceType | null;
    /**
     * Contract status
     */
    status: InsuranceStatus | null;
    /**
     * First insured day (inclusive)
     */
    activeSince: any | null;
    activeUntil: any | null;
    /**
     * Policy number (contract ID and serial number)
     */
    policyNumber: string | null;
    /**
     * price
     */
    price: any | null;
    documents: validateEmail_validateEmail_germanTravelInsuranceContracts_documents[];
    legalDocuments: validateEmail_validateEmail_germanTravelInsuranceContracts_legalDocuments;
    cartInsurances: validateEmail_validateEmail_germanTravelInsuranceContracts_cartInsurances[];
    modTravelDeData: validateEmail_validateEmail_germanTravelInsuranceContracts_modTravelDeData | null;
    wlLegalDocumentRelations: validateEmail_validateEmail_germanTravelInsuranceContracts_wlLegalDocumentRelations[] | null;
    ops: InsuranceOp[] | null;
    financed: any | null;
}

export interface validateEmail_validateEmail_germanHomeInsuranceContracts_documents_insuranceVersion {
    __typename: "InsuranceDocVersionInfo";
    version: number;
    financedFrom: any;
    financedUntil: any;
}

export interface validateEmail_validateEmail_germanHomeInsuranceContracts_documents_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface validateEmail_validateEmail_germanHomeInsuranceContracts_documents {
    __typename: "InsuranceDoc";
    serialNumber: any | null;
    insuranceDocType: string | null;
    name: string | null;
    started: any | null;
    created: any | null;
    deleted: any | null;
    /**
     * Information about the policy version, only for Contract documents
     */
    insuranceVersion: validateEmail_validateEmail_germanHomeInsuranceContracts_documents_insuranceVersion | null;
    fileURL: string | null;
    fileData: validateEmail_validateEmail_germanHomeInsuranceContracts_documents_fileData | null;
}

export interface validateEmail_validateEmail_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface validateEmail_validateEmail_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList {
    __typename: "FileDataWithLanguage";
    languageCode: LanguageCode;
    fileData: validateEmail_validateEmail_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList_fileData;
    legalDocumentFilenamePrefix: string | null;
}

export interface validateEmail_validateEmail_germanHomeInsuranceContracts_legalDocuments_current {
    __typename: "LegalDocument";
    categoryId: number | null;
    category: LegalDocumentCategory | null;
    interaction: LegalDocumentInteraction | null;
    version: any | null;
    effectiveFrom: any | null;
    effectiveUntil: any | null;
    created: any | null;
    subcategory: string | null;
    documentFileDataList: validateEmail_validateEmail_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList[] | null;
}

export interface validateEmail_validateEmail_germanHomeInsuranceContracts_legalDocuments {
    __typename: "InsuranceLegalDocLists";
    current: validateEmail_validateEmail_germanHomeInsuranceContracts_legalDocuments_current[];
}

export interface validateEmail_validateEmail_germanHomeInsuranceContracts_cartInsurances {
    __typename: "CartInsurance";
    cartExtId: string;
}

export interface validateEmail_validateEmail_germanHomeInsuranceContracts_wlLegalDocumentRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface validateEmail_validateEmail_germanHomeInsuranceContracts_wlLegalDocumentRelations {
    __typename: "LegalDocumentWithRelation";
    legalDocument: validateEmail_validateEmail_germanHomeInsuranceContracts_wlLegalDocumentRelations_legalDocument;
}

export interface validateEmail_validateEmail_germanHomeInsuranceContracts {
    __typename: "DeHomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    /**
     * Serial number
     */
    serialNumber: any | null;
    /**
     * Contract status
     */
    status: InsuranceStatus | null;
    /**
     * First date of insurance contract
     */
    activeSince: any | null;
    buildingType: ModHomeBuildingType | null;
    subjectZipCode: string | null;
    /**
     * Monthly price (last paid price)
     */
    feeMonthly: any | null;
    /**
     * number of months for one payment
     */
    paymentPeriodMonths: any | null;
    documents: validateEmail_validateEmail_germanHomeInsuranceContracts_documents[];
    legalDocuments: validateEmail_validateEmail_germanHomeInsuranceContracts_legalDocuments;
    cartInsurances: validateEmail_validateEmail_germanHomeInsuranceContracts_cartInsurances[];
    wlLegalDocumentRelations: validateEmail_validateEmail_germanHomeInsuranceContracts_wlLegalDocumentRelations[] | null;
    ops: InsuranceOp[] | null;
}

export interface validateEmail_validateEmail_legalDocumentsWithRelations_relation {
    __typename: "AccountLegalDocumentRelationWl";
    accepted: any | null;
    legalDocumentWlId: any;
    wlOfferId: string | null;
    accountId: any | null;
}

export interface validateEmail_validateEmail_legalDocumentsWithRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface validateEmail_validateEmail_legalDocumentsWithRelations {
    __typename: "LegalDocumentWithRelation";
    relation: validateEmail_validateEmail_legalDocumentsWithRelations_relation;
    legalDocument: validateEmail_validateEmail_legalDocumentsWithRelations_legalDocument;
}

export interface validateEmail_validateEmail {
    __typename: "Session";
    /**
     * Session ID. Send this in the Authorization header to your GraphQL requests
     */
    sessionId: string;
    /**
     * The email address this session belongs to. May not have been verified.
     */
    email: string | null;
    /**
     * The email address this session belongs to, but only if it has been verified.
     */
    verifiedEmail: string | null;
    liveChatToken: string | null;
    /**
     * The account this session belongs to. Set if a verified email matches an existing account.
     */
    accountData: validateEmail_validateEmail_accountData | null;
    /**
     * The travel insurance contracts that have been created for the account the session belongs to.
     */
    germanTravelInsuranceContracts: validateEmail_validateEmail_germanTravelInsuranceContracts[];
    /**
     * The home insurance contracts that have been created for the account the session belongs to.
     */
    germanHomeInsuranceContracts: validateEmail_validateEmail_germanHomeInsuranceContracts[];
    /**
     * The legal document status for the account the session belongs to
     */
    legalDocumentsWithRelations: validateEmail_validateEmail_legalDocumentsWithRelations[];
}

export interface validateEmail {
    /**
     * submit the verification code received by email to confirm ownership of that email
     */
    validateEmail: validateEmail_validateEmail;
}

export interface validateEmailVariables {
    email: string;
    code: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rePay
// ====================================================

export interface rePay_rePay_oneClickPaymentResult {
    __typename: "TransactionResult";
    transactionId: string | null;
}

export interface rePay_rePay {
    __typename: "PaymentResult";
    isOneClickPayment: boolean;
    oneClickPaymentResult: rePay_rePay_oneClickPaymentResult | null;
    standardPaymentResult: string | null;
    insuranceId: string | null;
}

export interface rePay {
    /**
     * Make a new payment after an unsuccessful payment.
     */
    rePay: rePay_rePay;
}

export interface rePayVariables {
    insuranceId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSessionByExternalInsuranceId
// ====================================================

export interface createSessionByExternalInsuranceId {
    /**
     * create a session by external Insurance Id and provide link to claim dashboard
     */
    createSessionByExternalInsuranceId: string;
}

export interface createSessionByExternalInsuranceIdVariables {
    id: string;
    claimId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendContractDocumentEmail
// ====================================================

export interface sendContractDocumentEmail_sendContractDocumentEmail {
    __typename: "VoidType";
    code: number;
    message: string | null;
}

export interface sendContractDocumentEmail {
    /**
     * Send a contract related document in email (to the session owner)
     */
    sendContractDocumentEmail: sendContractDocumentEmail_sendContractDocumentEmail;
}

export interface sendContractDocumentEmailVariables {
    insuranceId: string;
    fileName: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSession
// ====================================================

export interface getSession_session_accountData_name {
    __typename: "PersonName";
    first: string | null;
    last: string | null;
}

export interface getSession_session_accountData_claims_claimCause {
    __typename: "ClaimCause";
    id: any | null;
    translationKey: string | null;
}

export interface getSession_session_accountData_claims_contract_CarInsuranceContract {
    __typename: "CarInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface getSession_session_accountData_claims_contract_HomeInsuranceContract {
    __typename: "HomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface getSession_session_accountData_claims_contract_DeHomeInsuranceContract {
    __typename: "DeHomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface getSession_session_accountData_claims_contract_TravelInsuranceContract {
    __typename: "TravelInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface getSession_session_accountData_claims_contract_PetTravelInsuranceContract {
    __typename: "PetTravelInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface getSession_session_accountData_claims_contract_LifeInsuranceContract {
    __typename: "LifeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface getSession_session_accountData_claims_contract_TripCancellationInsuranceContract {
    __typename: "TripCancellationInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface getSession_session_accountData_claims_contract_ModularTravelDEInsuranceContract {
    __typename: "ModularTravelDEInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export type getSession_session_accountData_claims_contract =
    | getSession_session_accountData_claims_contract_CarInsuranceContract
    | getSession_session_accountData_claims_contract_HomeInsuranceContract
    | getSession_session_accountData_claims_contract_DeHomeInsuranceContract
    | getSession_session_accountData_claims_contract_TravelInsuranceContract
    | getSession_session_accountData_claims_contract_PetTravelInsuranceContract
    | getSession_session_accountData_claims_contract_LifeInsuranceContract
    | getSession_session_accountData_claims_contract_TripCancellationInsuranceContract
    | getSession_session_accountData_claims_contract_ModularTravelDEInsuranceContract;

export interface getSession_session_accountData_claims {
    __typename: "Claim";
    id: string | null;
    claimCause: getSession_session_accountData_claims_claimCause | null;
    claimStatus: ClaimStatus | null;
    contract: getSession_session_accountData_claims_contract | null;
}

export interface getSession_session_accountData {
    __typename: "Account";
    id: string | null;
    name: getSession_session_accountData_name | null;
    dateOfBirth: any | null;
    channel: ChannelName | null;
    email: string | null;
    countryCode: CountryCode | null;
    claims: getSession_session_accountData_claims[] | null;
}

export interface getSession_session_germanTravelInsuranceContracts_documents_insuranceVersion {
    __typename: "InsuranceDocVersionInfo";
    version: number;
    financedFrom: any;
    financedUntil: any;
}

export interface getSession_session_germanTravelInsuranceContracts_documents_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface getSession_session_germanTravelInsuranceContracts_documents {
    __typename: "InsuranceDoc";
    serialNumber: any | null;
    insuranceDocType: string | null;
    name: string | null;
    started: any | null;
    created: any | null;
    deleted: any | null;
    /**
     * Information about the policy version, only for Contract documents
     */
    insuranceVersion: getSession_session_germanTravelInsuranceContracts_documents_insuranceVersion | null;
    fileURL: string | null;
    fileData: getSession_session_germanTravelInsuranceContracts_documents_fileData | null;
}

export interface getSession_session_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface getSession_session_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList {
    __typename: "FileDataWithLanguage";
    languageCode: LanguageCode;
    fileData: getSession_session_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList_fileData;
    legalDocumentFilenamePrefix: string | null;
}

export interface getSession_session_germanTravelInsuranceContracts_legalDocuments_current {
    __typename: "LegalDocument";
    categoryId: number | null;
    category: LegalDocumentCategory | null;
    interaction: LegalDocumentInteraction | null;
    version: any | null;
    effectiveFrom: any | null;
    effectiveUntil: any | null;
    created: any | null;
    subcategory: string | null;
    documentFileDataList: getSession_session_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList[] | null;
}

export interface getSession_session_germanTravelInsuranceContracts_legalDocuments {
    __typename: "InsuranceLegalDocLists";
    current: getSession_session_germanTravelInsuranceContracts_legalDocuments_current[];
}

export interface getSession_session_germanTravelInsuranceContracts_cartInsurances {
    __typename: "CartInsurance";
    cartExtId: string;
}

export interface getSession_session_germanTravelInsuranceContracts_modTravelDeData {
    __typename: "ModularTravelDERelatedData";
    destinationCategory: Destination;
    firstDayOfTravel: any;
    lastDayOfTravel: any | null;
    durationCategory: Duration;
}

export interface getSession_session_germanTravelInsuranceContracts_wlLegalDocumentRelations_relation {
    __typename: "AccountLegalDocumentRelationWl";
    accepted: any | null;
    legalDocumentWlId: any;
    wlOfferId: string | null;
    accountId: any | null;
}

export interface getSession_session_germanTravelInsuranceContracts_wlLegalDocumentRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface getSession_session_germanTravelInsuranceContracts_wlLegalDocumentRelations {
    __typename: "LegalDocumentWithRelation";
    relation: getSession_session_germanTravelInsuranceContracts_wlLegalDocumentRelations_relation;
    legalDocument: getSession_session_germanTravelInsuranceContracts_wlLegalDocumentRelations_legalDocument;
}

export interface getSession_session_germanTravelInsuranceContracts {
    __typename: "ModularTravelDEInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    insuranceType: InsuranceType | null;
    /**
     * Contract status
     */
    status: InsuranceStatus | null;
    /**
     * First insured day (inclusive)
     */
    activeSince: any | null;
    activeUntil: any | null;
    /**
     * Policy number (contract ID and serial number)
     */
    policyNumber: string | null;
    /**
     * price
     */
    price: any | null;
    documents: getSession_session_germanTravelInsuranceContracts_documents[];
    legalDocuments: getSession_session_germanTravelInsuranceContracts_legalDocuments;
    cartInsurances: getSession_session_germanTravelInsuranceContracts_cartInsurances[];
    modTravelDeData: getSession_session_germanTravelInsuranceContracts_modTravelDeData | null;
    wlLegalDocumentRelations: getSession_session_germanTravelInsuranceContracts_wlLegalDocumentRelations[] | null;
    ops: InsuranceOp[] | null;
    financed: any | null;
}

export interface getSession_session_germanHomeInsuranceContracts_documents_insuranceVersion {
    __typename: "InsuranceDocVersionInfo";
    version: number;
    financedFrom: any;
    financedUntil: any;
}

export interface getSession_session_germanHomeInsuranceContracts_documents_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface getSession_session_germanHomeInsuranceContracts_documents {
    __typename: "InsuranceDoc";
    serialNumber: any | null;
    insuranceDocType: string | null;
    name: string | null;
    started: any | null;
    created: any | null;
    deleted: any | null;
    /**
     * Information about the policy version, only for Contract documents
     */
    insuranceVersion: getSession_session_germanHomeInsuranceContracts_documents_insuranceVersion | null;
    fileURL: string | null;
    fileData: getSession_session_germanHomeInsuranceContracts_documents_fileData | null;
}

export interface getSession_session_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface getSession_session_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList {
    __typename: "FileDataWithLanguage";
    languageCode: LanguageCode;
    fileData: getSession_session_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList_fileData;
    legalDocumentFilenamePrefix: string | null;
}

export interface getSession_session_germanHomeInsuranceContracts_legalDocuments_current {
    __typename: "LegalDocument";
    categoryId: number | null;
    category: LegalDocumentCategory | null;
    interaction: LegalDocumentInteraction | null;
    version: any | null;
    effectiveFrom: any | null;
    effectiveUntil: any | null;
    created: any | null;
    subcategory: string | null;
    documentFileDataList: getSession_session_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList[] | null;
}

export interface getSession_session_germanHomeInsuranceContracts_legalDocuments {
    __typename: "InsuranceLegalDocLists";
    current: getSession_session_germanHomeInsuranceContracts_legalDocuments_current[];
}

export interface getSession_session_germanHomeInsuranceContracts_cartInsurances {
    __typename: "CartInsurance";
    cartExtId: string;
}

export interface getSession_session_germanHomeInsuranceContracts_wlLegalDocumentRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface getSession_session_germanHomeInsuranceContracts_wlLegalDocumentRelations {
    __typename: "LegalDocumentWithRelation";
    legalDocument: getSession_session_germanHomeInsuranceContracts_wlLegalDocumentRelations_legalDocument;
}

export interface getSession_session_germanHomeInsuranceContracts {
    __typename: "DeHomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    /**
     * Serial number
     */
    serialNumber: any | null;
    /**
     * Contract status
     */
    status: InsuranceStatus | null;
    /**
     * First date of insurance contract
     */
    activeSince: any | null;
    buildingType: ModHomeBuildingType | null;
    subjectZipCode: string | null;
    /**
     * Monthly price (last paid price)
     */
    feeMonthly: any | null;
    /**
     * number of months for one payment
     */
    paymentPeriodMonths: any | null;
    documents: getSession_session_germanHomeInsuranceContracts_documents[];
    legalDocuments: getSession_session_germanHomeInsuranceContracts_legalDocuments;
    cartInsurances: getSession_session_germanHomeInsuranceContracts_cartInsurances[];
    wlLegalDocumentRelations: getSession_session_germanHomeInsuranceContracts_wlLegalDocumentRelations[] | null;
    ops: InsuranceOp[] | null;
}

export interface getSession_session_legalDocumentsWithRelations_relation {
    __typename: "AccountLegalDocumentRelationWl";
    accepted: any | null;
    legalDocumentWlId: any;
    wlOfferId: string | null;
    accountId: any | null;
}

export interface getSession_session_legalDocumentsWithRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface getSession_session_legalDocumentsWithRelations {
    __typename: "LegalDocumentWithRelation";
    relation: getSession_session_legalDocumentsWithRelations_relation;
    legalDocument: getSession_session_legalDocumentsWithRelations_legalDocument;
}

export interface getSession_session {
    __typename: "Session";
    /**
     * Session ID. Send this in the Authorization header to your GraphQL requests
     */
    sessionId: string;
    /**
     * The email address this session belongs to. May not have been verified.
     */
    email: string | null;
    /**
     * The email address this session belongs to, but only if it has been verified.
     */
    verifiedEmail: string | null;
    liveChatToken: string | null;
    /**
     * The account this session belongs to. Set if a verified email matches an existing account.
     */
    accountData: getSession_session_accountData | null;
    /**
     * The travel insurance contracts that have been created for the account the session belongs to.
     */
    germanTravelInsuranceContracts: getSession_session_germanTravelInsuranceContracts[];
    /**
     * The home insurance contracts that have been created for the account the session belongs to.
     */
    germanHomeInsuranceContracts: getSession_session_germanHomeInsuranceContracts[];
    /**
     * The legal document status for the account the session belongs to
     */
    legalDocumentsWithRelations: getSession_session_legalDocumentsWithRelations[];
}

export interface getSession {
    /**
     * information about the current session. Will only be returned if Auth header is present with an active session id
     */
    session: getSession_session | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEffectiveLegalDocuments
// ====================================================

export interface getEffectiveLegalDocuments_accountLegalDocuments {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface getEffectiveLegalDocuments_travelSingleTrip {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface getEffectiveLegalDocuments_travelAnnual {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface getEffectiveLegalDocuments_homeInsurance {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface getEffectiveLegalDocuments {
    accountLegalDocuments: getEffectiveLegalDocuments_accountLegalDocuments[];
    travelSingleTrip: getEffectiveLegalDocuments_travelSingleTrip[];
    travelAnnual: getEffectiveLegalDocuments_travelAnnual[];
    homeInsurance: getEffectiveLegalDocuments_homeInsurance[];
}

export interface getEffectiveLegalDocumentsVariables {
    at: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTransactionResult
// ====================================================

export interface getTransactionResult_getTransactionResult {
    __typename: "TransactionResult";
    transactionId: string | null;
    resultCode: TransactionStatus;
    resultMessage: string | null;
    orderId: string | null;
    providerTransactionId: string | null;
    providerName: string | null;
    userId: string | null;
    insuranceId: string | null;
}

export interface getTransactionResult_session_accountData_cartHistory_selectedPaymentOption {
    __typename: "SelectedPaymentOption";
    paymentMethod: PaymentMethodEnum;
    beRecurring: boolean | null;
}

export interface getTransactionResult_session_accountData_cartHistory_coupons {
    __typename: "CartCoupon";
    name: string | null;
}

export interface getTransactionResult_session_accountData_cartHistory {
    __typename: "Cart";
    id: string | null;
    /**
     * Cart price
     */
    amount: any;
    /**
     * Currency for amount
     */
    currency: Currency;
    selectedPaymentOption: getTransactionResult_session_accountData_cartHistory_selectedPaymentOption | null;
    coupons: getTransactionResult_session_accountData_cartHistory_coupons[];
}

export interface getTransactionResult_session_accountData {
    __typename: "Account";
    cartHistory: getTransactionResult_session_accountData_cartHistory[] | null;
}

export interface getTransactionResult_session {
    __typename: "Session";
    /**
     * The account this session belongs to. Set if a verified email matches an existing account.
     */
    accountData: getTransactionResult_session_accountData | null;
}

export interface getTransactionResult {
    /**
     * transaction result with the given transactionId
     */
    getTransactionResult: getTransactionResult_getTransactionResult;
    /**
     * information about the current session. Will only be returned if Auth header is present with an active session id
     */
    session: getTransactionResult_session | null;
}

export interface getTransactionResultVariables {
    transactionId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DeHomeInsuranceContract
// ====================================================

export interface DeHomeInsuranceContract_documents_insuranceVersion {
    __typename: "InsuranceDocVersionInfo";
    version: number;
    financedFrom: any;
    financedUntil: any;
}

export interface DeHomeInsuranceContract_documents_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface DeHomeInsuranceContract_documents {
    __typename: "InsuranceDoc";
    serialNumber: any | null;
    insuranceDocType: string | null;
    name: string | null;
    started: any | null;
    created: any | null;
    deleted: any | null;
    /**
     * Information about the policy version, only for Contract documents
     */
    insuranceVersion: DeHomeInsuranceContract_documents_insuranceVersion | null;
    fileURL: string | null;
    fileData: DeHomeInsuranceContract_documents_fileData | null;
}

export interface DeHomeInsuranceContract_legalDocuments_current_documentFileDataList_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface DeHomeInsuranceContract_legalDocuments_current_documentFileDataList {
    __typename: "FileDataWithLanguage";
    languageCode: LanguageCode;
    fileData: DeHomeInsuranceContract_legalDocuments_current_documentFileDataList_fileData;
    legalDocumentFilenamePrefix: string | null;
}

export interface DeHomeInsuranceContract_legalDocuments_current {
    __typename: "LegalDocument";
    categoryId: number | null;
    category: LegalDocumentCategory | null;
    interaction: LegalDocumentInteraction | null;
    version: any | null;
    effectiveFrom: any | null;
    effectiveUntil: any | null;
    created: any | null;
    subcategory: string | null;
    documentFileDataList: DeHomeInsuranceContract_legalDocuments_current_documentFileDataList[] | null;
}

export interface DeHomeInsuranceContract_legalDocuments {
    __typename: "InsuranceLegalDocLists";
    current: DeHomeInsuranceContract_legalDocuments_current[];
}

export interface DeHomeInsuranceContract_cartInsurances {
    __typename: "CartInsurance";
    cartExtId: string;
}

export interface DeHomeInsuranceContract_wlLegalDocumentRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface DeHomeInsuranceContract_wlLegalDocumentRelations {
    __typename: "LegalDocumentWithRelation";
    legalDocument: DeHomeInsuranceContract_wlLegalDocumentRelations_legalDocument;
}

export interface DeHomeInsuranceContract {
    __typename: "DeHomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    /**
     * Serial number
     */
    serialNumber: any | null;
    /**
     * Contract status
     */
    status: InsuranceStatus | null;
    /**
     * First date of insurance contract
     */
    activeSince: any | null;
    buildingType: ModHomeBuildingType | null;
    subjectZipCode: string | null;
    /**
     * Monthly price (last paid price)
     */
    feeMonthly: any | null;
    /**
     * number of months for one payment
     */
    paymentPeriodMonths: any | null;
    documents: DeHomeInsuranceContract_documents[];
    legalDocuments: DeHomeInsuranceContract_legalDocuments;
    cartInsurances: DeHomeInsuranceContract_cartInsurances[];
    wlLegalDocumentRelations: DeHomeInsuranceContract_wlLegalDocumentRelations[] | null;
    ops: InsuranceOp[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InsuranceDoc
// ====================================================

export interface InsuranceDoc_insuranceVersion {
    __typename: "InsuranceDocVersionInfo";
    version: number;
    financedFrom: any;
    financedUntil: any;
}

export interface InsuranceDoc_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface InsuranceDoc {
    __typename: "InsuranceDoc";
    serialNumber: any | null;
    insuranceDocType: string | null;
    name: string | null;
    started: any | null;
    created: any | null;
    deleted: any | null;
    /**
     * Information about the policy version, only for Contract documents
     */
    insuranceVersion: InsuranceDoc_insuranceVersion | null;
    fileURL: string | null;
    fileData: InsuranceDoc_fileData | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LegalDocument
// ====================================================

export interface LegalDocument_documentFileDataList_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface LegalDocument_documentFileDataList {
    __typename: "FileDataWithLanguage";
    languageCode: LanguageCode;
    fileData: LegalDocument_documentFileDataList_fileData;
    legalDocumentFilenamePrefix: string | null;
}

export interface LegalDocument {
    __typename: "LegalDocument";
    categoryId: number | null;
    category: LegalDocumentCategory | null;
    interaction: LegalDocumentInteraction | null;
    version: any | null;
    effectiveFrom: any | null;
    effectiveUntil: any | null;
    created: any | null;
    subcategory: string | null;
    documentFileDataList: LegalDocument_documentFileDataList[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LegalDocumentWL
// ====================================================

export interface LegalDocumentWL {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ModularTravelDEInsuranceContract
// ====================================================

export interface ModularTravelDEInsuranceContract_documents_insuranceVersion {
    __typename: "InsuranceDocVersionInfo";
    version: number;
    financedFrom: any;
    financedUntil: any;
}

export interface ModularTravelDEInsuranceContract_documents_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface ModularTravelDEInsuranceContract_documents {
    __typename: "InsuranceDoc";
    serialNumber: any | null;
    insuranceDocType: string | null;
    name: string | null;
    started: any | null;
    created: any | null;
    deleted: any | null;
    /**
     * Information about the policy version, only for Contract documents
     */
    insuranceVersion: ModularTravelDEInsuranceContract_documents_insuranceVersion | null;
    fileURL: string | null;
    fileData: ModularTravelDEInsuranceContract_documents_fileData | null;
}

export interface ModularTravelDEInsuranceContract_legalDocuments_current_documentFileDataList_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface ModularTravelDEInsuranceContract_legalDocuments_current_documentFileDataList {
    __typename: "FileDataWithLanguage";
    languageCode: LanguageCode;
    fileData: ModularTravelDEInsuranceContract_legalDocuments_current_documentFileDataList_fileData;
    legalDocumentFilenamePrefix: string | null;
}

export interface ModularTravelDEInsuranceContract_legalDocuments_current {
    __typename: "LegalDocument";
    categoryId: number | null;
    category: LegalDocumentCategory | null;
    interaction: LegalDocumentInteraction | null;
    version: any | null;
    effectiveFrom: any | null;
    effectiveUntil: any | null;
    created: any | null;
    subcategory: string | null;
    documentFileDataList: ModularTravelDEInsuranceContract_legalDocuments_current_documentFileDataList[] | null;
}

export interface ModularTravelDEInsuranceContract_legalDocuments {
    __typename: "InsuranceLegalDocLists";
    current: ModularTravelDEInsuranceContract_legalDocuments_current[];
}

export interface ModularTravelDEInsuranceContract_cartInsurances {
    __typename: "CartInsurance";
    cartExtId: string;
}

export interface ModularTravelDEInsuranceContract_modTravelDeData {
    __typename: "ModularTravelDERelatedData";
    destinationCategory: Destination;
    firstDayOfTravel: any;
    lastDayOfTravel: any | null;
    durationCategory: Duration;
}

export interface ModularTravelDEInsuranceContract_wlLegalDocumentRelations_relation {
    __typename: "AccountLegalDocumentRelationWl";
    accepted: any | null;
    legalDocumentWlId: any;
    wlOfferId: string | null;
    accountId: any | null;
}

export interface ModularTravelDEInsuranceContract_wlLegalDocumentRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface ModularTravelDEInsuranceContract_wlLegalDocumentRelations {
    __typename: "LegalDocumentWithRelation";
    relation: ModularTravelDEInsuranceContract_wlLegalDocumentRelations_relation;
    legalDocument: ModularTravelDEInsuranceContract_wlLegalDocumentRelations_legalDocument;
}

export interface ModularTravelDEInsuranceContract {
    __typename: "ModularTravelDEInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    insuranceType: InsuranceType | null;
    /**
     * Contract status
     */
    status: InsuranceStatus | null;
    /**
     * First insured day (inclusive)
     */
    activeSince: any | null;
    activeUntil: any | null;
    /**
     * Policy number (contract ID and serial number)
     */
    policyNumber: string | null;
    /**
     * price
     */
    price: any | null;
    documents: ModularTravelDEInsuranceContract_documents[];
    legalDocuments: ModularTravelDEInsuranceContract_legalDocuments;
    cartInsurances: ModularTravelDEInsuranceContract_cartInsurances[];
    modTravelDeData: ModularTravelDEInsuranceContract_modTravelDeData | null;
    wlLegalDocumentRelations: ModularTravelDEInsuranceContract_wlLegalDocumentRelations[] | null;
    ops: InsuranceOp[] | null;
    financed: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Session
// ====================================================

export interface Session_accountData_name {
    __typename: "PersonName";
    first: string | null;
    last: string | null;
}

export interface Session_accountData_claims_claimCause {
    __typename: "ClaimCause";
    id: any | null;
    translationKey: string | null;
}

export interface Session_accountData_claims_contract_CarInsuranceContract {
    __typename: "CarInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface Session_accountData_claims_contract_HomeInsuranceContract {
    __typename: "HomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface Session_accountData_claims_contract_DeHomeInsuranceContract {
    __typename: "DeHomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface Session_accountData_claims_contract_TravelInsuranceContract {
    __typename: "TravelInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface Session_accountData_claims_contract_PetTravelInsuranceContract {
    __typename: "PetTravelInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface Session_accountData_claims_contract_LifeInsuranceContract {
    __typename: "LifeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface Session_accountData_claims_contract_TripCancellationInsuranceContract {
    __typename: "TripCancellationInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export interface Session_accountData_claims_contract_ModularTravelDEInsuranceContract {
    __typename: "ModularTravelDEInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
}

export type Session_accountData_claims_contract =
    | Session_accountData_claims_contract_CarInsuranceContract
    | Session_accountData_claims_contract_HomeInsuranceContract
    | Session_accountData_claims_contract_DeHomeInsuranceContract
    | Session_accountData_claims_contract_TravelInsuranceContract
    | Session_accountData_claims_contract_PetTravelInsuranceContract
    | Session_accountData_claims_contract_LifeInsuranceContract
    | Session_accountData_claims_contract_TripCancellationInsuranceContract
    | Session_accountData_claims_contract_ModularTravelDEInsuranceContract;

export interface Session_accountData_claims {
    __typename: "Claim";
    id: string | null;
    claimCause: Session_accountData_claims_claimCause | null;
    claimStatus: ClaimStatus | null;
    contract: Session_accountData_claims_contract | null;
}

export interface Session_accountData {
    __typename: "Account";
    id: string | null;
    name: Session_accountData_name | null;
    dateOfBirth: any | null;
    channel: ChannelName | null;
    email: string | null;
    countryCode: CountryCode | null;
    claims: Session_accountData_claims[] | null;
}

export interface Session_germanTravelInsuranceContracts_documents_insuranceVersion {
    __typename: "InsuranceDocVersionInfo";
    version: number;
    financedFrom: any;
    financedUntil: any;
}

export interface Session_germanTravelInsuranceContracts_documents_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface Session_germanTravelInsuranceContracts_documents {
    __typename: "InsuranceDoc";
    serialNumber: any | null;
    insuranceDocType: string | null;
    name: string | null;
    started: any | null;
    created: any | null;
    deleted: any | null;
    /**
     * Information about the policy version, only for Contract documents
     */
    insuranceVersion: Session_germanTravelInsuranceContracts_documents_insuranceVersion | null;
    fileURL: string | null;
    fileData: Session_germanTravelInsuranceContracts_documents_fileData | null;
}

export interface Session_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface Session_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList {
    __typename: "FileDataWithLanguage";
    languageCode: LanguageCode;
    fileData: Session_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList_fileData;
    legalDocumentFilenamePrefix: string | null;
}

export interface Session_germanTravelInsuranceContracts_legalDocuments_current {
    __typename: "LegalDocument";
    categoryId: number | null;
    category: LegalDocumentCategory | null;
    interaction: LegalDocumentInteraction | null;
    version: any | null;
    effectiveFrom: any | null;
    effectiveUntil: any | null;
    created: any | null;
    subcategory: string | null;
    documentFileDataList: Session_germanTravelInsuranceContracts_legalDocuments_current_documentFileDataList[] | null;
}

export interface Session_germanTravelInsuranceContracts_legalDocuments {
    __typename: "InsuranceLegalDocLists";
    current: Session_germanTravelInsuranceContracts_legalDocuments_current[];
}

export interface Session_germanTravelInsuranceContracts_cartInsurances {
    __typename: "CartInsurance";
    cartExtId: string;
}

export interface Session_germanTravelInsuranceContracts_modTravelDeData {
    __typename: "ModularTravelDERelatedData";
    destinationCategory: Destination;
    firstDayOfTravel: any;
    lastDayOfTravel: any | null;
    durationCategory: Duration;
}

export interface Session_germanTravelInsuranceContracts_wlLegalDocumentRelations_relation {
    __typename: "AccountLegalDocumentRelationWl";
    accepted: any | null;
    legalDocumentWlId: any;
    wlOfferId: string | null;
    accountId: any | null;
}

export interface Session_germanTravelInsuranceContracts_wlLegalDocumentRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface Session_germanTravelInsuranceContracts_wlLegalDocumentRelations {
    __typename: "LegalDocumentWithRelation";
    relation: Session_germanTravelInsuranceContracts_wlLegalDocumentRelations_relation;
    legalDocument: Session_germanTravelInsuranceContracts_wlLegalDocumentRelations_legalDocument;
}

export interface Session_germanTravelInsuranceContracts {
    __typename: "ModularTravelDEInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    insuranceType: InsuranceType | null;
    /**
     * Contract status
     */
    status: InsuranceStatus | null;
    /**
     * First insured day (inclusive)
     */
    activeSince: any | null;
    activeUntil: any | null;
    /**
     * Policy number (contract ID and serial number)
     */
    policyNumber: string | null;
    /**
     * price
     */
    price: any | null;
    documents: Session_germanTravelInsuranceContracts_documents[];
    legalDocuments: Session_germanTravelInsuranceContracts_legalDocuments;
    cartInsurances: Session_germanTravelInsuranceContracts_cartInsurances[];
    modTravelDeData: Session_germanTravelInsuranceContracts_modTravelDeData | null;
    wlLegalDocumentRelations: Session_germanTravelInsuranceContracts_wlLegalDocumentRelations[] | null;
    ops: InsuranceOp[] | null;
    financed: any | null;
}

export interface Session_germanHomeInsuranceContracts_documents_insuranceVersion {
    __typename: "InsuranceDocVersionInfo";
    version: number;
    financedFrom: any;
    financedUntil: any;
}

export interface Session_germanHomeInsuranceContracts_documents_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface Session_germanHomeInsuranceContracts_documents {
    __typename: "InsuranceDoc";
    serialNumber: any | null;
    insuranceDocType: string | null;
    name: string | null;
    started: any | null;
    created: any | null;
    deleted: any | null;
    /**
     * Information about the policy version, only for Contract documents
     */
    insuranceVersion: Session_germanHomeInsuranceContracts_documents_insuranceVersion | null;
    fileURL: string | null;
    fileData: Session_germanHomeInsuranceContracts_documents_fileData | null;
}

export interface Session_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList_fileData {
    __typename: "FileData";
    /**
     * relative URL
     */
    url: string | null;
    /**
     * file name
     */
    name: string | null;
    /**
     * file size in bytes
     */
    size: any | null;
    /**
     * extracted metadata information
     */
    extractedMetadata: string | null;
    thumbnailUrl: string | null;
    thumbnailName: string | null;
}

export interface Session_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList {
    __typename: "FileDataWithLanguage";
    languageCode: LanguageCode;
    fileData: Session_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList_fileData;
    legalDocumentFilenamePrefix: string | null;
}

export interface Session_germanHomeInsuranceContracts_legalDocuments_current {
    __typename: "LegalDocument";
    categoryId: number | null;
    category: LegalDocumentCategory | null;
    interaction: LegalDocumentInteraction | null;
    version: any | null;
    effectiveFrom: any | null;
    effectiveUntil: any | null;
    created: any | null;
    subcategory: string | null;
    documentFileDataList: Session_germanHomeInsuranceContracts_legalDocuments_current_documentFileDataList[] | null;
}

export interface Session_germanHomeInsuranceContracts_legalDocuments {
    __typename: "InsuranceLegalDocLists";
    current: Session_germanHomeInsuranceContracts_legalDocuments_current[];
}

export interface Session_germanHomeInsuranceContracts_cartInsurances {
    __typename: "CartInsurance";
    cartExtId: string;
}

export interface Session_germanHomeInsuranceContracts_wlLegalDocumentRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface Session_germanHomeInsuranceContracts_wlLegalDocumentRelations {
    __typename: "LegalDocumentWithRelation";
    legalDocument: Session_germanHomeInsuranceContracts_wlLegalDocumentRelations_legalDocument;
}

export interface Session_germanHomeInsuranceContracts {
    __typename: "DeHomeInsuranceContract";
    /**
     * Contract ID
     */
    id: string | null;
    /**
     * Serial number
     */
    serialNumber: any | null;
    /**
     * Contract status
     */
    status: InsuranceStatus | null;
    /**
     * First date of insurance contract
     */
    activeSince: any | null;
    buildingType: ModHomeBuildingType | null;
    subjectZipCode: string | null;
    /**
     * Monthly price (last paid price)
     */
    feeMonthly: any | null;
    /**
     * number of months for one payment
     */
    paymentPeriodMonths: any | null;
    documents: Session_germanHomeInsuranceContracts_documents[];
    legalDocuments: Session_germanHomeInsuranceContracts_legalDocuments;
    cartInsurances: Session_germanHomeInsuranceContracts_cartInsurances[];
    wlLegalDocumentRelations: Session_germanHomeInsuranceContracts_wlLegalDocumentRelations[] | null;
    ops: InsuranceOp[] | null;
}

export interface Session_legalDocumentsWithRelations_relation {
    __typename: "AccountLegalDocumentRelationWl";
    accepted: any | null;
    legalDocumentWlId: any;
    wlOfferId: string | null;
    accountId: any | null;
}

export interface Session_legalDocumentsWithRelations_legalDocument {
    __typename: "LegalDocumentWL";
    category: legalDocumentCategory;
    interaction: LegalDocumentInteraction;
    version: number;
    effectiveFrom: any;
    effectiveUntil: any | null;
    name: string | null;
    url: string | null;
}

export interface Session_legalDocumentsWithRelations {
    __typename: "LegalDocumentWithRelation";
    relation: Session_legalDocumentsWithRelations_relation;
    legalDocument: Session_legalDocumentsWithRelations_legalDocument;
}

export interface Session {
    __typename: "Session";
    /**
     * Session ID. Send this in the Authorization header to your GraphQL requests
     */
    sessionId: string;
    /**
     * The email address this session belongs to. May not have been verified.
     */
    email: string | null;
    /**
     * The email address this session belongs to, but only if it has been verified.
     */
    verifiedEmail: string | null;
    liveChatToken: string | null;
    /**
     * The account this session belongs to. Set if a verified email matches an existing account.
     */
    accountData: Session_accountData | null;
    /**
     * The travel insurance contracts that have been created for the account the session belongs to.
     */
    germanTravelInsuranceContracts: Session_germanTravelInsuranceContracts[];
    /**
     * The home insurance contracts that have been created for the account the session belongs to.
     */
    germanHomeInsuranceContracts: Session_germanHomeInsuranceContracts[];
    /**
     * The legal document status for the account the session belongs to
     */
    legalDocumentsWithRelations: Session_legalDocumentsWithRelations[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TransactionResult
// ====================================================

export interface TransactionResult {
    __typename: "TransactionResult";
    transactionId: string | null;
    resultCode: TransactionStatus;
    resultMessage: string | null;
    orderId: string | null;
    providerTransactionId: string | null;
    providerName: string | null;
    userId: string | null;
    insuranceId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VoidType
// ====================================================

export interface VoidType {
    __typename: "VoidType";
    code: number;
    message: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Channel on which an account or insurance created
 */
export enum ChannelName {
    BROKER_DE = "BROKER_DE",
    CHERRISK_WAPP = "CHERRISK_WAPP",
    CHERRISK_WAPP_DE_2022 = "CHERRISK_WAPP_DE_2022",
    CHERRISK_WAPP_DE_BROKER = "CHERRISK_WAPP_DE_BROKER",
    COVOMO = "COVOMO",
    MR_MONEY = "MR_MONEY",
    NETRISK = "NETRISK",
    SK_TREND = "SK_TREND",
    SOFTFAIR = "SOFTFAIR",
    TELESALES = "TELESALES",
    WHITE_LABEL_CZ = "WHITE_LABEL_CZ",
    WHITE_LABEL_HU = "WHITE_LABEL_HU",
    WHITE_LABEL_RO = "WHITE_LABEL_RO",
    WHITE_LABEL_SK = "WHITE_LABEL_SK",
    WPR = "WPR",
}

/**
 * Claim status
 */
export enum ClaimStatus {
    accepted_finished = "accepted_finished",
    accepted_payment_pending = "accepted_payment_pending",
    assistance_opened_by_partner = "assistance_opened_by_partner",
    automatic_accepted_finished = "automatic_accepted_finished",
    call_arranged = "call_arranged",
    canceled_by_user = "canceled_by_user",
    checking_local_expert = "checking_local_expert",
    closed_by_partner = "closed_by_partner",
    closed_draft_timeout = "closed_draft_timeout",
    closed_totaled = "closed_totaled",
    closed_with_regress = "closed_with_regress",
    closed_without_payment = "closed_without_payment",
    closed_without_payment_invalid_data = "closed_without_payment_invalid_data",
    draft_canceled = "draft_canceled",
    open_draft = "open_draft",
    open_reopened = "open_reopened",
    open_submitted = "open_submitted",
    ordered_from_partner = "ordered_from_partner",
    reimbursement_closed_with_payment = "reimbursement_closed_with_payment",
    reimbursement_closed_without_payment = "reimbursement_closed_without_payment",
    reimbursement_opened_by_partner = "reimbursement_opened_by_partner",
    rejected = "rejected",
    rejected_timeout = "rejected_timeout",
    repair_done = "repair_done",
    repair_in_progress = "repair_in_progress",
    sending_to_partner = "sending_to_partner",
    waiting_for_client = "waiting_for_client",
    waiting_for_client_payment = "waiting_for_client_payment",
    waiting_for_expert = "waiting_for_expert",
    waiting_for_operator = "waiting_for_operator",
}

/**
 * Country codes
 */
export enum CountryCode {
    CZ = "CZ",
    DE = "DE",
    HU = "HU",
    RO = "RO",
    SK = "SK",
}

/**
 * Currencies
 */
export enum Currency {
    CHF = "CHF",
    CZK = "CZK",
    EUR = "EUR",
    HUF = "HUF",
    JPY = "JPY",
    RON = "RON",
    USD = "USD",
}

export enum Destination {
    america = "america",
    europe = "europe",
    notEUAM = "notEUAM",
}

export enum Duration {
    DAILY = "DAILY",
    YEARLY = "YEARLY",
}

/**
 * Insurance operation
 */
export enum InsuranceOp {
    Cancel = "Cancel",
    CeaseOfNeedsDelete = "CeaseOfNeedsDelete",
    Delete = "Delete",
    Inspect = "Inspect",
    Modify = "Modify",
    Pause = "Pause",
    Pay = "Pay",
    Reactivate = "Reactivate",
    Renew = "Renew",
    ReportClaim = "ReportClaim",
    Resume = "Resume",
    ResumeWithPayment = "ResumeWithPayment",
}

/**
 * Insurance Status codes
 */
export enum InsuranceStatus {
    ActiveImmediateTermination = "ActiveImmediateTermination",
    ActiveJointAgreement = "ActiveJointAgreement",
    ActiveNormal = "ActiveNormal",
    ActiveNotFeasible = "ActiveNotFeasible",
    ActivePaused = "ActivePaused",
    ActiveSubsequentPayment = "ActiveSubsequentPayment",
    ActiveTermination = "ActiveTermination",
    ActiveTooRisky = "ActiveTooRisky",
    DeletedCanceled = "DeletedCanceled",
    DeletedCustomerRequest = "DeletedCustomerRequest",
    DeletedDeath = "DeletedDeath",
    DeletedDestroyed = "DeletedDestroyed",
    DeletedFinished = "DeletedFinished",
    DeletedIllegalArgs = "DeletedIllegalArgs",
    DeletedImmediateTermination = "DeletedImmediateTermination",
    DeletedJointAgreement = "DeletedJointAgreement",
    DeletedLossOfInterest = "DeletedLossOfInterest",
    DeletedNotFeasible = "DeletedNotFeasible",
    DeletedSEPAchargeback = "DeletedSEPAchargeback",
    DeletedTermination = "DeletedTermination",
    DeletedTooRisky = "DeletedTooRisky",
    DeletedUnpaid = "DeletedUnpaid",
    InactiveDestroyed = "InactiveDestroyed",
    InactivePaused = "InactivePaused",
    InactiveRiskAssessment = "InactiveRiskAssessment",
    InactiveSubsequentPayment = "InactiveSubsequentPayment",
    InactiveUnpaid = "InactiveUnpaid",
    PendingCanceled = "PendingCanceled",
    PendingPayment = "PendingPayment",
    PendingRiskAssessment = "PendingRiskAssessment",
    WaitingForActivation = "WaitingForActivation",
    WaitingForDeletion = "WaitingForDeletion",
    WaitingForPayment = "WaitingForPayment",
    WaitingForReactivation = "WaitingForReactivation",
}

/**
 * Insurance types
 */
export enum InsuranceType {
    CAR = "CAR",
    CZ_CANCELLATION_WL = "CZ_CANCELLATION_WL",
    CZ_TRAVEL_WL = "CZ_TRAVEL_WL",
    DE_HOME_PACKAGES = "DE_HOME_PACKAGES",
    DE_TRAVEL_MOD_A = "DE_TRAVEL_MOD_A",
    DE_TRAVEL_MOD_S = "DE_TRAVEL_MOD_S",
    HOME = "HOME",
    LIFE = "LIFE",
    RO_CANCELLATION_WL = "RO_CANCELLATION_WL",
    RO_TRAVEL_WL = "RO_TRAVEL_WL",
    SK_CANCELLATION_WL = "SK_CANCELLATION_WL",
    SK_TRAVEL_WL = "SK_TRAVEL_WL",
    TRAVEL = "TRAVEL",
    TRIP_CANCELLATION = "TRIP_CANCELLATION",
}

/**
 * Language code
 */
export enum LanguageCode {
    CS = "CS",
    DE = "DE",
    EN = "EN",
    HU = "HU",
    RO = "RO",
    SK = "SK",
}

/**
 * Legal document main categories
 */
export enum LegalDocumentCategory {
    CARCOVER = "CARCOVER",
    CITYLIFE = "CITYLIFE",
    DE_TRAVEL_MOD_A = "DE_TRAVEL_MOD_A",
    DE_TRAVEL_MOD_S = "DE_TRAVEL_MOD_S",
    FORM = "FORM",
    GENERAL = "GENERAL",
    HOMESHIELD = "HOMESHIELD",
    NON_PRODUCT = "NON_PRODUCT",
    NOTICE = "NOTICE",
    TRAVEL_DOMESTIC = "TRAVEL_DOMESTIC",
    TRAVEL_NON_DOMESTIC = "TRAVEL_NON_DOMESTIC",
    TRIP_CANCELLATION = "TRIP_CANCELLATION",
}

/**
 * Legal document required user interaction types
 */
export enum LegalDocumentInteraction {
    NEED_TO_ACCEPT = "NEED_TO_ACCEPT",
    NONE = "NONE",
    NOTIFY = "NOTIFY",
}

export enum ModHomeBuildingType {
    flat = "flat",
    house = "house",
}

/**
 * Payment method
 */
export enum PaymentMethodEnum {
    BankCard = "BankCard",
    External = "External",
    PayPal = "PayPal",
    SEPA = "SEPA",
}

/**
 * Transaction status by BigFish paymentGateway
 */
export enum TransactionStatus {
    CANCELED = "CANCELED",
    ERROR = "ERROR",
    FunctionNotImplemented = "FunctionNotImplemented",
    InactiveProvider = "InactiveProvider",
    InactiveStore = "InactiveStore",
    MissingParameter = "MissingParameter",
    MissingRemoteAddress = "MissingRemoteAddress",
    OPEN = "OPEN",
    PENDING = "PENDING",
    PaymentLinkCancelDenied = "PaymentLinkCancelDenied",
    PaypalrestError = "PaypalrestError",
    SUCCESSFUL = "SUCCESSFUL",
    TIMEOUT = "TIMEOUT",
    UNSUCCESFUL = "UNSUCCESFUL",
    UnauthorizedAccess = "UnauthorizedAccess",
    UnauthorizedRemoteAddress = "UnauthorizedRemoteAddress",
    UnknownParameter = "UnknownParameter",
    UnknownPaymentLink = "UnknownPaymentLink",
    UnknownProvider = "UnknownProvider",
    UnknownProviderForStore = "UnknownProviderForStore",
    UnknownReferenceTransaction = "UnknownReferenceTransaction",
    UnknownStore = "UnknownStore",
    UnknownTransaction = "UnknownTransaction",
    WrongApikey = "WrongApikey",
    WrongParameter = "WrongParameter",
    WrongProviderSettings = "WrongProviderSettings",
    WrongReferenceTransactionStatus = "WrongReferenceTransactionStatus",
}

export enum legalDocumentCategory {
    ANNOUNCEMENT = "ANNOUNCEMENT",
    CHERRISK_PRIVACY_POLICY = "CHERRISK_PRIVACY_POLICY",
    COMPLAINT = "COMPLAINT",
    GENERAL_CONTRACT_INFORMATION = "GENERAL_CONTRACT_INFORMATION",
    HEALTH_DATA = "HEALTH_DATA",
    INSURANCE_INTERMEDIARY_INFO = "INSURANCE_INTERMEDIARY_INFO",
    IPID = "IPID",
    MARKETING_POLICY = "MARKETING_POLICY",
    PRIVACY_POLICY = "PRIVACY_POLICY",
    PROTOCOL_OF_ADVICE = "PROTOCOL_OF_ADVICE",
    REGISTRATION_POLICY = "REGISTRATION_POLICY",
    SCALE_MARKETING_POLICY = "SCALE_MARKETING_POLICY",
    TERMS = "TERMS",
    WL_HU_CLAIM_SETTLEMENT_NOTICE = "WL_HU_CLAIM_SETTLEMENT_NOTICE",
    WL_HU_COMPLAINT = "WL_HU_COMPLAINT",
    WL_HU_COOKIE_POLICY = "WL_HU_COOKIE_POLICY",
    WL_HU_IPID = "WL_HU_IPID",
    WL_HU_MARKETING_POLICY = "WL_HU_MARKETING_POLICY",
    WL_HU_PRIVACY_POLICY = "WL_HU_PRIVACY_POLICY",
    WL_HU_SCALE_MARKETING_POLICY = "WL_HU_SCALE_MARKETING_POLICY",
    WL_HU_TERMS = "WL_HU_TERMS",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
