import { Surface, Text, useTextVariant } from "@monster/chr-ui";
import { LoctoolMessage } from "@monster/loctool";
import { Box, DateFormat, List } from "@monster/shared";
import React from "react";
import { ClaimLink } from "./ClaimLink";
import { useTheme } from "styled-components";
import { DateUtils } from "@utils/DateHelpers";
import { GqlDeWebapp } from "@api/Api";
import { Duration } from "@api/graphql/deWebapp/types";

type Props = {
    claimableContracts: Array<GqlDeWebapp.DeHomeInsuranceContract | GqlDeWebapp.ModularTravelDEInsuranceContract>;
};

export const FileClaimSection = ({ claimableContracts }: Props) => {
    const theme = useTheme();
    const TextVariant = useTextVariant();

    return (
        <>
            <Text as="h2" $variant="h3Mobile" $style={{ margin: "40px 16px 16px", fontWeight: 400 }} $styleMedium={{ ...TextVariant({ $variant: "h2Desktop" }), margin: "12px 16px 20px" }}>
                <LoctoolMessage id="pages.dashboard.fileClaimSection.title" />
            </Text>

            <Surface
                $variant="white"
                $style={{
                    border: `1px solid ${theme.color.greyD}`,
                    padding: "15px 15px 23px",
                    marginBottom: 32,
                }}
            >
                <Box $style={{ maxWidth: 504, margin: "0 auto", textAlign: "center" }}>
                    <Box
                        $style={{
                            width: 100,
                            height: 80,
                            margin: "0 auto",
                            backgroundImage: 'image-set( url("/images/file-claim.png") 1x, url("/images/file-claim@2x.png") 2x, url("/images/file-claim@3x.png") 3x)',
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "auto 100%",
                        }}
                    />
                    <Text as="h3" $variant="bodyMobile" $style={{ fontWeight: 700, margin: "16px 0 0" }}>
                        <LoctoolMessage id="components.contactUs.claim" />
                    </Text>
                    <Text as="p" $variant="bodyMobile" $style={{ margin: "0 0 16px" }}>
                        <LoctoolMessage id="pages.dashboard.fileClaimSection.description" />
                    </Text>
                </Box>
                <List $cssReset $style={{ boxShadow: `inset 0px 0px 0px 1px ${theme.color.neutral300}`, borderRadius: 16, overflow: "hidden", maxWidth: 574, margin: "0 auto" }}>
                    {claimableContracts.map(contract => {
                        if (contract.__typename === "ModularTravelDEInsuranceContract") {
                            const activeFrom = DateUtils.format(contract.activeSince, DateFormat.deShort);
                            const info =
                                contract.modTravelDeData?.durationCategory === Duration.YEARLY ? (
                                    <LoctoolMessage id="pages.dashboard.fileClaimSection.link.travel.info.annual" values={{ activeFrom }} />
                                ) : (
                                    <LoctoolMessage
                                        id="pages.dashboard.fileClaimSection.link.travel.info"
                                        values={{ activeFrom, activeUntil: DateUtils.format(contract.activeUntil ?? DateUtils.parseISO(contract.financed), DateFormat.deShort) }}
                                    />
                                );

                            return <ClaimLink key={contract.id} label={<LoctoolMessage id={"pages.dashboard.fileClaimSection.link.travel"} />} info={info} contractId={contract.id!} />;
                        }
                        if (contract.__typename === "DeHomeInsuranceContract") {
                            return (
                                <ClaimLink
                                    key={contract.id}
                                    label={<LoctoolMessage id={"pages.dashboard.fileClaimSection.link.home"} />}
                                    info={contract.subjectZipCode ?? "missing_data"}
                                    contractId={contract.id!}
                                />
                            );
                        }
                        console.warn("Unhandled contract type", contract);
                    })}
                </List>
            </Surface>
        </>
    );
};
