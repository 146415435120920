import { GqlDeWebapp } from "@api/Api";
import log from "loglevel";
import { DateUtils } from "./DateHelpers";

export enum ClaimFrontendStatus {
    filed = "filed",
    in_progress = "in_progress",
    waiting_client_task = "waiting_client_task",
    closed = "closed",
}

export class Helpers {
    public static getLegalDocumentPdfLink(legalDocument: { url?: string | null; category: string } | null): string {
        if (!legalDocument?.url) {
            console.warn(`File url not available for ${legalDocument?.category} legal document`);
            return window.location.href;
        }
        const fileUrl = encodeURIComponent(`${process.env.REACT_APP_DOCUMENT_URL_BASE}${legalDocument.url}`);
        return `${process.env.REACT_APP_PDF_VIEWER_URL ?? ""}/?file=${fileUrl}`;
    }

    public static getLocationParameterByName(name: string, url: string): string | null {
        name = name.replace(/[[]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    public static openLivePersonChat() {
        const engagementId = process.env.REACT_APP_LIVEPERSON_ENGAGEMENT_ID_DE_AUTH;
        log.debug(`Opening engagementID: ${engagementId}`);
        if (engagementId && typeof lpTag !== "undefined") {
            try {
                lpTag.taglets.rendererStub.click(engagementId);
            } catch (error) {
                console.dir(error);
            }
        } else {
            log.debug('Unable to open chat for language: "DE", authorized: "yes", maybe REACT_APP_LIVEPERSON_ENGAGEMENT_ID_DE_AUTH is undefined or lpTag is not loaded');
        }
    }

    public static refreshLivePersonTagImmediate = () => {
        try {
            if (typeof lpTag !== "undefined" && !!lpTag.newPage && typeof lpTag.newPage === "function") {
                lpTag.newPage(document.location.href);
            }
        } catch (error) {}
    };

    public static nullFilter<T>(item: T | null): item is T {
        return item !== null;
    }

    public static getTransactionResultStatus(resultCode?: GqlDeWebapp.TransactionStatus | null): "success" | "pending" | "failed" {
        switch (resultCode) {
            case GqlDeWebapp.TransactionStatus.SUCCESSFUL:
                return "success";
            case GqlDeWebapp.TransactionStatus.ERROR:
            case GqlDeWebapp.TransactionStatus.UnauthorizedAccess:
            case GqlDeWebapp.TransactionStatus.UnauthorizedRemoteAddress:
            case GqlDeWebapp.TransactionStatus.UnknownParameter:
            case GqlDeWebapp.TransactionStatus.UnknownPaymentLink:
            case GqlDeWebapp.TransactionStatus.UnknownProvider:
            case GqlDeWebapp.TransactionStatus.UnknownProviderForStore:
            case GqlDeWebapp.TransactionStatus.UnknownStore:
            case GqlDeWebapp.TransactionStatus.UnknownTransaction:
            case GqlDeWebapp.TransactionStatus.UnknownReferenceTransaction:
            case GqlDeWebapp.TransactionStatus.WrongApikey:
            case GqlDeWebapp.TransactionStatus.WrongParameter:
            case GqlDeWebapp.TransactionStatus.WrongProviderSettings:
            case GqlDeWebapp.TransactionStatus.WrongReferenceTransactionStatus:
            case GqlDeWebapp.TransactionStatus.PaypalrestError:
            case GqlDeWebapp.TransactionStatus.TIMEOUT:
            case GqlDeWebapp.TransactionStatus.UNSUCCESFUL:
            case GqlDeWebapp.TransactionStatus.FunctionNotImplemented:
            case GqlDeWebapp.TransactionStatus.InactiveStore:
            case GqlDeWebapp.TransactionStatus.InactiveProvider:
            case GqlDeWebapp.TransactionStatus.MissingParameter:
            case GqlDeWebapp.TransactionStatus.MissingRemoteAddress:
            case GqlDeWebapp.TransactionStatus.PaymentLinkCancelDenied:
            case GqlDeWebapp.TransactionStatus.CANCELED:
                return "failed";
            case GqlDeWebapp.TransactionStatus.OPEN:
            case GqlDeWebapp.TransactionStatus.PENDING:
            default:
                return "pending";
        }
    }

    public static getClaimStatuses(status: ClaimFrontendStatus): GqlDeWebapp.ClaimStatus[] {
        switch (status) {
            case ClaimFrontendStatus.filed:
                return [GqlDeWebapp.ClaimStatus.open_submitted, GqlDeWebapp.ClaimStatus.open_reopened];
            case ClaimFrontendStatus.in_progress:
                return [
                    GqlDeWebapp.ClaimStatus.waiting_for_operator,
                    GqlDeWebapp.ClaimStatus.waiting_for_expert,
                    GqlDeWebapp.ClaimStatus.sending_to_partner,
                    GqlDeWebapp.ClaimStatus.checking_local_expert,
                    GqlDeWebapp.ClaimStatus.call_arranged,
                    GqlDeWebapp.ClaimStatus.repair_in_progress,
                    GqlDeWebapp.ClaimStatus.repair_done,
                    GqlDeWebapp.ClaimStatus.waiting_for_client_payment,
                    GqlDeWebapp.ClaimStatus.reimbursement_opened_by_partner,
                ];
            case ClaimFrontendStatus.waiting_client_task:
                return [GqlDeWebapp.ClaimStatus.waiting_for_client];
            case ClaimFrontendStatus.closed:
                return [
                    GqlDeWebapp.ClaimStatus.closed_with_regress,
                    GqlDeWebapp.ClaimStatus.rejected,
                    GqlDeWebapp.ClaimStatus.rejected_timeout,
                    GqlDeWebapp.ClaimStatus.closed_totaled,
                    GqlDeWebapp.ClaimStatus.closed_without_payment,
                    GqlDeWebapp.ClaimStatus.closed_without_payment_invalid_data,
                    GqlDeWebapp.ClaimStatus.closed_by_partner,
                    GqlDeWebapp.ClaimStatus.closed_draft_timeout,
                    GqlDeWebapp.ClaimStatus.canceled_by_user,
                    GqlDeWebapp.ClaimStatus.reimbursement_closed_without_payment,
                    GqlDeWebapp.ClaimStatus.reimbursement_closed_with_payment,
                ];
            default:
                return [];
        }
    }

    /**
     * Filter and sort documents with duplicated serial number
     * @param insuranceDocs documents field of a contract
     * @returns CONTRACT typed documents distinct by serialNumber
     */
    public static getSortedContractDocs(insuranceDocs: GqlDeWebapp.InsuranceDoc[]): GqlDeWebapp.InsuranceDoc[] {
        return insuranceDocs
            .filter(doc => doc.insuranceDocType === "CONTRACT" && !doc.deleted)
            .sort((d1, d2) => d1.serialNumber - d2.serialNumber || DateUtils.parseISO(d2.started).getTime() - DateUtils.parseISO(d1.started).getTime())
            .reduce((prev: GqlDeWebapp.InsuranceDoc[], curr: GqlDeWebapp.InsuranceDoc) => {
                const prevDoc = prev.length > 0 ? prev[prev.length - 1] : null;
                if (!prevDoc || (!curr.deleted && prevDoc.serialNumber !== curr.serialNumber)) {
                    prev.push(curr);
                }
                return prev;
            }, [] as GqlDeWebapp.InsuranceDoc[]);
    }

    /**
     * Filter and sort documents that are not the current version (highest version number)
     * @param insuranceDocs documents field of a contract
     * @returns CONTRACT typed documents distinct by serialNumber
     */
    public static getSortedArchiveContractDocs(insuranceDocs: GqlDeWebapp.InsuranceDoc[]): GqlDeWebapp.InsuranceDoc[] {
        const currentVersion = Math.max(...insuranceDocs.map(doc => doc.insuranceVersion?.version ?? 0));
        return insuranceDocs
            .filter(doc => doc.insuranceDocType === "CONTRACT" && !doc.deleted)
            .sort((d1, d2) => d1.serialNumber - d2.serialNumber || DateUtils.parseISO(d2.started).getTime() - DateUtils.parseISO(d1.started).getTime())
            .filter(d => (d.insuranceVersion?.version ?? Infinity) !== currentVersion);
    }

    public static hasArchivedContractDocs(insuranceDocs: GqlDeWebapp.InsuranceDoc[]): boolean {
        return insuranceDocs.filter(doc => doc.insuranceDocType === "CONTRACT" && (doc.insuranceVersion?.version ?? 0) > 0).length > 0;
    }
}
