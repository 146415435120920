import React, { useState } from "react";
import { Button, Text } from "@monster/chr-ui";
import { SVGChrIcon32BasicsArrowOpen } from "@monster/chr-ui/dist/svg_assets";
import { LoctoolMessage } from "@monster/loctool";
import { Flex } from "@monster/shared";

type Props = {
    label: React.ReactNode;
};

export const DashboardAccordion = ({ label, children }: React.PropsWithChildren<Props>) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <Flex.Container $style={{ gap: 16, justifyContent: "space-between", alignItems: "center", padding: "36px 12px 12px 16px" }}>
                <Text as="h3" $variant="h3Mobile" $style={{ margin: 0, fontWeight: 400 }}>
                    {label}
                </Text>
                <Button.IconOnly
                    size="small"
                    btnLabel={<LoctoolMessage id="pages.dashboard.toggleArchives" />}
                    icon={<SVGChrIcon32BasicsArrowOpen style={{ transform: isOpen ? "rotate(180deg)" : "none" }} />}
                    onClick={() => setOpen(!isOpen)}
                />
            </Flex.Container>
            <div aria-atomic="true" aria-live="polite">
                {isOpen && children}
            </div>
        </>
    );
};
