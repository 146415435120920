import React, { useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Box, Flex } from "@monster/shared";
import { Button, Text, useNotification, useTextVariant } from "@monster/chr-ui";
import { SepaPaymentAppBar } from "@pages/SepaPayment/SepaPaymentAppBar";
import { SepaPaymentCart_DEHomePackages, SepaPaymentCart_DELegacyHomePackages, SepaPaymentCart_LifeInsurance, SepaPaymentCart_ModularTravel } from "@pages/SepaPayment/SepaPaymentCart";
import { SepaPaymentFooter } from "@pages/SepaPayment/SepaPaymentFooter";
import { useHistory, useParams } from "react-router-dom";
import { Api } from "@api/Api";
import {
    cart_cart,
    cart_cart_insurances_contract_DeHomeInsuranceContract,
    cart_cart_insurances_contract_HomeInsuranceContract,
    cart_cart_insurances_contract_LifeInsuranceContract,
    cart_cart_insurances_contract_ModularTravelDEInsuranceContract,
    InsuranceType,
} from "@api/graphql/selfservice/types";
import { Loctool, LoctoolMessage } from "@monster/loctool";
import { SepaResultDialog } from "./SepaResultDialog";
import { Helpers } from "@utils/Helpers";

export const SepaPaymentPage = () => {
    const textVariant = useTextVariant();
    const theme = useTheme();
    const showNotification = useNotification();
    const [cart, setCart] = useState<cart_cart | null>(null);
    const { token } = useParams<{ token: string }>();
    const history = useHistory();
    const transactionId = useMemo(() => Helpers.getLocationParameterByName("TransactionId", history.location.search), [history.location.search]);

    useEffect(() => {
        (async () => {
            if (transactionId !== null) {
                return;
            }
            try {
                const cart = await Api.getSelfserviceCart(token);
                setCart(cart);
            } catch (e) {
                setCart(null);
            }
        })();
    }, [token, transactionId]);

    const doPayment = async () => {
        try {
            const url = await Api.selfServiceSepaPay(token);
            if (url) {
                document.location.href = url;
                return;
            }
        } catch (e) {}
        showNotification(Loctool.instance.formatMessage({ id: "api.errors.sepa_payment_init_failed	" }));
        history.push(document.location.pathname);
    };

    if (transactionId !== null) {
        return (
            <PageWrapper>
                <SepaPaymentAppBar />
                <SepaResultDialog />
                <Box as="main" $style={{ width: "100%", maxWidth: 504, margin: "0 auto" }}></Box>
                <SepaPaymentFooter />
            </PageWrapper>
        );
    }

    return (
        <PageWrapper>
            <SepaPaymentAppBar />
            <SepaResultDialog />

            <Box as="main" $style={{ width: "100%", maxWidth: 504, margin: "0 auto" }}>
                {/* eslint-disable-next-line no-constant-condition */}
                {cart ? (
                    <>
                        <Flex.Container
                            $style={{ columnGap: 16, backgroundColor: theme.color.greyN, margin: "24px 0 0", borderRadius: 8, boxShadow: `inset 0px 0px 0px 1px ${theme.color.greyD}`, padding: 16 }}
                            $styleLarge={{ marginBottom: -10 }}
                        >
                            <Flex.Item $shrink="shrink">
                                <Box
                                    $style={{
                                        width: 32,
                                        height: 32,
                                        backgroundImage:
                                            'image-set( url("/images/global-emma-avatar-statement-32.png") 1x, url("/images/global-emma-avatar-statement-32@2x.png") 2x, url("/images/global-emma-avatar-statement-32@3x.png") 3x)',
                                    }}
                                />
                            </Flex.Item>

                            <Flex.Item $shrink="auto" $style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <LoctoolMessage id="pages.sepa.lead" />
                            </Flex.Item>
                        </Flex.Container>

                        {[InsuranceType.LIFE].includes(cart.insurances[0].insuranceType) && (
                            <SepaPaymentCart_LifeInsurance
                                insuranceId={cart.insurances[0].id}
                                billingPeriod={cart.insurances[0].billingPeriod}
                                amountTotal={cart.amountTotal.amount}
                                insuranceAmountId={(cart.insurances[0].contract as cart_cart_insurances_contract_LifeInsuranceContract).insuranceAmountId ?? 0}
                                insureeCount={cart.insurances.length}
                            />
                        )}
                        {[InsuranceType.DE_TRAVEL_MOD_A, InsuranceType.DE_TRAVEL_MOD_S].includes(cart.insurances[0].insuranceType) && (
                            <SepaPaymentCart_ModularTravel
                                insuranceId={cart.insurances[0].id}
                                amountTotal={cart.amountTotal.amount}
                                contract={cart.insurances[0].contract as cart_cart_insurances_contract_ModularTravelDEInsuranceContract}
                                isAnnual={cart.insurances[0].insuranceType === InsuranceType.DE_TRAVEL_MOD_A}
                            />
                        )}
                        {[InsuranceType.DE_HOME_PACKAGES].includes(cart.insurances[0].insuranceType) && (
                            <SepaPaymentCart_DEHomePackages
                                insuranceId={cart.insurances[0].id}
                                amountTotal={cart.amountTotal.amount}
                                contract={cart.insurances[0].contract as cart_cart_insurances_contract_DeHomeInsuranceContract}
                            />
                        )}

                        {[InsuranceType.HOME].includes(cart.insurances[0].insuranceType) && (
                            <SepaPaymentCart_DELegacyHomePackages
                                insuranceId={cart.insurances[0].id}
                                amountTotal={cart.amountTotal.amount}
                                contract={cart.insurances[0].contract as cart_cart_insurances_contract_HomeInsuranceContract}
                            />
                        )}

                        <Text as="p" $variant="captionMobile" $style={{ margin: "32px 0", paddingLeft: 16, paddingRight: 16, textAlign: "center" }} $styleLarge={{ marginTop: 24 }}>
                            <LoctoolMessage id="pages.sepa.paydisclaimer" />
                        </Text>

                        <Box $style={{ maxWidth: 242 + 2 * 16, margin: "32px auto", padding: "0 16px", textAlign: "center" }} $styleLarge={{ marginBottom: 60 }}>
                            <Button.Primary onClick={doPayment} btnLabel={<LoctoolMessage id="pages.sepa.paynow.button" />} isExpanded />
                        </Box>
                    </>
                ) : (
                    <>
                        <Text
                            as="h1"
                            $variant="h3Desktop"
                            $style={{ margin: "0 0 24px", padding: "44px 16px 20px" }}
                            $styleLarge={{ ...textVariant({ $variant: "h2Desktop" }), marginBottom: 16, paddingTop: 38, paddingBottom: 12 }}
                        >
                            <LoctoolMessage id="pages.sepa.expired.title" />
                        </Text>

                        <Box $style={{ backgroundColor: theme.color.white, borderRadius: 16, marginBottom: 48, padding: "0 24px 24px", textAlign: "center" }}>
                            <Text as="h2" $variant="h3Desktop" $style={{ margin: "0 0 8px", padding: "32px 16px 16px" }}>
                                <LoctoolMessage id="pages.sepa.expired.lead" />
                            </Text>

                            <Box as="p" $style={{ margin: 0 }}>
                                <LoctoolMessage id="pages.sepa.expired.description" />
                            </Box>
                        </Box>
                    </>
                )}
            </Box>

            <SepaPaymentFooter />
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
`;
