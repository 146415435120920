import {
    LegalDocumentWL,
    TransactionResult,
    DeHomeInsuranceContract,
    ModularTravelDEInsuranceContract,
    InsuranceType,
    getTransactionResult_session_accountData_cartHistory,
} from "./graphql/deWebapp/types";

export enum legalDocumentUseCase {
    accountCreation = "accountCreation",
    travelNonDomestic = "travelNonDomestic",
    claimInformation = "claimInformation",
    travelSingleTrip = "travelSingleTrip",
    travelAnnual = "travelAnnual",
    homeInsurance = "homeInsurance",
}

export type LegalDocumentWithCase = LegalDocumentWL & { useCase: legalDocumentUseCase };

export type DeHomeInsuranceContractWithInsuranceType = DeHomeInsuranceContract & { insuranceType: InsuranceType };

export type ExtendedTransactionResult = TransactionResult & { cartHistory: getTransactionResult_session_accountData_cartHistory[] };

export type GermanInsuranceContract = ModularTravelDEInsuranceContract | DeHomeInsuranceContractWithInsuranceType;
