import React from "react";
import styled, { useTheme } from "styled-components";
import { Loctool, LoctoolMessage } from "@monster/loctool";
import { bp, Box, Flex, flexItemShrinkVariant, List } from "@monster/shared";
import { Text } from "@monster/chr-ui";
import { LegalDocumentLink } from "@components/LegalDocumentLink";
import { SVGSharedIcon32BasicsArrowRight } from "@monster/shared/dist/svg_assets";
import { Helpers } from "@utils/Helpers";
import { GqlDeWebapp } from "@api/Api";

export const SepaPaymentFooter = () => {
    const theme = useTheme();
    const shadowBottom = `inset 0 -1px 0 0 ${theme.color.greyN}`;

    return (
        <StyledFooter>
            <Box $style={{ maxWidth: 1024 + 2 * 16, margin: "0 auto", padding: "32px 16px 24px" }} $styleLarge={{ paddingTop: 48, paddingBottom: 72 }}>
                <Flex.Container $style={{ columnGap: 32, flexWrap: "wrap", maxWidth: 672 }}>
                    <Flex.Item $shrink={1} $styleLarge={{ ...flexItemShrinkVariant({ $shrink: "auto" }) }}>
                        <Text
                            as="h2"
                            $variant="bodyMobile"
                            $style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", boxShadow: shadowBottom, margin: 0, padding: "16px 0" }}
                            $styleLarge={{
                                minHeight: 80,
                                position: "relative",
                                padding: 16,
                            }}
                        >
                            <LoctoolMessage id="components.sepafooter.questions" />{" "}
                            <Box as="span" $style={{ fontWeight: 400 }}>
                                <LoctoolMessage id="components.sepafooter.contactUs" />
                            </Box>
                        </Text>

                        <List>
                            <Box as="li" $style={{ boxShadow: shadowBottom }}>
                                <StyledFooterListItemInnerElement as="button" type="button" onClick={Helpers.openLivePersonChat}>
                                    <Flex.Item as="span" $shrink="auto">
                                        <LoctoolMessage id="components.sepafooter.liveChat" />
                                    </Flex.Item>

                                    <SVGSharedIcon32BasicsArrowRight />
                                </StyledFooterListItemInnerElement>
                            </Box>

                            <Box as="li" $style={{ boxShadow: shadowBottom }}>
                                <StyledFooterListItemInnerElement as="a" href={`tel:${Loctool.instance.formatMessage({ id: "components.footer.telLink" }).replace(/\s/g, "")}`}>
                                    <Flex.Item as="span" $shrink="auto">
                                        <LoctoolMessage id="components.footer.tel" />
                                    </Flex.Item>

                                    <SVGSharedIcon32BasicsArrowRight />
                                </StyledFooterListItemInnerElement>
                            </Box>

                            <Box as="li" $style={{ boxShadow: shadowBottom }}>
                                <StyledFooterListItemInnerElement as="a" href="mailto:cherrisk@cherrisk.com">
                                    <Flex.Item as="span" $shrink="auto">
                                        cherrisk@cherrisk.com
                                    </Flex.Item>

                                    <SVGSharedIcon32BasicsArrowRight />
                                </StyledFooterListItemInnerElement>
                            </Box>
                        </List>
                    </Flex.Item>

                    <Flex.Item $shrink={1} $styleLarge={{ ...flexItemShrinkVariant({ $shrink: "auto" }) }}>
                        <Text
                            as="h2"
                            $variant="bodyMobile"
                            $style={{ boxShadow: shadowBottom, margin: 0, padding: "32px 0 16px" }}
                            $styleLarge={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", minHeight: 80, padding: 16 }}
                        >
                            <LoctoolMessage id="components.sepafooter.legalDocuments" />
                        </Text>

                        <List>
                            <Box as="li" $style={{ boxShadow: shadowBottom }}>
                                <StyledFooterListItemInnerElement as={PrivacyPolicyLink}>
                                    <SVGSharedIcon32BasicsArrowRight />
                                </StyledFooterListItemInnerElement>
                            </Box>
                        </List>
                    </Flex.Item>
                </Flex.Container>
            </Box>
        </StyledFooter>
    );
};

const PrivacyPolicyLink = ({ children, className }: React.PropsWithChildren<{ className?: string }>) => (
    <LegalDocumentLink className={className} legalDocumentCategory={GqlDeWebapp.legalDocumentCategory.CHERRISK_PRIVACY_POLICY}>
        {children}
    </LegalDocumentLink>
);

const StyledFooterListItemInnerElement = styled(Flex.Container)`
    align-items: center;
    color: ${({ theme }) => theme.color.blueD};
    column-gap: 16px;
    justify-content: space-between;
    min-height: 56px;
    padding: 12px 0;
    text-decoration: none;
    width: 100%;

    ${bp.large} {
        padding: 12px 16px;
    }

    @media (hover: hover) {
        a&,
        button& {
            &:hover {
                color: ${({ theme }) => theme.color.greenN};
            }
        }
    }
`;

const StyledFooter = styled.footer`
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.color.greyD};
    margin-top: auto;
    position: relative;
    z-index: 1;
`;
