import React, { useCallback } from "react";
import { useTheme } from "styled-components";
import { Link } from "react-router-dom";
import { Box, Container, Flex } from "@monster/shared";
import { Path } from "@utils/Path";
import { SVGSharedIcon32NavLogin } from "@monster/shared/dist/svg_assets";
import { SVGChrBrandCherriskByUniqa } from "@monster/chr-ui/dist/svg_assets";
import { Button } from "@monster/chr-ui";
import { Cookie } from "@monster/shared";
import { useDispatch } from "react-redux";
import { AppStateActions } from "@redux/actions/appStateActions";
import { Loctool, LoctoolMessage } from "@monster/loctool";

export const AppBar = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const onLogoutClick = useCallback(() => {
        Cookie.authToken.remove();
        dispatch(AppStateActions.reset());
    }, [dispatch]);
    return (
        <Box $style={{ position: "sticky", top: 0, backgroundColor: theme.color.greyL, zIndex: theme.zIndex.appBar }}>
            <Container>
                <Flex.Container
                    $style={{ height: 64, justifyContent: "space-between", alignItems: "center", marginRight: -4 }}
                    $styleMedium={{ marginRight: 0 }}
                    $styleXLarge={{ paddingLeft: 16, paddingRight: 16 }}
                >
                    <Flex.Item $shrink="shrink">
                        <Link to={Path.dashboard}>
                            <SVGChrBrandCherriskByUniqa title={Loctool.instance.formatMessage({ id: "common.logoTitle" })} color={theme.color.body.textColor} />
                        </Link>
                    </Flex.Item>

                    <Flex.Item $shrink="shrink">
                        <Button.PrimaryAlt size="small" btnLabel={<LoctoolMessage id="common.logout" />} onClick={onLogoutClick} icon={<SVGSharedIcon32NavLogin />} className="hide-for-medium" />
                        <Button.Secondary
                            size="small"
                            btnLabel={<LoctoolMessage id="common.logout" />}
                            onClick={onLogoutClick}
                            icon={<SVGSharedIcon32NavLogin />}
                            iconPosition="right"
                            className="show-for-medium"
                        />
                    </Flex.Item>
                </Flex.Container>
            </Container>
        </Box>
    );
};
