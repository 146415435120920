import { gql } from "@apollo/client";

// TODO: remove unnecessary fields
export const LegalDocumentFragment = gql`
    fragment LegalDocument on LegalDocument {
        categoryId
        category
        interaction
        version
        effectiveFrom
        effectiveUntil
        created
        subcategory
        documentFileDataList {
            languageCode
            fileData {
                url
                name
                size
                extractedMetadata
                thumbnailUrl
                thumbnailName
            }
            legalDocumentFilenamePrefix
        }
    }
`;
