import { gql } from "@apollo/client";

export const GlobalQueries = Object.freeze({
    time: gql`
        query time {
            time
        }
    `,

    applications: gql`
        query applications($email: String!) {
            applications(email: $email) {
                name
                apiEndpoint
            }
        }
    `,
});
