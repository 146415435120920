import { ApolloClient, ApolloQueryResult, ApolloError, QueryOptions, MutationOptions, OperationVariables } from "apollo-client";
import { ApolloLink, NextLink, Operation } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError, ErrorResponse } from "apollo-link-error";
import { FetchResult } from "@apollo/client";
import { Loctool } from "@monster/loctool";
import { Cookie } from "@monster/shared";

export enum GQLClient {
    Global = "global",
    DEWebapp = "deWebapp",
    Selfservice = "selfservice",
}

const globalHttpLink = createHttpLink({ uri: `${process.env.REACT_APP_API_URL_GLOBAL || ""}` });
/*
const deWebappHttpLink = createHttpLink({ uri: `${process.env.REACT_APP_API_URL_DE_TRAVEL || ""}` });
const selfserviceHttpLink = createHttpLink({ uri: `${process.env.REACT_APP_API_URL_SELFSERVICE || ""}` });
*/

const errorLink = onError((_: ErrorResponse) => {
    return;
});

const headerLink = new ApolloLink((operation: Operation, forward: NextLink) => {
    const token = Cookie.authToken.get();
    // Global uri
    let uri = `${process.env.REACT_APP_API_URL_GLOBAL || ""}`;
    switch (operation.getContext().client) {
        case GQLClient.DEWebapp: {
            uri = `${process.env.REACT_APP_API_URL_DE_TRAVEL || ""}`;
            break;
        }
        case GQLClient.Selfservice: {
            uri = `${process.env.REACT_APP_API_URL_SELFSERVICE || ""}`;
            break;
        }
    }
    operation.setContext({
        headers: {
            authorization: token,
            ...operation.getContext().headers,
            "X-GQLOperation": operation.operationName,
        },
        uri,
    });

    return forward(operation);
});

const parseErrorMessageFromGraphQLError = (gqlError: string) => {
    const regex = /(\[.*\]\s*)?(.+)/;
    const result = regex.exec(gqlError);
    if (result === null || result.length !== 3 || typeof result[2] === "undefined") {
        return gqlError;
    }
    return result[2];
};

export class GraphQLClientError extends Error {
    code: number;
    intlMessage: string;
    __proto__: GraphQLClientError;

    constructor(code: number, message: string) {
        super(message);
        this.name = this.constructor.name;
        this.code = code;
        this.constructor = GraphQLClientError;
        this.__proto__ = GraphQLClientError.prototype;
        if (typeof Error.captureStackTrace === "function") {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
        this.message = message;
        this.intlMessage = Loctool.instance.formatMessage({ id: `api.errors.${message}`, defaultMessage: Loctool.instance.formatMessage({ id: "api.errors.unknown" }) });
    }
}

class GraphQLClient {
    static isFirstRequest = true;

    static client = new ApolloClient({
        /*
        link: errorLink.concat(headerLink).split(
            operation => {
                return operation.getContext().client === "global";
            },
            globalHttpLink,
            deWebappHttpLink
        ),*/
        link: errorLink.concat(headerLink).concat(globalHttpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "no-cache",
                errorPolicy: "ignore",
            },
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
            },
        },
    });

    static async mutate<R, V = {}>(options: MutationOptions<R, V>): Promise<R> {
        try {
            const response = await GraphQLClient.client.mutate<R, V>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                throw error;
            }
            throw GraphQLClient.handleErrors(error as Error);
        }
    }

    static async query<R, V extends OperationVariables = {}>(options: QueryOptions<V>): Promise<R> {
        try {
            const response: ApolloQueryResult<R> = await GraphQLClient.client.query<R>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                throw error;
            }
            throw GraphQLClient.handleErrors(error as Error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static getResult<R>(response: ApolloQueryResult<R> | FetchResult<R> | any): R {
        if (response.errors && response.errors.length > 0) {
            throw GraphQLClient.handleErrors(response.errors[0]);
        }

        return response.data;
    }

    private static handleErrors(error: Error): Error {
        if (error instanceof ApolloError && error.graphQLErrors?.length > 0) {
            return new GraphQLClientError(400, error.graphQLErrors[0].message ? parseErrorMessageFromGraphQLError(error.graphQLErrors[0].message) : "unknown");
        } else if (typeof error === "object" && error.message) {
            return new GraphQLClientError(400, parseErrorMessageFromGraphQLError(error.message));
        }
        return error;
    }
}

export default GraphQLClient;
