import { gql } from "@apollo/client";

export const SelfserviceMutations = Object.freeze({
    pay: gql`
        mutation pay {
            pay {
                isOneClickPayment
                oneClickPaymentResult {
                    transactionId
                    resultCode
                    resultMessage
                    orderId
                    providerTransactionId
                    providerName
                    userId
                    insuranceId
                }
                standardPaymentResult
                insuranceId
            }
        }
    `,
    getTransactionResult: gql`
        mutation getTransactionResult($transactionId: String!) {
            handleClientCallback(transactionId: $transactionId) {
                transactionId
                resultCode
                resultMessage
                orderId
                providerTransactionId
                providerName
                userId
                insuranceId
            }
        }
    `,
    setPaymentOption: gql`
        mutation setPaymentOption($selectedPaymentOption: SelectedPaymentOptionInput!) {
            setPaymentOption(selectedPaymentOption: $selectedPaymentOption) {
                code
            }
        }
    `,
});
