import { gql } from "@apollo/client";
import { LegalDocumentWLFragment } from "./fragments/LegalDocumentWLFragment";
import { SessionFragment } from "./fragments/SessionFragment";
import { TransactionResultFragment } from "./fragments/TransactionResultFragment";

export const DeWebappQueries = Object.freeze({
    getSession: gql`
        query getSession {
            session {
                ...Session
            }
        }
        ${SessionFragment}
    `,

    legalDocuments: gql`
        query getEffectiveLegalDocuments($at: LocalDate!) {
            accountLegalDocuments: effectiveLegalDocuments(at: $at, useCase: accountCreation, countryCode: DE) {
                ...LegalDocumentWL
            }

            travelSingleTrip: effectiveLegalDocuments(at: $at, useCase: travelSingleTrip, countryCode: DE) {
                ...LegalDocumentWL
            }

            travelAnnual: effectiveLegalDocuments(at: $at, useCase: travelAnnual, countryCode: DE) {
                ...LegalDocumentWL
            }

            homeInsurance: effectiveLegalDocuments(at: $at, useCase: homeInsurance, countryCode: DE) {
                ...LegalDocumentWL
            }
        }
        ${LegalDocumentWLFragment}
    `,

    getTransactionResult: gql`
        query getTransactionResult($transactionId: String!) {
            getTransactionResult(transactionId: $transactionId) {
                ...TransactionResult
            }
            session {
                accountData {
                    cartHistory {
                        id
                        amount
                        currency
                        selectedPaymentOption {
                            paymentMethod
                            beRecurring
                        }
                        coupons {
                            name
                        }
                    }
                }
            }
        }
        ${TransactionResultFragment}
    `,
});
