import React from "react";
import { Box, Flex, flexItemShrinkVariant } from "@monster/shared";
import { SVGSharedIcon32BasicsArrowRight } from "@monster/shared/dist/svg_assets";
import { useTheme } from "styled-components";
import { DashboardLink } from "./DashboardLink.styled";
import { Api } from "@api/Api";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { useNotification } from "@monster/chr-ui";

type Props = {
    label: React.ReactNode;
    info: React.ReactNode;
    contractId: string;
    claimId?: string | null;
};

export const ClaimLink = ({ label, info, contractId, claimId }: Props) => {
    const theme = useTheme();
    const showNotification = useNotification();
    const href = `${process.env.REACT_APP_CLAIM_APP_URL}${claimId ? `/claim/${claimId}` : "/create"}`;

    const onClick = async (event: React.MouseEvent<HTMLAnchorElement>): Promise<void> => {
        event.preventDefault();
        try {
            const url = await Api.createSessionByExternalInsuranceId({ id: contractId, claimId });
            window.location.href = `${url}&lang=de`;
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                showNotification(error.intlMessage);
            }
        }
    };

    return (
        <Flex.Container
            as="li"
            $style={{ columnGap: 16, flexWrap: "wrap", padding: "15px 64px 9px 16px", boxShadow: `0px -1px 0px 0px ${theme.color.greyD}`, position: "relative" }}
            $styleMedium={{ padding: "15px 64px 17px 16px" }}
        >
            <Flex.Item $shrink={1} $styleMedium={flexItemShrinkVariant({ $shrink: "auto" })}>
                <DashboardLink href={href} onClick={onClick}>
                    {label}
                </DashboardLink>
            </Flex.Item>
            <Flex.Item
                $shrink={1}
                $styleMedium={{
                    ...flexItemShrinkVariant({
                        $shrink: "shrink",
                    }),
                    width: "auto",
                }}
            >
                <Box $style={{ color: theme.color.blueL }} $styleMedium={{ textAlign: "right" }}>
                    {info}
                </Box>
            </Flex.Item>
            <Box $style={{ pointerEvents: "none", position: "absolute", top: 17, right: 16 }} $styleMedium={{ top: 11 }}>
                <SVGSharedIcon32BasicsArrowRight />
            </Box>
        </Flex.Container>
    );
};
