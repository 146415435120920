import { GqlDeWebapp } from "@api/Api";
import { Loctool } from "@monster/loctool";
import { DateFormat } from "@monster/shared";
import { DateUtils } from "./DateHelpers";

export enum LegalDocumentSubCategory {
    TERMS = "TERMS",
    IPIDS = "IPIDS",
    PROTOCOL_OF_ADVICE = "PROTOCOL_OF_ADVICE",
    GENERAL_CONTRACT_INFO = "GENERAL_CONTRACT_INFO",
    CONTRACT_POLICY = "CONTRACT_POLICY",
}

export class LegalDocumentUtils {
    public static isAccountLegalDocument(legalDocumentCategory: GqlDeWebapp.legalDocumentCategory): boolean {
        switch (legalDocumentCategory) {
            case GqlDeWebapp.legalDocumentCategory.CHERRISK_PRIVACY_POLICY:
            case GqlDeWebapp.legalDocumentCategory.HEALTH_DATA:
            case GqlDeWebapp.legalDocumentCategory.REGISTRATION_POLICY:
            case GqlDeWebapp.legalDocumentCategory.MARKETING_POLICY:
                // TODO: kérdezzünk rá
                // case GqlDeWebapp.legalDocumentCategory.INSURANCE_INTERMEDIARY_INFO:
                return true;
            default:
                return false;
        }
    }

    public static isActiveInsurance(status: GqlDeWebapp.InsuranceStatus | null): boolean {
        if (!status) {
            return false;
        }
        return [
            GqlDeWebapp.InsuranceStatus.PendingRiskAssessment,
            GqlDeWebapp.InsuranceStatus.WaitingForActivation,
            GqlDeWebapp.InsuranceStatus.ActiveNormal,
            GqlDeWebapp.InsuranceStatus.ActivePaused,
            GqlDeWebapp.InsuranceStatus.WaitingForDeletion,
            GqlDeWebapp.InsuranceStatus.ActiveNotFeasible,
            GqlDeWebapp.InsuranceStatus.ActiveTooRisky,
            GqlDeWebapp.InsuranceStatus.ActiveJointAgreement,
            GqlDeWebapp.InsuranceStatus.ActiveImmediateTermination,
            GqlDeWebapp.InsuranceStatus.ActiveTermination,
            GqlDeWebapp.InsuranceStatus.ActiveSubsequentPayment,
            GqlDeWebapp.InsuranceStatus.InactiveSubsequentPayment,
        ].includes(status);
    }

    public static isArchivedInsurance(status: GqlDeWebapp.InsuranceStatus | null): boolean {
        if (!status) {
            return false;
        }

        return [
            GqlDeWebapp.InsuranceStatus.InactivePaused,
            GqlDeWebapp.InsuranceStatus.InactiveUnpaid,
            GqlDeWebapp.InsuranceStatus.InactiveRiskAssessment,
            GqlDeWebapp.InsuranceStatus.InactiveDestroyed,
            GqlDeWebapp.InsuranceStatus.DeletedJointAgreement,
            GqlDeWebapp.InsuranceStatus.DeletedFinished,
            GqlDeWebapp.InsuranceStatus.DeletedCanceled,
            GqlDeWebapp.InsuranceStatus.DeletedUnpaid,
            GqlDeWebapp.InsuranceStatus.DeletedTermination,
            GqlDeWebapp.InsuranceStatus.DeletedImmediateTermination,
            GqlDeWebapp.InsuranceStatus.WaitingForReactivation,
            GqlDeWebapp.InsuranceStatus.DeletedCustomerRequest,
            GqlDeWebapp.InsuranceStatus.DeletedDestroyed,
            GqlDeWebapp.InsuranceStatus.DeletedDeath,
            GqlDeWebapp.InsuranceStatus.DeletedLossOfInterest,
            GqlDeWebapp.InsuranceStatus.DeletedNotFeasible,
            GqlDeWebapp.InsuranceStatus.DeletedSEPAchargeback,
            GqlDeWebapp.InsuranceStatus.DeletedTooRisky,
        ].includes(status);
    }

    public static isPendingPaymentInsurance(status: GqlDeWebapp.InsuranceStatus | null): boolean {
        if (!status) {
            return false;
        }

        return status === GqlDeWebapp.InsuranceStatus.PendingPayment;
    }

    public static toInterval(from: string, to?: string | Date | null, upperFirstLetter?: boolean): string {
        if (to) {
            return `${DateUtils.format(from, DateFormat.deShort)}-${DateUtils.format(to, DateFormat.deShort)}`;
        }
        const result = `${Loctool.instance.formatMessage({ id: "common.activeFrom" })} ${DateUtils.format(from, DateFormat.deShort)}`;
        if (!upperFirstLetter) {
            return result;
        }

        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    public static legalDocumentCategoryToLegalDocumentSubCategory(category: GqlDeWebapp.legalDocumentCategory): LegalDocumentSubCategory {
        switch (category) {
            case GqlDeWebapp.legalDocumentCategory.TERMS:
                return LegalDocumentSubCategory.TERMS;
            case GqlDeWebapp.legalDocumentCategory.IPID:
                return LegalDocumentSubCategory.IPIDS;
            case GqlDeWebapp.legalDocumentCategory.PROTOCOL_OF_ADVICE:
                return LegalDocumentSubCategory.PROTOCOL_OF_ADVICE;
            case GqlDeWebapp.legalDocumentCategory.GENERAL_CONTRACT_INFORMATION:
                return LegalDocumentSubCategory.GENERAL_CONTRACT_INFO;
            default:
                return category as unknown as LegalDocumentSubCategory;
        }
    }
}
