import React from "react";
import styled, { useTheme } from "styled-components";
import { Box, Flex, List } from "@monster/shared";
import { Text, Surface, Button, useTextVariant } from "@monster/chr-ui";
import { SVGSharedIcon32Umbrella, SVGSharedIcon32CoverLaw } from "@monster/shared/dist/svg_assets";
import { useSelector } from "react-redux";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { DocumentLink } from "./DocumentLink";
import { PageFrame } from "@components/PageFrame";
import { LoctoolMessage } from "@monster/loctool";
import { TransactionResultDialog } from "./TransactionResultDialog";
import { LegalDocumentSelectors } from "@redux/selectors/LegalDocumentSelectors";
import { GqlDeWebapp } from "@api/Api";
import { FileClaimSection } from "./FileClaimSection";
import { ClaimSection } from "./ClaimSection";
import { DashboardAccordion } from "./DashboardAccordion";
import { ClaimFrontendStatus } from "@utils/Helpers";
import { ContractAccordion } from "./ContractAccordion";

export const DashboardPage = () => {
    const theme = useTheme();
    const TextVariant = useTextVariant();
    const insuranceIntermediaryInfo = useSelector(LegalDocumentSelectors.getDocumentByCategory(GqlDeWebapp.legalDocumentCategory.INSURANCE_INTERMEDIARY_INFO));
    const legalDocuments = useSelector(SessionSelectors.legalDocuments);
    const contracts = useSelector(SessionSelectors.contractDocuments);
    const allContracts = useSelector(SessionSelectors.getAllContracts());
    const claimableContracts = useSelector(SessionSelectors.getClaimableContracts);
    const inProgressClaims = useSelector(SessionSelectors.getClaims([ClaimFrontendStatus.filed, ClaimFrontendStatus.in_progress, ClaimFrontendStatus.waiting_client_task]));
    const archivedClaims = useSelector(SessionSelectors.getClaims([ClaimFrontendStatus.closed])).filter(claim => claim.claimStatus !== GqlDeWebapp.ClaimStatus.closed_draft_timeout);

    const activeContractIds = Array.from(new Set(contracts.active.map(c => c.contractId)));
    const activeContracts = allContracts.filter(c => activeContractIds.includes(c.id ?? ""));

    const archiveContractIds = Array.from(new Set(contracts.archive.map(c => c.contractId)));
    const archiveContracts = allContracts.filter(c => archiveContractIds.includes(c.id ?? ""));

    return (
        <PageFrame>
            <TransactionResultDialog />
            {contracts.active.length === 0 && contracts.hasPendingContractGeneration === false ? (
                <Box $style={{ marginTop: 40, textAlign: "center" }}>
                    <HelperBox>
                        <HelperBoxImage />
                        <Text as="p" $variant="bodyMobile" $style={{ margin: 0, alignSelf: "center", textAlign: "left" }}>
                            <LoctoolMessage id="pages.dashboard.noActiveContract" />
                        </Text>
                    </HelperBox>
                </Box>
            ) : (
                <>
                    {claimableContracts.length > 0 && <FileClaimSection claimableContracts={claimableContracts} />}

                    {inProgressClaims.length > 0 && <ClaimSection label={<LoctoolMessage id="pages.dashboard.claimsInProgress" />} claims={inProgressClaims} />}

                    {archivedClaims.length > 0 && (
                        <DashboardAccordion label={<LoctoolMessage id="pages.dashboard.archiveClaims" />}>
                            <ClaimSection claims={archivedClaims} />
                        </DashboardAccordion>
                    )}

                    <Text as="h2" $variant="h3Mobile" $style={{ margin: "40px 16px 16px", fontWeight: 400 }} $styleMedium={{ ...TextVariant({ $variant: "h2Desktop" }), margin: "12px 16px 20px" }}>
                        <LoctoolMessage id="pages.dashboard.activeDocuments" />
                    </Text>

                    {contracts.hasPendingContractGeneration && (
                        <Box $style={{ textAlign: "center" }}>
                            <HelperBox>
                                <HelperBoxImage />
                                <Text as="p" $variant="bodyMobile" $style={{ margin: 0, alignSelf: "center", textAlign: "left" }}>
                                    <LoctoolMessage id="pages.dashboard.pendingContractGeneration" />
                                </Text>
                            </HelperBox>
                            <Box $style={{ marginTop: 16, marginBottom: 40 }}>
                                <Button.Primary
                                    btnLabel={<LoctoolMessage id="pages.dashboard.pendingContractGeneration.refreshButton" />}
                                    onClick={() => {
                                        document.location.reload();
                                    }}
                                />
                            </Box>
                        </Box>
                    )}

                    <Surface $variant="greyBorder" $style={{ backgroundColor: theme.color.white }}>
                        <Flex.Container as="h3" $style={{ columnGap: 16, margin: 0, padding: "12px 16px" }}>
                            <Flex.Item as="span" $shrink="shrink">
                                <SVGSharedIcon32Umbrella />
                            </Flex.Item>
                            <Flex.Item as="span" $shrink="auto" $style={{ alignSelf: "center" }}>
                                <Text as="span" $variant="bodyMobile">
                                    <LoctoolMessage id="pages.dashboard.insurances" />
                                </Text>
                            </Flex.Item>
                        </Flex.Container>
                        <List $cssReset>
                            {activeContracts.map((insurance, index) => {
                                return (
                                    <ContractAccordion key={index} insurance={insurance}>
                                        <List $cssReset style={{ backgroundColor: theme.color.neutral200 }}>
                                            {contracts.active
                                                .filter(c => c.contractId === insurance.id)
                                                .map((insurance, index) => (
                                                    <DocumentLink key={index} contractDocument={insurance} />
                                                ))}
                                        </List>
                                    </ContractAccordion>
                                );
                            })}
                        </List>
                    </Surface>
                </>
            )}

            {legalDocuments.length > 0 && (
                <Box $style={{ marginTop: 16 }}>
                    <Surface $variant="greyBorder" $style={{ backgroundColor: theme.color.white }}>
                        <Flex.Container as="h3" $style={{ columnGap: 16, margin: 0, padding: "12px 16px" }}>
                            <Flex.Item as="span" $shrink="shrink">
                                <SVGSharedIcon32CoverLaw />
                            </Flex.Item>
                            <Flex.Item as="span" $shrink="auto" $style={{ alignSelf: "center" }}>
                                <Text as="span" $variant="bodyMobile">
                                    <LoctoolMessage id="pages.dashboard.legalDocuments" />
                                </Text>
                            </Flex.Item>
                        </Flex.Container>
                        <List $cssReset>
                            {legalDocuments.map((legal, index) => (
                                <DocumentLink key={index} legalDocument={legal} isExternal={true} />
                            ))}
                            {insuranceIntermediaryInfo && <DocumentLink legalDocument={insuranceIntermediaryInfo} isExternal={true} />}
                        </List>
                    </Surface>
                </Box>
            )}

            {contracts.archive.length > 0 && (
                <DashboardAccordion label={<LoctoolMessage id="pages.dashboard.archives" />}>
                    <Surface $variant="greyBorder" $style={{ backgroundColor: theme.color.white }}>
                        <Flex.Container as="h4" $style={{ columnGap: 16, margin: 0, padding: "12px 16px" }}>
                            <Flex.Item as="span" $shrink="shrink">
                                <SVGSharedIcon32Umbrella />
                            </Flex.Item>
                            <Flex.Item as="span" $shrink="auto" $style={{ alignSelf: "center" }}>
                                <Text as="span" $variant="bodyMobile">
                                    <LoctoolMessage id="pages.dashboard.insurances" />
                                </Text>
                            </Flex.Item>
                        </Flex.Container>
                        <List $cssReset>
                            {archiveContracts.map((insurance, index) => {
                                return (
                                    <ContractAccordion key={index} insurance={insurance}>
                                        <List $cssReset style={{ backgroundColor: theme.color.neutral200 }}>
                                            {contracts.archive
                                                .filter(c => c.contractId === insurance.id)
                                                .map((insurance, index) => (
                                                    <DocumentLink key={index} contractDocument={insurance} />
                                                ))}
                                        </List>
                                    </ContractAccordion>
                                );
                            })}
                        </List>
                    </Surface>
                </DashboardAccordion>
            )}
        </PageFrame>
    );
};

const HelperBox = styled.div`
    background-color: ${({ theme }) => theme.color.greyN};
    border: 1px solid ${({ theme }) => theme.color.greyD};
    border-radius: 8px;
    padding: 12px 16px;
    display: inline-flex;
    gap: 16px;
`;

const HelperBoxImage = styled(Box)`
    min-width: 32px;
    width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.color.greyN};
    background-image: image-set(url("/images/emma-question.png") 1x, url("/images/emma-question@2x.png") 2x, url("/images/emma-question@3x.png") 3x);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% auto;
`;
