import { GqlDeWebapp } from "@api/Api";
import { legalDocumentUseCase } from "@api/CustomGqlTypes";
import { ApplicationState } from "@redux/Reducers";

export class LegalDocumentSelectors {
    public static getDocumentByCategory(category: GqlDeWebapp.legalDocumentCategory) {
        return (state: ApplicationState): GqlDeWebapp.LegalDocumentWL | null => {
            const useCase = (() => {
                switch (category) {
                    case GqlDeWebapp.legalDocumentCategory.CHERRISK_PRIVACY_POLICY:
                    case GqlDeWebapp.legalDocumentCategory.HEALTH_DATA:
                    case GqlDeWebapp.legalDocumentCategory.REGISTRATION_POLICY:
                    case GqlDeWebapp.legalDocumentCategory.MARKETING_POLICY:
                    case GqlDeWebapp.legalDocumentCategory.INSURANCE_INTERMEDIARY_INFO:
                        return legalDocumentUseCase.accountCreation;
                    default:
                        console.warn("useCase not determined! Please check LegalDocumentSelectors.getDocumentByCategory");
                        return null;
                }
            })();
            if (useCase) {
                const docWithUseCase = state.legalDocuments.data.find(document => document.category === category && document.useCase === useCase);
                if (!docWithUseCase) {
                    console.log(
                        `Document for category ${category} not found with useCase ${useCase}`,
                        "Available document categories: ",
                        new Set(state.legalDocuments.data.filter(d => d.useCase === useCase).map(d => d.category))
                    );
                }
                return docWithUseCase ?? null;
            }

            const docWithoutUseCase = state.legalDocuments.data.find(document => document.category === category);
            if (!docWithoutUseCase) {
                console.warn(`Document for category ${category} is missing!`, "Available document categories: ", new Set(state.legalDocuments.data.map(d => d.category)));
            }
            return docWithoutUseCase ?? null;
        };
    }
}
