import { gql } from "@apollo/client";
import { InsuranceDocFragment } from "./InsuranceDocFragment";
import { LegalDocumentFragment } from "./LegalDocumentFragment";

export const DeHomeInsuranceContractFragment = gql`
    fragment DeHomeInsuranceContract on DeHomeInsuranceContract {
        id
        serialNumber
        status
        activeSince
        buildingType
        subjectZipCode
        feeMonthly
        paymentPeriodMonths
        documents {
            ...InsuranceDoc
        }
        legalDocuments {
            current {
                ...LegalDocument
            }
        }
        cartInsurances {
            cartExtId
        }
        wlLegalDocumentRelations {
            legalDocument {
                category
                version
                effectiveFrom
                effectiveUntil
                name
                url
            }
        }
        ops
    }
    ${LegalDocumentFragment}
    ${InsuranceDocFragment}
`;
