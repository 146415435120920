import { LegalDocumentWithCase } from "@api/CustomGqlTypes";
import { ActionsUnion, ReduxHelpers } from "@monster/shared";

export enum LegalDocumentActionTypes {
    UPDATE_LEGAL_DOCUMENTS = "LegalDocumentAction/UPDATE_LEGAL_DOCUMENTS",
}

export const LegalDocumentActions = {
    update: (effectiveFrom: string, legalDocuments: LegalDocumentWithCase[]) => ReduxHelpers.createAction(LegalDocumentActionTypes.UPDATE_LEGAL_DOCUMENTS, { legalDocuments, effectiveFrom }),
};

export type LegalDocumentActions = ActionsUnion<typeof LegalDocumentActions>;
