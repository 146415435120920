import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import { Box, hexToRGBA } from "@monster/shared";
import { Button, Text, useTextVariant, TextField, useNotification } from "@monster/chr-ui";
import { SVGChrBrandCherriskByUniqa, SVGChrIcon32BasicsNotify } from "@monster/chr-ui/dist/svg_assets";
import { Api } from "@api/Api";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { Loctool, LoctoolMessage, LoctoolHTMLMessage } from "@monster/loctool";
import { Validator } from "@utils/Validator";

type Props = {
    onSubmit: (email: string) => void;
};

type Inputs = {
    email: string;
};

export const EmailAddressForm: React.FC<Props> = props => {
    const theme = useTheme();
    const TextVariant = useTextVariant();
    const showNotification = useNotification();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<Inputs>({ defaultValues: { email: "" } });
    const [showTemporaryPlatformInfo, setShowTemporaryPlatformInfo] = useState<boolean>(false);

    const validateEmail = (email: string): string | boolean => {
        showTemporaryPlatformInfo && setShowTemporaryPlatformInfo(false);
        const emailError = Validator.email(email);
        return emailError ? `validation.email.${emailError}` : true;
    };

    const onSubmit = async ({ email }: Inputs): Promise<void> => {
        try {
            const result = await Api.applications(email);
            if (result.length === 0) {
                setError("email", { type: "custom", message: "pages.login.emailAddress.form.email.error" });
                setShowTemporaryPlatformInfo(true);
                return;
            }
            if (result.length > 1) {
                console.log("TODO: more than 1 channel found");
            }

            await Api.sendValidationEmail(email);
            props.onSubmit(email);
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                showNotification(error.intlMessage);
            }
        }
    };

    return (
        <Box $style={{ padding: "0 16px", height: "100%", display: "flex", flexDirection: "column" }} $styleMedium={{ width: 440 }}>
            <Box $style={{ paddingTop: 32 }} $styleMedium={{ paddingTop: 48 }}>
                <SVGChrBrandCherriskByUniqa title={Loctool.instance.formatMessage({ id: "common.logoTitle" })} color={theme.color.body.textColor} />
            </Box>
            <Box $style={{ paddingTop: 40 }} $styleXXLarge={{ marginTop: "auto" }}>
                <Text as="h1" $variant="h3Mobile" $style={{ margin: 0 }} $styleLarge={TextVariant({ $variant: "h2Desktop" })}>
                    <LoctoolMessage id="pages.login.emailAddress.h1" />
                </Text>
            </Box>

            <Text as="p" $variant="bodyMobile" $style={{ margin: "16px 0 24px" }} $styleLarge={{ margin: "16px 0 40px" }}>
                <LoctoolMessage id="pages.login.emailAddress.description" />
            </Text>

            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    id="email"
                    label={<LoctoolMessage id="pages.login.emailAddress.form.email.label" />}
                    placeholder={Loctool.instance.formatMessage({ id: "pages.login.emailAddress.form.email.placeholder" })}
                    errorText={errors.email?.message ? <LoctoolMessage id={errors.email.message} /> : null}
                    {...register("email", { validate: validateEmail })}
                />
                {showTemporaryPlatformInfo && <TemporaryPlatformInfo />}
                <Box $style={{ maxWidth: 214, padding: "16px 0 32px", margin: "0 auto" }} $styleLarge={{ maxWidth: 178 }}>
                    <Button.Primary type="submit" btnLabel={<LoctoolMessage id="pages.login.emailAddress.form.submit" />} isExpanded disabled={!!errors.email?.message} />
                </Box>
            </form>
            <Box></Box>
            <Text as="p" $variant="bodyMobile" $style={{ margin: "auto 0 24px", textAlign: "center", paddingTop: 16 }}>
                <LoctoolMessage id="pages.login.emailAddress.temporaryMessage" />
            </Text>
            <Box $style={{ paddingBottom: 32, textAlign: "center" }}>
                <Button.Secondary btnLabel={<LoctoolMessage id="pages.login.emailAddress.temporaryLogin" />} href={`${process.env.REACT_APP_TEMPORARY_LOGIN_PATH}/temporary?l=de`} target="_blank" />
            </Box>
        </Box>
    );
};

const TemporaryPlatformInfo = () => {
    const theme = useTheme();
    return (
        <Box $style={{ marginBottom: 16 }}>
            <Box
                $style={{
                    position: "relative",
                    border: `1px solid ${theme.color.yellowD}`,
                    borderRadius: 8,
                    padding: "15px 15px 15px 63px",
                    backgroundColor: hexToRGBA(theme.color.yellowN, 0.5),
                }}
            >
                <LoctoolHTMLMessage id="pages.login.temporaryPlatformInfo.text" />

                <Box $style={{ position: "absolute", top: 11, left: 15 }}>
                    <SVGChrIcon32BasicsNotify />
                </Box>
            </Box>
        </Box>
    );
};
