import { GqlDeWebapp } from "@api/Api";
import { AppStateActionTypes } from "@redux/actions/appStateActions";
import { ReduxAction } from "@redux/actions/ReduxAction";
import { SessionActionTypes } from "@redux/actions/sessionActions";
import { Cookie } from "@monster/shared";
import { DeHomeInsuranceContractWithInsuranceType } from "@api/CustomGqlTypes";

export type SessionReducerType = (Omit<GqlDeWebapp.Session, "germanHomeInsuranceContracts"> & { germanHomeInsuranceContracts: DeHomeInsuranceContractWithInsuranceType[] }) | null;

const initialState: SessionReducerType = null;

export default function sessionReducer(state: SessionReducerType = initialState, action: ReduxAction): SessionReducerType {
    switch (action.type) {
        case SessionActionTypes.UPDATE: {
            if (action.payload.session) {
                Cookie.authToken.set(action.payload.session.sessionId);
            } else {
                Cookie.authToken.remove();
            }

            if (!action.payload.session) {
                return null;
            }

            const germanTravelInsuranceContracts = action.payload.session?.germanTravelInsuranceContracts ?? [];
            const germanHomeInsuranceContracts: DeHomeInsuranceContractWithInsuranceType[] = (action.payload.session?.germanHomeInsuranceContracts ?? []).map(contract => ({
                ...contract,
                insuranceType: GqlDeWebapp.InsuranceType.HOME,
            }));

            return { ...action.payload.session, germanTravelInsuranceContracts, germanHomeInsuranceContracts };
        }
        case AppStateActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
}
