import sessionStorage from "redux-persist/es/storage/session";
import { persistCombineReducers } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import { ReduxAction } from "./actions/ReduxAction";
import sessionReducer, { SessionReducerType } from "./reducers/sessionReducer";
import legalDocumentReducer, { LegalDocumentReducerType } from "./reducers/legalDocumentReducer";

export interface ApplicationState {
    session: SessionReducerType;
    legalDocuments: LegalDocumentReducerType;
}

const config: PersistConfig<ApplicationState> = {
    key: "chr-dashboard-app",
    storage: sessionStorage,
    debug: process.env.NODE_ENV === "development",
    whitelist: [],
};

const rootReducer = persistCombineReducers<ApplicationState, ReduxAction>(config, {
    session: sessionReducer,
    legalDocuments: legalDocumentReducer,
});

export default rootReducer;
