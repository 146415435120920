import { PageLoader as ChrPageLoader } from "@monster/chr-ui";
import { LoctoolMessage } from "@monster/loctool";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const PageLoader = () => {
    const location = useLocation();
    const isTransactionResult = useMemo(() => location.search.includes("TransactionId"), [location]);
    return (
        <ChrPageLoader
            variant="general"
            title={<LoctoolMessage id={`components.pageLoader${isTransactionResult ? ".transactionResult" : ""}.title`} />}
            subTitle={<LoctoolMessage id={`components.pageLoader${isTransactionResult ? ".transactionResult" : ""}.subTitle`} />}
        />
    );
};
