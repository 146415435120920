import React, { useMemo } from "react";
import { Box, Flex, flexItemShrinkVariant } from "@monster/shared";
import { SVGSharedIcon32BasicsArrowRight } from "@monster/shared/dist/svg_assets";
import { useTheme } from "styled-components";
import { ContractDocument } from "@redux/selectors/SessionSelectors";
import { Path } from "@utils/Path";
import { Link } from "react-router-dom";
import { LegalDocumentWL } from "@api/graphql/deWebapp/types";
import { Helpers } from "@utils/Helpers";
import { LegalDocumentSubCategory, LegalDocumentUtils } from "@utils/LegalDocumentUtils";
import { LoctoolMessage } from "@monster/loctool";
import { DashboardLink } from "./DashboardLink.styled";
import { DateInputParser, DateUtils } from "@utils/DateHelpers";

type ExternalLinkProps = {
    isExternal: true;
    legalDocument: LegalDocumentWL;
};

type InternalLinkProps = {
    isExternal?: false;
    contractDocument: ContractDocument;
};

type Props = InternalLinkProps | ExternalLinkProps;

export const DocumentLink = (props: Props) => {
    const theme = useTheme();
    const effective = useMemo(() => {
        if (props.isExternal) {
            return LegalDocumentUtils.toInterval(props.legalDocument.effectiveFrom, props.legalDocument.effectiveUntil);
        }
        if (props.contractDocument.versionInfo) {
            const financedUntil = DateInputParser.offsetDateToLocalDate(props.contractDocument.versionInfo.financedUntil);
            let financedUntilDate = undefined;
            if (financedUntil) {
                try {
                    financedUntilDate = DateUtils.parse(financedUntil);
                    financedUntilDate = DateUtils.subDays(financedUntilDate, 1);
                } catch (e) {}
            }
            return LegalDocumentUtils.toInterval(DateInputParser.offsetDateToLocalDate(props.contractDocument.versionInfo.financedFrom), financedUntilDate);
        }
        return LegalDocumentUtils.toInterval(props.contractDocument.activeSince, props.contractDocument.activeUntil);
    }, [props]);

    if (props.isExternal && !props.legalDocument.url) {
        return null;
    }

    return (
        <Flex.Container
            as="li"
            $style={{ columnGap: 16, flexWrap: "wrap", padding: "15px 64px 9px 16px", boxShadow: `0px -1px 0px 0px ${theme.color.greyD}`, position: "relative" }}
            $styleMedium={{ padding: "15px 64px 17px 16px" }}
        >
            <Flex.Item
                $shrink={1}
                $styleMedium={{
                    ...flexItemShrinkVariant({
                        $shrink: "auto",
                    }),
                }}
            >
                {props.isExternal ? (
                    <DashboardLink href={Helpers.getLegalDocumentPdfLink(props.legalDocument)} target="_blank">
                        <LoctoolMessage id={`enums.legalDocumentCategory.${props.legalDocument.category}`} />
                    </DashboardLink>
                ) : (
                    <DashboardLink
                        as={Link}
                        to={Path.contractDocument(
                            props.contractDocument.contractId,
                            props.contractDocument.subCategory,
                            props.contractDocument?.serialNumber,
                            props.contractDocument?.versionInfo?.version
                        )}
                    >
                        <LoctoolMessage id={`enums.insuranceType.${props.contractDocument.insuranceType}`} />{" "}
                        <LoctoolMessage id={`enums.legalDocumentSubCategory.${props.contractDocument.subCategory ?? LegalDocumentSubCategory.CONTRACT_POLICY}`} />
                    </DashboardLink>
                )}
            </Flex.Item>
            <Flex.Item
                $shrink={1}
                $styleMedium={{
                    ...flexItemShrinkVariant({
                        $shrink: "shrink",
                    }),
                    width: "auto",
                }}
            >
                <Box $style={{ color: theme.color.blueL }} $styleMedium={{ textAlign: "right" }}>
                    {effective}
                </Box>
            </Flex.Item>
            <Box $style={{ pointerEvents: "none", position: "absolute", top: 17, right: 16 }} $styleMedium={{ top: 11 }}>
                <SVGSharedIcon32BasicsArrowRight />
            </Box>
        </Flex.Container>
    );
};
