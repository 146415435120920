import { gql } from "@apollo/client";

// TODO: remove unncessary fields
export const InsuranceDocFragment = gql`
    fragment InsuranceDoc on InsuranceDoc {
        serialNumber
        insuranceDocType
        name
        started
        created
        deleted
        insuranceVersion {
            version
            financedFrom
            financedUntil
        }
        fileURL
        fileData {
            url
            name
            size
            extractedMetadata
            thumbnailUrl
            thumbnailName
        }
    }
`;
