import { BackendEnvironments } from "./BackendEnvironments";

export enum ValidatorMessage {
    required = "required",
    invalidEmail = "invalidEmail",
}

export class Validator {
    public static required<T>(value: T): ValidatorMessage | undefined {
        if (value === null || typeof value === "undefined" || (typeof value === "string" && value === "")) {
            return ValidatorMessage.required;
        }
        return undefined;
    }

    public static email(email: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(email);
        if (requiredError) {
            return requiredError;
        }
        if (!email.match(BackendEnvironments.validatorEmail())) {
            return ValidatorMessage.invalidEmail;
        }
        return undefined;
    }
}
