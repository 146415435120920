import React from "react";
import { LoctoolMessage } from "@monster/loctool";
import { Helpers } from "@utils/Helpers";
import { Flex } from "@monster/shared";
import { useSelector } from "react-redux";
import { LegalDocumentSelectors } from "@redux/selectors/LegalDocumentSelectors";
import { legalDocumentCategory } from "@api/graphql/deWebapp/types";

type Props = React.PropsWithChildren<{ className?: string; legalDocumentCategory: legalDocumentCategory }>;

export const LegalDocumentLink = ({ className, legalDocumentCategory, children }: Props) => {
    const doc = useSelector(LegalDocumentSelectors.getDocumentByCategory(legalDocumentCategory));
    return (
        <a className={className} target="_blank" href={Helpers.getLegalDocumentPdfLink(doc)} rel="noreferrer">
            <Flex.Item as="span" $shrink="auto">
                <LoctoolMessage id={`enums.legalDocumentCategory.${legalDocumentCategory}`} />
            </Flex.Item>
            {children}
        </a>
    );
};
