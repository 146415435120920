import { Text, useTextVariant } from "@monster/chr-ui";
import { LoctoolHTMLMessage } from "@monster/loctool";
import { Box, Container, Flex, flexItemShrinkVariant } from "@monster/shared";
import { ApplicationState } from "@redux/Reducers";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { AppBar } from "./AppBar";
import { ContactUs } from "./ContactUs";

type Props = {};

export const PageFrame = ({ children }: React.PropsWithChildren<Props>) => {
    const session = useSelector((state: ApplicationState) => state.session);
    const TextVariant = useTextVariant();
    return (
        <>
            <AppBar />
            <Box $style={{ paddingBottom: 64 }} $styleLarge={{ paddingBottom: 150 }}>
                <Container>
                    <Text as="h1" $variant="h2Mobile" $style={{ margin: "12px 0 20px" }} $styleMedium={{ ...TextVariant({ $variant: "h1Desktop" }), margin: "28px 16px" }}>
                        <LoctoolHTMLMessage id="components.pageFrame.h1" values={{ name: session?.accountData?.name?.first }} />
                    </Text>
                    <Separator className="hide-for-medium" />
                    <Flex.Container $style={{ flexWrap: "wrap" }} $styleLarge={{ flexWrap: "nowrap", gap: 48 }}>
                        <Flex.Item $shrink={1} $styleLarge={{ ...flexItemShrinkVariant({ $shrink: 634 }) }}>
                            {children}
                        </Flex.Item>
                        <ContactUs />
                    </Flex.Container>
                </Container>
            </Box>
        </>
    );
};

const Separator = styled.div`
    margin-inline: -16px;
    border-bottom: 1px solid ${({ theme }) => theme.color.greyD};
`;
