import React, { useMemo } from "react";
import { useTheme } from "styled-components";
import { Box, Flex } from "@monster/shared";
import { Text, Surface, Button, useNotification, useTextVariant } from "@monster/chr-ui";
import { SVGSharedIcon32BasicsArrowLeft, SVGSharedIcon32ProductsTravel } from "@monster/shared/dist/svg_assets";
import { useSelector } from "react-redux";
import { ApplicationState } from "@redux/Reducers";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Path } from "@utils/Path";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { Helpers } from "@utils/Helpers";
import { Api, GqlDeWebapp } from "@api/Api";
import { LegalDocumentSubCategory, LegalDocumentUtils } from "@utils/LegalDocumentUtils";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { Loctool, LoctoolHTMLMessage, LoctoolMessage } from "@monster/loctool";
import { PageFrame } from "@components/PageFrame";

export const ContractDocumentPage = () => {
    const theme = useTheme();
    const history = useHistory();
    const TextVariant = useTextVariant();
    const showNotification = useNotification();
    const { contractId, subCategory, serialNumber, version } = useParams<{ contractId: string; subCategory: string; serialNumber?: string; version?: string }>();
    const contract = useSelector(SessionSelectors.getContract(contractId));
    const session = useSelector((state: ApplicationState) => state.session);
    const currentDocument = useMemo(() => {
        if (subCategory === LegalDocumentSubCategory.CONTRACT_POLICY) {
            if (serialNumber) {
                if (typeof version !== "undefined") {
                    return (contract?.documents ?? []).find(d => !d.deleted && `${d.serialNumber}` === serialNumber && `${d.insuranceVersion?.version}` === version) ?? null;
                }
                return Helpers.getSortedContractDocs(contract?.documents ?? []).find(d => `${d.serialNumber}` === serialNumber) ?? null;
            }
            return (contract?.documents ?? [])[0] ?? null;
        }
        if (contract?.insuranceType === GqlDeWebapp.InsuranceType.HOME || contract?.insuranceType === GqlDeWebapp.InsuranceType.DE_HOME_PACKAGES) {
            return (contract as GqlDeWebapp.DeHomeInsuranceContract).wlLegalDocumentRelations
                ?.map(r => r.legalDocument)
                .find(doc => LegalDocumentUtils.legalDocumentCategoryToLegalDocumentSubCategory(doc.category) === subCategory);
        }
        console.dir(contract);
        if (contract?.insuranceType === GqlDeWebapp.InsuranceType.DE_TRAVEL_MOD_A || contract?.insuranceType === GqlDeWebapp.InsuranceType.DE_TRAVEL_MOD_S) {
            return (contract as GqlDeWebapp.ModularTravelDEInsuranceContract).wlLegalDocumentRelations?.map(r => r.legalDocument).find(doc => doc.category === subCategory);
        }
        return contract?.legalDocuments.current.find(doc => doc.subcategory === subCategory);
    }, [subCategory, contract, serialNumber, version]);

    console.dir(currentDocument);

    const documentUrl = useMemo(() => {
        if (!currentDocument) {
            console.warn("Doc url is missing!", currentDocument);
            return "";
        }
        if (currentDocument.__typename === "InsuranceDoc") {
            return currentDocument.fileURL;
        }
        if (currentDocument.__typename === "LegalDocumentWL") {
            return currentDocument.url;
        }
        if (currentDocument.documentFileDataList) {
            return currentDocument.documentFileDataList[0].fileData?.url;
        }
        console.warn("currentDocument.documentFileDataList is missing!", currentDocument);
        return "";
    }, [currentDocument]);

    const documentSubCategory = useMemo(() => {
        if (currentDocument?.__typename === "LegalDocument") {
            return currentDocument?.subcategory;
        }
        if (currentDocument?.__typename === "LegalDocumentWL") {
            return LegalDocumentUtils.legalDocumentCategoryToLegalDocumentSubCategory(currentDocument.category);
        }
        return LegalDocumentSubCategory.CONTRACT_POLICY;
    }, [currentDocument]);

    const effective = useMemo(() => {
        if (currentDocument?.__typename === "InsuranceDoc") {
            if (!contract) {
                return "";
            }
            if (contract.__typename === "ModularTravelDEInsuranceContract") {
                return LegalDocumentUtils.toInterval(contract.activeSince, contract.activeUntil, true);
            }
            // NOTE: de home contracts don't have activeUntil field yet
            return LegalDocumentUtils.toInterval(contract.activeSince, undefined, true);
        }

        if (!currentDocument) {
            return null;
        }
        return LegalDocumentUtils.toInterval(currentDocument.effectiveFrom, currentDocument.effectiveUntil, true);
    }, [contract, currentDocument]);

    const onSendClick = async (): Promise<void> => {
        try {
            const url = new URL(`${process.env.REACT_APP_DOCUMENT_URL_BASE}${documentUrl ?? ""}`).pathname;
            await Api.sendDocumentEmail(contract?.id ?? "", url.substring(url.lastIndexOf("/") + 1));
            showNotification(Loctool.instance.formatMessage({ id: "pages.contractDocument.emailSent" }), "success");
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                showNotification(error.intlMessage);
            }
        }
    };
    if (!contract) {
        return <Redirect to={Path.dashboard} />;
    }

    return (
        <PageFrame>
            <Text as="h2" $variant="h3Mobile" $style={{ margin: "40px 16px 16px", fontWeight: 400 }} $styleMedium={{ ...TextVariant({ $variant: "h2Desktop" }), margin: "12px 16px 20px" }}>
                <Box as="span" $style={{ hyphens: "auto" }}>
                    <LoctoolMessage id={`enums.insuranceType.${contract.insuranceType}`} />
                </Box>{" "}
                <LoctoolMessage id={`enums.legalDocumentSubCategory.${documentSubCategory}`} />
            </Text>
            <Surface $variant="greyBorder" $style={{ backgroundColor: theme.color.white, marginBottom: 16 }}>
                <Flex.Container $style={{ gap: 16, alignItems: "center", padding: "12px 16px" }}>
                    <SVGSharedIcon32ProductsTravel />
                    <Text $variant="bodyMobile" $style={{ fontWeight: 700 }}>
                        {effective}
                    </Text>
                </Flex.Container>
                <Box $style={{ padding: 16, borderTop: `1px solid ${theme.color.greyD}`, borderBottom: `1px solid ${theme.color.greyD}` }}>
                    <Text as="p" $variant="bodyMobile" $style={{ margin: 0 }}>
                        <LoctoolMessage id="pages.contractDocument.message" />
                    </Text>
                </Box>
                <Box $style={{ padding: "32px 16px" }}>
                    <Box $style={{ width: 242, margin: "0 auto 0px" }}>
                        <Button.Primary
                            btnLabel={<LoctoolMessage id="pages.contractDocument.download" />}
                            isExpanded
                            download
                            target="_blank"
                            href={Helpers.getLegalDocumentPdfLink({ category: subCategory, url: documentUrl })}
                        />
                    </Box>
                    <Box $style={{ width: 242, margin: "16px auto" }}>
                        <Button.Secondary btnLabel={<LoctoolMessage id="pages.contractDocument.sendEmail" />} isExpanded onClick={onSendClick} />
                    </Box>
                    <Text $variant="bodyMobile" $style={{ textAlign: "center", color: theme.color.blueL }}>
                        <LoctoolHTMLMessage id="pages.contractDocument.sendEmailDescription" values={{ email: session?.accountData?.email ?? "" }} />
                    </Text>
                </Box>
            </Surface>
            <Box $style={{ paddingBottom: 16, textAlign: "center" }}>
                <Button.Text
                    btnLabel={<LoctoolMessage id="pages.contractDocument.back" />}
                    icon={<SVGSharedIcon32BasicsArrowLeft />}
                    iconPosition="left"
                    href={Path.dashboard}
                    onClick={e => {
                        e.preventDefault();
                        history.push(Path.dashboard);
                    }}
                />
            </Box>
        </PageFrame>
    );
};
