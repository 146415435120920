import React from "react";
import { Surface, Text, useTextVariant } from "@monster/chr-ui";
import { LoctoolMessage } from "@monster/loctool";
import { Flex, List } from "@monster/shared";
import { SVGSharedIcon32Umbrella } from "@monster/shared/dist/svg_assets";
import { useTheme } from "styled-components";
import { GqlDeWebapp } from "@api/Api";
import { ClaimLink } from "./ClaimLink";

type Props = {
    label?: React.ReactNode;
    claims: GqlDeWebapp.Session_accountData_claims[];
};

export const ClaimSection = ({ label, claims }: Props) => {
    const theme = useTheme();
    const TextVariant = useTextVariant();

    return (
        <>
            {label && (
                <Text as="h2" $variant="h3Mobile" $style={{ margin: "40px 16px 16px", fontWeight: 400 }} $styleMedium={{ ...TextVariant({ $variant: "h2Desktop" }), margin: "12px 16px 20px" }}>
                    {label}
                </Text>
            )}
            <Surface $variant="greyBorder" $style={{ backgroundColor: theme.color.white }}>
                <Flex.Container as="h3" $style={{ columnGap: 16, margin: 0, padding: "12px 16px" }}>
                    <Flex.Item as="span" $shrink="shrink">
                        <SVGSharedIcon32Umbrella />
                    </Flex.Item>
                    <Flex.Item as="span" $shrink="auto" $style={{ alignSelf: "center" }}>
                        <Text as="span" $variant="bodyMobile">
                            <LoctoolMessage id="pages.dashboard.claim" />
                        </Text>
                    </Flex.Item>
                </Flex.Container>
                <List $cssReset>
                    {claims.map(claim => {
                        return (
                            <ClaimLink
                                key={claim.id}
                                label={<LoctoolMessage id={`${claim.claimCause?.translationKey}`} defaultMessage={"Unknown"} />}
                                info={claim.id}
                                claimId={claim.id}
                                contractId={claim.contract!.id!}
                            />
                        );
                    })}
                </List>
            </Surface>
        </>
    );
};
