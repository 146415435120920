import { gql } from "@apollo/client";
import { SessionFragment } from "./fragments/SessionFragment";
import { VoidTypeFragment } from "./fragments/VoidTypeFragment";

export const DeWebappMutations = Object.freeze({
    startSession: gql`
        mutation startSession {
            startSession {
                ...Session
            }
        }
        ${SessionFragment}
    `,

    sendValidationEmail: gql`
        mutation sendValidationEmail($email: String!) {
            sendValidationEmail(email: $email) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,

    validateEmail: gql`
        mutation validateEmail($email: String!, $code: String!) {
            validateEmail(email: $email, code: $code) {
                ...Session
            }
        }
        ${SessionFragment}
    `,

    rePay: gql`
        mutation rePay($insuranceId: String!) {
            rePay(insuranceId: $insuranceId) {
                isOneClickPayment
                oneClickPaymentResult {
                    transactionId
                }
                standardPaymentResult
                insuranceId
            }
        }
    `,

    createSessionByExternalInsuranceId: gql`
        mutation createSessionByExternalInsuranceId($id: String!, $claimId: String) {
            createSessionByExternalInsuranceId(id: $id, claimId: $claimId)
        }
    `,

    sendContractDocumentEmail: gql`
        mutation sendContractDocumentEmail($insuranceId: String!, $fileName: String!) {
            sendContractDocumentEmail(insuranceId: $insuranceId, fileName: $fileName) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,
});
