import React from "react";
import ReactDOM from "react-dom";
import { CherriskThemeProvider, NotificationProvider } from "@monster/chr-ui";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "@redux/store";
import { LoadingProvider, renderAppBanner } from "@monster/shared";
import { PageLoader } from "@components/PageLoader";
import { initLoctool } from "./i18n";
import App from "./App";

let translationVersionNr = "N/A";
try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const translationDataJSON = require("./i18n/locales/.loctool.cache.json");
    translationVersionNr = translationDataJSON.systemVersion ?? translationDataJSON.draftVersion ?? null;
} catch (e) {}

const banner = `
 _____ _    _ _____    _____            _     _                         _ 
/ ____| |  | |  __ \\  |  __ \\          | |   | |                       | |
| |   | |__| | |__) | | |  | | __ _ ___| |__ | |__   ___   __ _ _ __ __| |
| |   |  __  |  _  /  | |  | |/ _\` / __| '_ \\| '_ \\ / _ \\ / _\` | '__/ _\` |
| |___| |  | | | \\ \\  | |__| | (_| \\__ \\ | | | |_) | (_) | (_| | | | (_| |
\\_____|_|  |_|_|  \\_\\ |_____/ \\__,_|___/_| |_|_.__/ \\___/ \\__,_|_|  \\__,_|`;

const translationVersion = process.env.REACT_APP_LOCTOOL_PRODUCT_ID
    ? `${process.env.REACT_APP_LOCTOOL_PRODUCT_ID}:${process.env.REACT_APP_LOCTOOL_TRANSLATION_FLAG?.toLowerCase()}, version: ${translationVersionNr}`
    : process.env.REACT_APP_LOCTOOL_DRAFT_ID
    ? `Draft version: ${process.env.REACT_APP_LOCTOOL_DRAFT_ID}`
    : undefined;
renderAppBanner({ banner, buildSHA: process.env.REACT_APP_BUILD_SHA, lastBuildTime: process.env.REACT_APP_LAST_BUILD_TIME, translationVersion });

initLoctool();

ReactDOM.render(
    <React.StrictMode>
        <CherriskThemeProvider>
            <LoadingProvider>
                <BrowserRouter>
                    <Provider store={store}>
                        <PersistGate persistor={persistor}>
                            <NotificationProvider>
                                <App />
                                <div id="lpAuthenticatedChatDE" style={{ display: "none" }} />
                            </NotificationProvider>
                        </PersistGate>
                    </Provider>
                    <PageLoader />
                </BrowserRouter>
            </LoadingProvider>
        </CherriskThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
